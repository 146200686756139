import React, { memo, useCallback } from "react";
import { useAppDispatch, useAppSelector } from "stores";
import styled from "styled-components";
import store from "stores/interfaces";

import SceneListItem from "../SceneListItem";
import SortableList from "containers/SortableList";
import SortableItem from "containers/SortableItem";
import { DropProps } from "stores/modules/firestoreModuleUtils/ordaring";

const SceneList = () => {
  const d = useAppDispatch();
  const sceneIds = useAppSelector(store.getRoomSceneOrderedIds);
  // todo
  const roomId = useAppSelector((state) => store.getAppState(state, "roomId"));
  // const currentRoomSceneId = useAppSelector(store.getRoomSceneIds);
  const onReorder = useCallback(
    (order: DropProps) => {
      if (!roomId) return;
      d(store.reorderScenes(roomId, order));
    },
    [roomId, d]
  );

  return (
    <Content>
      <SortableList
        ids={sceneIds}
        noPadding={true}
        onReorder={onReorder}
        overlay={(id) => <SceneListItem sceneId={id} isOverlay />}
        sortableItem={(id) => (
          <DraggableItem>
            <SortableItem id={id}>
              <SceneListItem key={id} sceneId={id} />
            </SortableItem>
          </DraggableItem>
        )}
      />
    </Content>
  );
};

const Content = styled.div`
  padding: 1px;
`;

const DraggableItem = styled.div`
  padding: 1px;
`;

export default memo(SceneList);
