import {
  TouchEvent,
  useEffect,
  useCallback,
  useRef,
  useMemo,
  useId,
} from "react";

export type LongTap = {
  onTouchStart: (e: TouchEvent) => void;
  onTouchMove: (e: TouchEvent) => void;
  onTouchEnd: (e: TouchEvent) => void;
};

export default function useLongTap(onPress: (e: TouchEvent) => void): LongTap {
  const timer = useRef<number | null>(null);
  const { tryLock, unlock } = useTappingLock();

  const clertTimer = useCallback(() => {
    if (timer.current != null) {
      clearTimeout(timer.current);
    }
    unlock();
  }, [unlock]);

  const handlePressStart = useCallback(
    (e: TouchEvent) => {
      e.persist();
      e.preventDefault();

      if (!tryLock()) {
        return;
      }

      if (timer.current != null) {
        clearTimeout(timer.current);
      }
      timer.current = window.setTimeout(() => {
        onPress(e);
        unlock();
        /** ドラッグをキャンセルするためのカスタムイベント */
        const cancelDragEvent = new CustomEvent("canceldrag");
        window.document.dispatchEvent(cancelDragEvent);
      }, 480);
    },
    [onPress, tryLock, unlock]
  );

  useEffect(() => {
    return clertTimer;
  }, [clertTimer]);

  return useMemo(
    () => ({
      onTouchStart: handlePressStart,
      onTouchMove: clertTimer,
      onTouchEnd: clertTimer,
    }),
    [handlePressStart, clertTimer]
  );
}

// ロングタップを別々の要素で二重に発生させないためのロック機構

let currentTapId: string | null = null;

const useTappingLock = () => {
  const tapId = useId();

  const tryLock = useCallback((): boolean => {
    if (currentTapId != null && currentTapId !== tapId) {
      return false;
    }

    currentTapId = tapId;
    return true;
  }, [tapId]);
  const unlock = useCallback(() => {
    if (currentTapId === tapId) {
      currentTapId = null;
    }
  }, [tapId]);

  return {
    tryLock,
    unlock,
  };
};
