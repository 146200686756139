import { useCallback } from "react";

import { Link } from "react-router-dom";
import { List, ListItemButton, Drawer, IconButton } from "@mui/material";
import styled from "styled-components";
import store from "stores/interfaces";
import { useTranslation } from "react-i18next";

import CloseIcon from "@mui/icons-material/Close";
import TranslationButton from "containers/TranslationButton";
import { useAppSelector, useAppDispatch } from "stores";

const HeaderMenuSp = () => {
  const dispatch = useAppDispatch();
  const [t] = useTranslation();

  const open = useAppSelector((state) =>
    store.getAppState(state, "openTopHeaderMenu")
  );

  const onClose = useCallback(() => {
    dispatch(
      store.appStateMutate((state) => {
        state.openTopHeaderMenu = false;
      })
    );
  }, [dispatch]);
  return (
    <Drawer
      open={open}
      anchor="right"
      PaperProps={{
        sx: {
          width: "100%",
        },
      }}
    >
      <HeaderMenu>
        <HeaderMenuHead>
          <div className="logo">
            <img src="images/logo-white.svg" alt="CCFOLIAのロゴ" />
          </div>
          <div className="language">
            <TranslationButton buttonStyle="BorderButton" />
          </div>
          <CloseIconButton onClick={onClose}>
            <CloseIcon />
          </CloseIconButton>
        </HeaderMenuHead>
        <HeaderMenuList>
          <MenuListItem
            sx={{
              borderBottom: "1px solid rgba(255, 255, 255, 0.12)",
            }}
            component={Link}
            to="/plans"
          >
            {t("プラン")}
          </MenuListItem>
          <MenuListItem
            sx={{
              borderBottom: "1px solid rgba(255, 255, 255, 0.12)",
            }}
            component={Link}
            to="/games"
          >
            {t("ストア")}
          </MenuListItem>
          <MenuListItemAccordion>
            <MenuAccordion>
              <MenuAccordionSummary>{t("コミュニティ")}</MenuAccordionSummary>
              <List sx={{ paddingBottom: "16px " }}>
                <ListItemButton
                  href="https://x.com/ccfolia"
                  sx={{ padding: "9px 24px" }}
                >
                  X
                </ListItemButton>
                <ListItemButton
                  href="https://discord.gg/VTg5VHPZBw"
                  sx={{ padding: "9px 24px" }}
                >
                  {t("Discordサーバー")}
                </ListItemButton>
                <ListItemButton
                  href="https://ccfolia.fanbox.cc/"
                  sx={{ padding: "9px 24px" }}
                >
                  {t("開発支援（PIXIV FANBOX）")}
                </ListItemButton>
                <ListItemButton
                  href="https://docs.ccfolia.com/"
                  sx={{ padding: "9px 24px" }}
                >
                  {t("公式ドキュメント（ヘルプ）")}
                </ListItemButton>
              </List>
            </MenuAccordion>
          </MenuListItemAccordion>
        </HeaderMenuList>
      </HeaderMenu>
    </Drawer>
  );
};

const HeaderMenu = styled.nav`
  width: 100%;
  height: 100svh;
  @supports not (height: 100svh) {
    height: 100vh;
  }
  background: #202020;
  font-family: "Noto Sans JP", sans-serif;
`;

const HeaderMenuHead = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;

  margin: 12px 0 0 16px;

  .logo {
    width: 120px;
    img {
      width: 100%;
    }
  }
  .language {
    margin-left: 16px;
  }
`;

const CloseIconButton = styled(IconButton)`
  margin-left: auto;
`;

const HeaderMenuList = styled(List)`
  width: calc(342 / 390 * 100%);
  margin: 0 auto;
`;
const MenuListItem = styled(ListItemButton)`
  padding: 15px 0;
  font-weight: 700;
`;
const MenuListItemAccordion = styled(ListItemButton)`
  padding: 0;
  font-weight: 700;
`;

const MenuAccordion = styled.details`
  width: 100%;
  height: 100%;
  font-size: 14px;
`;
const MenuAccordionSummary = styled.summary`
  display: block grid;
  grid-template: ". icon" / 1fr 1em;
  column-gap: 1em;
  align-items: center;
  padding: 15px 5px 15px 0;
  font-size: 16px;
  list-style: none;
  &::before,
  &::after {
    content: "";
    grid-area: icon;
    border-block-end: 2px solid;
  }

  &::after {
    rotate: 90deg;
    transition: rotate 0.1s linear;
  }

  &:where([open] > *)::after {
    rotate: 180deg;
    transition: rotate 0.1s linear;
  }
  &::-webkit-details-marker {
    display: none;
  }
`;

export default HeaderMenuSp;
