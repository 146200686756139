import React, { useState, useEffect, useRef, useCallback, memo } from "react";
import styled, { keyframes } from "styled-components";
import theme from "theme";
import store from "stores/interfaces";
import {
  Paper,
  Typography,
  Toolbar,
  Slide,
  IconButton,
  Tooltip,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import Dice from "components/Dice";
import Typing from "components/Typing";

import ArrowRightIcon from "@mui/icons-material/ArrowRight";
import CloseIcon from "@mui/icons-material/Close";
import toCDNUrl from "modules/toCDNUrl";

import { shallowEqual } from "react-redux";
import { DefaultRootState } from "stores";
import { useAppDispatch, useAppSelector } from "stores";
import { Message } from "stores/modules/entities.room.messages";
import { playDiceSound } from "stores/modules/app.state/operations";

const selectState = (state: DefaultRootState) => {
  const message = store.getFrontAddedRoomMessage(state);
  const hidden = !!store.getCurrentRoom(state)?.features?.hiddenMessage;
  const hiddenDice = !store.getCurrentRoom(state)?.hidden3dDice;
  return { message, hidden, hiddenDice };
};

const MessageBox = () => {
  const dispatch = useAppDispatch();
  const state = useAppSelector(selectState, shallowEqual);

  const $ = useEnhance(state);
  const [t] = useTranslation();
  // sound
  const playedId = useRef<string | undefined | null>(null);
  useEffect(() => {
    if (
      $.message &&
      $.message.extend.roll &&
      (!state.hiddenDice || $.message.extend.roll.secret) &&
      playedId.current !== $.message._id &&
      !$.close
    ) {
      playedId.current = $.message._id;
      dispatch(playDiceSound());
    }
  }, [$.message, $.close, state.hiddenDice, playedId, dispatch]);
  if (!$.message) return null;
  return (
    <>
      <Slide direction="up" in={!$.close}>
        <MessageBoxWrapper>
          {$.message.imageUrl && (
            <MessageBoxImage
              src={toCDNUrl($.message.imageUrl)}
              draggable={false}
            />
          )}
          <MessageBoxContainer>
            <MessageBoxDices>
              {$.message.extend.roll &&
              !$.message.extend.roll.secret &&
              !state.hiddenDice
                ? $.message.extend.roll.dices.map((dice, index) => (
                    <Dice
                      key={index}
                      faces={dice.faces}
                      value={dice.value}
                      kind={dice.kind}
                      index={index}
                      secret={!!$.message?.extend.roll?.secret}
                      timestamp={$.message?.createdAt}
                    />
                  ))
                : null}
            </MessageBoxDices>
            <MessageBoxToolbar variant="dense">
              <Typography variant="subtitle2">{$.message.name}</Typography>
              {$.resultText ? (
                <MessageBoxDiceTypography
                  variant="body2"
                  color={toRollTextColor($.message.extend.roll)}
                >
                  🎲 {$.resultText}
                </MessageBoxDiceTypography>
              ) : null}
              <Spacer />
              <Tooltip title={"" + t("スキップ")} placement="top">
                <IconButton edge="end" onClick={$.onNext} size="large">
                  <ArrowRightIcon />
                </IconButton>
              </Tooltip>
              <Tooltip title={"" + t("閉じる")} placement="top">
                <IconButton edge="end" onClick={$.onClose} size="large">
                  <CloseIcon />
                </IconButton>
              </Tooltip>
            </MessageBoxToolbar>
            <MessageBoxTextContent ref={$.contentEl}>
              <Typing
                text={$.text}
                onEnded={$.onNext}
                onRender={$.onRender}
                t={$.message._id}
                TypographyProps={{}}
              />
            </MessageBoxTextContent>
          </MessageBoxContainer>
        </MessageBoxWrapper>
      </Slide>
    </>
  );
};

const Spacer = styled.div`
  flex-grow: 1;
`;

const MessageBoxWrapper = styled(Paper)`
  margin: 0 auto;
  max-width: 720px;
  position: absolute;
  left: 16px;
  right: 88px;
  bottom: 16px;
  z-index: 102;
`;

const MessageBoxContainer = styled(Paper)`
  position: relative;
  z-index: 1;
  background: rgba(22, 22, 22, 0.84);
  color: ${theme.palette.grey[100]};
`;

const MessageBoxToolbar = styled(Toolbar)`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  background: rgba(0, 0, 0, 0);
`;

const MessageBoxTextContent = styled.div`
  padding: 0 24px 16px;
  height: 80px;
  overflow-y: auto;
`;

const rotate = keyframes`
  0% {
    transform: scale(1, 0.98) translate(0, 6px);
  }

  100% {
    transform: scale(0.985, 1) translate(0, 0);
  }
`;

const MessageBoxImage = styled.img`
  width: 240px;
  position: absolute;
  bottom: 100%;
  left: 8px;
  z-index: -1;
  /* animation: 1600ms ${rotate} ease-out infinite alternate; */
  transform-origin: bottom center;
  ${theme.breakpoints.down("md")} {
    width: 180px;
  }
  ${theme.breakpoints.down("sm")} {
    width: 120px;
  }
`;

const MessageBoxDices = styled.div`
  margin-left: 180px;
  position: absolute;
  bottom: 100%;
  right: 16px;
  z-index: -1;
  display: flex;
  flex-wrap: wrap-reverse;
  justify-content: center;
  ${theme.breakpoints.down("md")} {
    margin-left: 60px;
  }
`;

const MessageBoxDiceTypography = styled(Typography)`
  margin-left: 16px;
`;

const toRollTextColor = (roll: Message["extend"]["roll"]) => {
  if (roll && roll.result.includes("成功")) {
    return "primary";
  } else if (roll && roll.result.includes("スペシャル")) {
    return "primary";
  } else if (roll && roll.result.includes("失敗")) {
    return "secondary";
  } else {
    return "textSecondary";
  }
};

const resultRegex = /＞[^＞]+$/;
const useEnhance = (state: ReturnType<typeof selectState>) => {
  const [close, setClose] = useState(false);
  const cachedMessage = useRef<Message | null>(null);
  const cachedText = useRef("");
  const cachedResultText = useRef("");
  const contentEl = useRef<HTMLDivElement | null>(null);
  const dispatch = useAppDispatch();

  const onNext = useCallback(() => {
    dispatch(store.shiftAddedRoomMessage());
  }, [dispatch]);

  const onRender = useCallback(() => {
    if (contentEl.current) {
      contentEl.current.scrollTop = 999999;
    }
  }, []);

  const [t] = useTranslation();

  if (state.message && state.message.text) {
    cachedMessage.current = state.message;
    if (
      state.message &&
      state.message.extend.roll &&
      !state.message.extend.roll.secret
    ) {
      const maches = state.message.extend.roll.result.match(resultRegex);
      cachedResultText.current = maches
        ? maches[0]
        : state.message.extend.roll.result;
    } else {
      cachedResultText.current = "";
    }
    if (state.message.extend.roll && state.message.extend.roll.secret) {
      cachedText.current = t("シークレットダイス");
    } else {
      cachedText.current = state.message.text;
    }
  }

  useEffect(() => {
    if (!state.hidden && state.message && state.message.text) {
      setClose(false);
    }
  }, [state.hidden, state.message]);

  const onClose = useCallback(() => {
    onNext();
    setClose(true);
  }, [onNext]);

  return {
    onClose,
    onNext,
    onRender,
    close,
    contentEl,
    message: cachedMessage.current,
    text: cachedText.current,
    resultText: cachedResultText.current,
  };
};

export default memo(MessageBox);
