import {
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  MenuProps,
} from "@mui/material";
import { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useAppSelector, store as reduxStore } from "stores";
import { appStateMutate } from "stores/modules/app.state/operations";
import { getAppState } from "stores/modules/app.state/selectors";
import PlayArrowIcon from "@mui/icons-material/PlayArrow";
import StopIcon from "@mui/icons-material/Stop";

type MediumListOfficialPlaylistItemMenuProps = {
  open: boolean;
  soundId: string | null;
  anchorEl?: MenuProps["anchorEl"];
  onClose: () => void;
};

const MediumListOfficialPlaylistItemMenu = ({
  open,
  soundId,
  anchorEl,
  onClose,
}: MediumListOfficialPlaylistItemMenuProps) => {
  const [t] = useTranslation();
  const dispatch = useDispatch();

  const sound = useAppSelector((state) => {
    const playlists = getAppState(state, "officialPlaylists");
    for (const playlist of playlists) {
      const sound = playlist.sounds.find((s) => s.id === soundId);
      if (sound) {
        return sound;
      }
    }
    return null;
  });
  const previewMediaUrl = useAppSelector((state) =>
    getAppState(state, "previewMediaUrl")
  );
  const isPreviewing = sound?.url === previewMediaUrl;

  const onPreview = useCallback(() => {
    const state = reduxStore.getState();
    const officialPlaylistVolume = getAppState(state, "officialPlaylistVolume");

    if (sound) {
      dispatch(
        appStateMutate((state) => {
          state.previewMediaUrl = isPreviewing ? null : sound.url;
          state.previewMediaVolume = sound.volume * officialPlaylistVolume;
          state.previewMediaLoop = sound.repeat;
        })
      );
    }
  }, [isPreviewing, sound, dispatch]);

  return (
    <Menu
      open={open}
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "right",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      onClose={onClose}
    >
      <MenuItem onClick={onPreview}>
        <ListItemIcon>
          {isPreviewing ? <StopIcon /> : <PlayArrowIcon />}
        </ListItemIcon>
        <ListItemText style={{ minWidth: "72px" }}>
          {isPreviewing ? t("試聴停止") : t("試聴")}
        </ListItemText>
      </MenuItem>
    </Menu>
  );
};

export default MediumListOfficialPlaylistItemMenu;
