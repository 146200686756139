import styled from "styled-components";
import theme from "theme";
import { useTranslation } from "react-i18next";
import gsap from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { useGSAP } from "@gsap/react";

import Header from "./Header/Header";
import MainVisual from "./MainVisual";
import About from "./About";
import Message from "./Message";
import Games from "./Games";
import Faq from "./Faq";
import Community from "./Community";
// import Sponsor from "./Sponsor";
import Footer from "./Footer";
import { useRef } from "react";

const WelcomeContent = () => {
  const [, i18n] = useTranslation();
  gsap.registerPlugin(ScrollTrigger);

  const container = useRef<HTMLDivElement | null>(null);
  const messageRef = useRef<HTMLDivElement | null>(null);

  useGSAP(
    () => {
      if (container.current) {
        const elements = Array.from(
          container.current.children
        ) as HTMLElement[];
        elements.forEach((el) => {
          gsap.fromTo(
            el,
            {
              opacity: 0,
              y: 10,
            },
            {
              opacity: 1,
              y: 0,
              ease: "power1.out",
              duration: 0.5,
              scrollTrigger: {
                trigger: el,
                start: "top center",
              },
            }
          );
        });

        // SPではSliderの高さが一定にならないため、アニメーションの発火位置が決まらずその下の描画がされない
        // メッセージが画面に入ったときに、ScrollTriggerをリフレッシュして発火位置を調節する
        ScrollTrigger.create({
          trigger: messageRef.current,
          start: "top 80%",
          onEnter: () => {
            ScrollTrigger.refresh();
          },
        });
      }
    },
    { scope: container }
  );

  return (
    <>
      <Header />
      <div ref={container}>
        <MainVisual />
        <About />
        {i18n.language === "ja" ? (
          <>
            <div ref={messageRef}>
              <Message />
            </div>
            <Games />
          </>
        ) : null}
      </div>
      {i18n.language === "ja" ? (
        <>
          <Faq />
          <Community />
          <Banner>
            <a
              href="https://bnn.co.jp/products/9784802512978"
              target="_blank"
              rel="noreferrer"
            >
              <img
                src="/images/top/banner.png"
                alt="ココフォリアガイドブック好評発売中"
              />
            </a>
          </Banner>
        </>
      ) : null}
      {/* <Sponsor /> */}
      <Footer />
    </>
  );
};

const Banner = styled.div`
  margin: 80px auto 0;
  width: calc(760 / 1400 * 100%);
  max-width: 760px;
  overflow: hidden;
  border-radius: 8px;
  &:hover {
    opacity: 0.7;
    transition: opacity 0.15s ease-in-out;
  }
  img {
    width: 100%;
  }
  ${theme.breakpoints.down("md")} {
    width: calc(342 / 390 * 100%);
    border-radius: 4px;
  }
  ${theme.breakpoints.down("sm")} {
    margin: 40px auto 0;
  }
`;

export default WelcomeContent;
