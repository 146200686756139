import { memo, useCallback } from "react";
import { useAppDispatch, useAppSelector } from "stores";

import EffectListItem from "./EffectListItem";
import { reorderEffects } from "stores/modules/entities.room.effects/operations";
import { getRoomOrderdEffectIds } from "stores/modules/entities.room.effects/selectors";
import SortableList from "containers/SortableList";
import SortableItem from "containers/SortableItem";
import { DropProps } from "stores/modules/firestoreModuleUtils/ordaring";
import styled from "styled-components";

type EffectListProps = {
  roomId: string;
};

const EffectList = ({ roomId }: EffectListProps) => {
  const dispatch = useAppDispatch();
  const effectIds = useAppSelector(getRoomOrderdEffectIds);

  const onReorder = useCallback(
    (order: DropProps) => {
      dispatch(reorderEffects(roomId, order));
    },
    [roomId, dispatch]
  );

  return (
    <NonStyleList>
      <SortableList
        ids={effectIds}
        sortableItem={(id) => (
          <SortableItem id={id}>
            <EffectListItem roomId={roomId} effectId={id} />
          </SortableItem>
        )}
        overlay={(id) => (
          <OverlayItem>
            <EffectListItem roomId={roomId} effectId={id} />
          </OverlayItem>
        )}
        onReorder={onReorder}
      />
    </NonStyleList>
  );
};

const NonStyleList = styled.div`
  color: white;
  list-style: none;
`;

const OverlayItem = styled.div`
  color: white;
  list-style: none;
  background: rgb(0 0 0 / 0.5);
`;

export default memo(EffectList);
