import React, { MouseEvent, useCallback, useEffect, useState } from "react";
import styled from "styled-components";
import { Box, Menu, MenuItem, Tooltip, Typography } from "@mui/material";
import toCDNUrl from "modules/toCDNUrl";
import { OfficialImage } from "api";
import { useModal } from "hooks/useModal";
import { useTranslation } from "react-i18next";
import { useAppDispatch, useAppSelector } from "stores";
import { appStateMutate } from "stores/modules/app.state/operations";
import { getAppState } from "stores/modules/app.state/selectors";

type FileListItemProps = {
  asset: OfficialImage;
  onSelect: (file: string) => void;
  onMounted: (node: HTMLImageElement) => void;
  onUnMounted: (node: HTMLImageElement) => void;
};

const OfficialFileListItem = ({
  asset,
  onSelect,
  onMounted,
  onUnMounted,
}: FileListItemProps) => {
  const d = useAppDispatch();
  const [t] = useTranslation();
  const [img, setImg] = React.useState<null | HTMLImageElement>(null);
  const { open, onClose, onOpen } = useModal();
  const [top, setTop] = useState(0);
  const [left, setLeft] = useState(0);
  const selectingFiles = useAppSelector((state) =>
    getAppState(state, "selectingFiles")
  );

  useEffect(() => {
    if (img && asset.url) {
      onMounted(img);
      return () => onUnMounted(img);
    }
  }, [img, asset.url, onUnMounted, onMounted]);

  const onClick = useCallback(() => {
    if (!selectingFiles) {
      onSelect(asset.url || "");
    }
  }, [onSelect, asset.url, selectingFiles]);

  const handleContextMenu = useCallback(
    (e: MouseEvent) => {
      e.preventDefault();
      setTop(e.clientY);
      setLeft(e.clientX);
      onOpen();
    },
    [onOpen]
  );

  const onShowImage = useCallback(() => {
    d(
      appStateMutate((state) => {
        state.openInspector = true;
        state.inspectImageUrl = asset.url || null;
      })
    );
    onClose();
  }, [asset.url, d, onClose]);

  return (
    <>
      <Tooltip title={asset.name} placement="bottom" disableTouchListener>
        <FileTile
          onClick={onClick}
          onContextMenu={handleContextMenu}
          disabled={selectingFiles}
        >
          <img
            ref={(node) => setImg(node)}
            src={"/blank.gif"}
            data-src={toCDNUrl(asset.url)}
            draggable={false}
            alt={asset.name}
          />
          <OfficialChips>
            <Typography fontSize="10px">{t("公式素材")}</Typography>
          </OfficialChips>
        </FileTile>
      </Tooltip>
      <Menu
        open={open}
        anchorReference="anchorPosition"
        anchorPosition={{ top, left }}
        MenuListProps={{ dense: true }}
        onClose={onClose}
      >
        <MenuItem onClick={onShowImage}>{t("拡大表示")}</MenuItem>
      </Menu>
    </>
  );
};

const FileTile = styled.div<{ disabled?: boolean }>`
  border: 1px solid rgba(0, 0, 0, 0.2);
  box-sizing: border-box;
  margin: 0.5%;
  width: 19%;
  height: 90px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(0, 0, 0, 0.2);
  transition: opacity 160ms ease-out;
  opacity: ${({ disabled }) => (disabled ? 0.5 : 1)};
  pointer-events: ${({ disabled }) => (disabled ? "none" : "auto")};
  cursor: ${({ disabled }) => (disabled ? "auto" : "pointer")};
  position: relative;
  &:active {
    opacity: 0.5;
  }
  img {
    max-width: 100%;
    max-height: 100%;
  }
`;

const OfficialChips = styled(Box)`
  position: absolute;
  right: 0;
  bottom: 10px;
  padding: 2px 4px;
  background-color: black;
`;

export default OfficialFileListItem;
