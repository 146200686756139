import { PayloadAction } from "@reduxjs/toolkit";
import {
  CollectionReference,
  doc,
  setDoc,
  updateDoc,
} from "firebase/firestore";
import { produce } from "immer";
import { HugeBatch } from "modules/hugeBatch";
import { DefaultRootState, DefaultThunk } from "stores";
import { addUndo } from "../entities.room.histories/slice";
import { Scene, UpdateScene } from "../entities.room.scenes";
import { Item, UpdateItem } from "../entities.room.items";
import { Effect, UpdateEffect } from "../entities.room.effects";
import { Note, UpdateNote } from "../entities.room.notes";
import { UserMediumDirectory } from "../entities.user.mediumDirectories";

type Move = {
  destIndex: number;
  srcIndex: number;
};

type OrderType = "scene" | "item" | "effect" | "note";

type OrderEntities = Scene | Item | Effect | Note | UserMediumDirectory;
type UpdateOrderEntities = UpdateScene | UpdateItem | UpdateEffect | UpdateNote;

export const moveIdByIndex = (
  ids: string[],
  { destIndex, srcIndex }: Move
): string[] => {
  return produce(ids, (draft) => {
    const [id] = draft.splice(srcIndex, 1);
    draft.splice(destIndex, 0, id);
  });
};

const MIN_ORDER_DIFFERENCE = 2 ** -32;

export const calcOrderByDrag = (
  ids: string[],
  entities: Record<string, { order: number } | undefined>,
  { destIndex, srcIndex }: Move
): number | null => {
  const [prevIndex, nextIndex] =
    destIndex > srcIndex
      ? [destIndex, destIndex + 1]
      : [destIndex - 1, destIndex];

  return calcOrderByInternal(ids, entities, { prevIndex, nextIndex });
};

export const calcOrderByInsert = (
  ids: string[],
  entities: Record<string, { order: number } | undefined>,
  index: number
): number | null => {
  const prevIndex = index - 1;
  const nextIndex = index;

  return calcOrderByInternal(ids, entities, { prevIndex, nextIndex });
};

export const calcOrderAppendingToTail = (
  ids: string[],
  entities: Record<string, { order: number }>
): number => {
  if (ids.length === 0) {
    return 1;
  }

  const id = ids[ids.length - 1];
  const obj = entities[id];

  return obj.order + 1;
};

type PrevNext = {
  prevIndex: number;
  nextIndex: number;
};

const calcOrderByInternal = (
  ids: string[],
  entities: Record<string, { order: number } | undefined>,
  { prevIndex, nextIndex }: PrevNext
) => {
  const prevId = ids[prevIndex];
  const nextId = ids[nextIndex];

  const prevObj = entities[prevId];
  const nextObj = entities[nextId];

  if (prevObj != null && nextObj != null) {
    const candidate = (nextObj.order + prevObj.order) / 2;
    if (
      Number.isNaN(candidate) ||
      candidate - prevObj.order < MIN_ORDER_DIFFERENCE ||
      nextObj.order - candidate < MIN_ORDER_DIFFERENCE
    ) {
      return null;
    } else {
      return candidate;
    }
  }

  if (prevObj != null) {
    return prevObj.order + 1;
  }

  if (nextObj != null) {
    return nextObj.order - 1;
  }

  return 1;
};

type CollectionRefByRoomId = (roomId: string) => CollectionReference;

export const forceAlignOrders = (
  ids: string[],
  roomId: string,
  collectionRef: CollectionRefByRoomId
) => {
  const batch = new HugeBatch();
  ids.forEach((id, index) => {
    batch.update(doc(collectionRef(roomId), id), { order: index });
  });
  batch.commit();
};

type ReorderEntitiesProps = {
  selectOrderdIds: (state: DefaultRootState) => string[];
  selectEntities: (state: DefaultRootState) => Record<string, OrderEntities>;
  actionReorder: (payload: {
    srcIndex: number;
    destIndex: number;
  }) => PayloadAction<{ srcIndex: number; destIndex: number }>;
  actionUpdateOrder: (payload: {
    id: string;
    order: number;
  }) => PayloadAction<{ id: string; order: number }>;
  collectionRef: CollectionRefByRoomId;
  type?: OrderType;
};

export type DropProps = {
  destination: number;
  source: number;
};

export const isDropProps = (x: any): x is DropProps => {
  return typeof x.destination === "number" && typeof x.source === "number";
};

export const reorderEntities =
  ({
    selectOrderdIds,
    selectEntities,
    actionReorder,
    actionUpdateOrder,
    collectionRef,
    type,
  }: ReorderEntitiesProps) =>
  (refId: string, order: DropProps): DefaultThunk =>
  (dispatch, getState) => {
    const destIndex = order.destination;
    const srcIndex = order.source;

    const state = getState();
    const ids = selectOrderdIds(state);

    const targetId = ids[srcIndex];
    if (!targetId || ids.length <= destIndex || ids.length <= srcIndex) {
      return;
    }

    dispatch(actionReorder({ destIndex, srcIndex }));
    const entities = selectEntities(state);

    const newOrder = calcOrderByDrag(ids, entities, {
      destIndex,
      srcIndex,
    });

    if (newOrder === null) {
      const movedIds = moveIdByIndex(ids, { destIndex, srcIndex });
      forceAlignOrders(movedIds, refId, collectionRef);
    } else {
      dispatch(actionUpdateOrder({ id: targetId, order: newOrder }));
      updateDoc(doc(collectionRef(refId), targetId), { order: newOrder });
    }
    if (type) {
      dispatch(
        addUndo({
          kind: `change-${type}-index`,
          beforeIndex: srcIndex,
          afterIndex: destIndex,
          id: targetId,
          item: entities[targetId],
        })
      );
    }
  };

export const undoRedoReorderEntities =
  ({
    selectOrderdIds,
    selectEntities,
    actionReorder,
    actionUpdateOrder,
    collectionRef,
  }: ReorderEntitiesProps) =>
  (
    roomId: string,
    id: string,
    index: number,
    item: UpdateOrderEntities
  ): DefaultThunk =>
  (dispatch, getState) => {
    const state = getState();
    const ids = selectOrderdIds(state);

    const srcIndex = ids.indexOf(id);

    // 対象が削除済みの場合
    if (srcIndex < 0) {
      // 移動先に直接挿入
      const addDestIndex = Math.min(index, ids.length);
      const order = calcOrderByInsert(ids, selectEntities(state), addDestIndex);
      setDoc(doc(collectionRef(roomId), id), {
        ...item,
        order: order ?? addDestIndex,
      });
      if (order === null) {
        const movedIds = ids.splice(addDestIndex, 0, id);
        forceAlignOrders(movedIds, roomId, collectionRef);
      }
      return;
    }

    const destIndex = Math.min(index, ids.length - 1);
    if (srcIndex === destIndex) return;

    dispatch(actionReorder({ destIndex, srcIndex }));

    const newOrder = calcOrderByDrag(ids, selectEntities(state), {
      destIndex,
      srcIndex,
    });

    if (newOrder === null) {
      const movedIds = moveIdByIndex(ids, { destIndex, srcIndex });
      forceAlignOrders(movedIds, roomId, collectionRef);
      return;
    } else {
      dispatch(actionUpdateOrder({ id, order: newOrder }));
      setDoc(doc(collectionRef(roomId), id), {
        ...item,
        order: newOrder,
      });
      return newOrder;
    }
  };
