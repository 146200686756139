import React, {
  ChangeEvent,
  MouseEvent,
  memo,
  useCallback,
  useEffect,
  useState,
} from "react";
import { Trans, useTranslation } from "react-i18next";
import { useAppSelector } from "stores";
import store from "stores/interfaces";
import { useDropzone } from "react-dropzone";
import toCDNUrl from "modules/toCDNUrl";
import styled from "styled-components";
import {
  Avatar,
  Button,
  Dialog,
  DialogContent,
  List,
  ListItem,
  ListItemText,
  TextField,
  Link,
  DialogTitle,
  IconButton,
  Typography,
  Switch,
  ListItemSecondaryAction,
  CircularProgress,
  Box,
  Snackbar,
  Alert,
} from "@mui/material";
import { createBillingPortalSession, syncSubscriptionState } from "api";
import { refreshCustomClaim } from "stores/modules/app.user/operations";
import SyncButton from "./SyncButton";
import CloseIcon from "@mui/icons-material/Close";
import { ReactComponent as CcfoliaProLogo } from "./CcfoliaProLogo.svg";
import { ReactComponent as CcfoliaProIcon } from "./CcfoliaPro.svg";
import { useForm } from "react-hook-form";
import theme from "theme";
import Spacer from "components/Spacer";
import { useAppDispatch } from "stores";
import ChangeEmailDialog from "./ChangeEmailDialog";
import ChangePasswordDialog from "./ChangePasswordDialog";
import LinkWithEmailAuthDialog from "./LinkWithEmailAuthDialog";
import AlertMessage from "./AlertMessage";
import { sendEmailVerification } from "firebase/auth";
import { auth } from "initializer";

type FormData = {
  name: string;
};

const UserSettings: React.FC<{ uid: string | null }> = () => {
  const dispatch = useAppDispatch();
  const open = useAppSelector((state) =>
    store.getAppState(state, "openUserSettings")
  );
  const displayName = useAppSelector((state) =>
    store.getAppState(state, "displayName")
  );
  const photoUrl = useAppSelector((state) =>
    store.getAppState(state, "photoUrl")
  );
  const email = useAppSelector((state) => store.getAppState(state, "email"));
  const requiredVerifyEmail = useAppSelector(store.getRequiredVerifyEmail);
  const providerData = useAppSelector((state) =>
    store.getAppState(state, "providerData")
  );
  const plan = useAppSelector((state) => store.getAppState(state, "plan"));
  const isPro = plan === "ccfolia-pro";
  const skipInfomation = useAppSelector(
    (state) => state.entities.userSetting.skipInfomation
  );

  const onDrop = useCallback(
    (files: File[]) => {
      dispatch(store.updateUserPhoto(files[0]));
    },
    [dispatch]
  );

  const dropzone = useDropzone({
    onDrop,
    noClick: true,
    accept: ["image/*"],
  });

  const { register, handleSubmit, reset, getValues } = useForm<FormData>({
    defaultValues: {
      name: "",
    },
  });

  useEffect(() => {
    reset({ name: displayName || "" });
  }, [displayName, reset]);

  const onSubmit = useCallback(
    (values: FormData) => {
      dispatch(store.updateUserProfile({ displayName: values.name }));
    },
    [dispatch]
  );

  const onClose = useCallback(() => {
    onSubmit(getValues());
    dispatch(store.appStateMutate((state) => (state.openUserSettings = false)));
  }, [onSubmit, getValues, dispatch]);
  const onDelete = useCallback(() => {
    dispatch(
      store.appStateMutate((state) => {
        state.openUserDelete = true;
      })
    );
  }, [dispatch]);

  const onClickPlan = async () => {
    if (plan === "ccfolia-pro") {
      const { url } = await createBillingPortalSession();
      window.location.href = url;
      return;
    }

    dispatch(
      store.appStateMutate((state) => {
        state.openUserSettings = false;
        state.openPlanSelect = true;
        state.destOnClosePlanSelect = "userSettings";
      })
    );
  };

  const onClickSyncPlan = async () => {
    await syncSubscriptionState({ force: true });
    await dispatch(refreshCustomClaim());
  };

  const onChangeSkipInfomation = (
    _: ChangeEvent<HTMLInputElement>,
    skipInfomation: boolean
  ) => {
    dispatch(store.updateUserSetting({ skipInfomation }));
    dispatch(
      store.appStateMutate((state) => {
        // ルーム内で切り替えた時に表示されてしまうのを防ぐ
        const date = new Date();
        state.informationDate = date.getTime();
      })
    );
  };

  const modalChangeEmail = useModal();
  const modalChangePassword = useModal();
  const modalLinkWithEmailAuth = useModal();

  const { onOpen: onOpenModalChangeEmail } = modalChangeEmail;
  const { onOpen: onOpenModalChangePassword } = modalChangePassword;

  const onClickChangeEmail = useCallback(
    (event: MouseEvent) => {
      onOpenModalChangeEmail();
      event.preventDefault();
    },
    [onOpenModalChangeEmail]
  );

  const onClickChangePassword = useCallback(
    (event: MouseEvent) => {
      onOpenModalChangePassword();
      event.preventDefault();
    },
    [onOpenModalChangePassword]
  );

  const [t] = useTranslation();
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarText, setSnackbarText] = useState<String>("");

  const onOpenSnackbar = useCallback((text: string) => {
    setOpenSnackbar(true);
    setSnackbarText(text);
  }, []);

  const onClickSendEmailVerification = useCallback(
    (event: MouseEvent) => {
      event.preventDefault();

      if (auth.currentUser) {
        sendEmailVerification(auth.currentUser).then(() => {
          window.alert(
            t(
              "確認メールを再送しました。\n\nメールに記載されたリンクをクリックして、メールアドレスの確認を完了させてください。"
            )
          );
        });
      }
    },
    [t]
  );
  return (
    <>
      <Dialog
        open={open}
        onClose={onClose}
        fullWidth
        maxWidth="sm"
        PaperProps={dropzone.getRootProps()}
      >
        <DialogTitle>
          {t("アカウント設定")}
          <IconButton
            onClick={onClose}
            style={{ position: "absolute", right: 8, top: 8 }}
            size="large"
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <ProfileArea>
            <Typography color="textSecondary" variant="caption" display="block">
              {t("プロフィール")}
            </Typography>
            <Profile>
              <IconButton onClick={dropzone.open} size="small">
                <Avatar
                  src={toCDNUrl(photoUrl) || "/ccfolia.png"}
                  style={{ width: 48, height: 48 }}
                />
              </IconButton>
              <input {...dropzone.getInputProps()} disabled={false} />
              <form
                onSubmit={handleSubmit(onSubmit)}
                key={"user"}
                style={{ flex: 1, marginLeft: 16 }}
              >
                <TextField
                  variant="standard"
                  placeholder={t("匿名ユーザー")}
                  label={t("ユーザー名")}
                  type="text"
                  fullWidth
                  {...register("name")}
                />
              </form>
            </Profile>
          </ProfileArea>
          <PlanArea>
            <Typography color="textSecondary" variant="caption" display="block">
              {t("現在のプラン")}
            </Typography>
            <PlanBody>
              {isPro ? (
                <CcfoliaProLogoStyled role="img" aria-label="CCFOLIA PRO" />
              ) : (
                <Typography variant="body1" display="inline">
                  {t("CCFOLIA BASIC")}
                </Typography>
              )}
              <Spacer />
              <PlanActions>
                <SyncButton onClick={onClickSyncPlan} />
                <ButtonChangePlan onClick={onClickPlan} />
              </PlanActions>
            </PlanBody>
          </PlanArea>
          <PlanArea>
            <Typography color="textSecondary" variant="caption" display="block">
              {t("メールアドレス・パスワード")}
            </Typography>
            {providerData.email ? (
              <Box padding="8px 16px">
                <Typography variant="body1" display="inline">
                  {email}
                </Typography>
                <EmailAuthActions>
                  <EmailAuthAction>
                    ・
                    <Link
                      fontFamily={theme.typography.fontFamily}
                      fontSize="14px"
                      underline="hover"
                      href="#"
                      onClick={onClickChangeEmail}
                    >
                      {t("メールアドレスを変更する")}
                    </Link>
                  </EmailAuthAction>
                  <EmailAuthAction>
                    ・
                    <Link
                      fontFamily={theme.typography.fontFamily}
                      fontSize="14px"
                      underline="hover"
                      href="#"
                      onClick={onClickChangePassword}
                    >
                      {t("パスワードを変更する")}
                    </Link>
                  </EmailAuthAction>
                </EmailAuthActions>
              </Box>
            ) : (
              <PlanBody>
                <Typography variant="body1" display="inline">
                  {t("登録されていません")}
                </Typography>
                <Spacer />
                <PlanActions>
                  <Button
                    variant="outlined"
                    onClick={modalLinkWithEmailAuth.onOpen}
                  >
                    {t("パスワードを登録")}
                  </Button>
                </PlanActions>
              </PlanBody>
            )}
            <LinkWithEmailAuthDialog
              open={modalLinkWithEmailAuth.open}
              onClose={modalLinkWithEmailAuth.onClose}
              onOpenSnackbar={onOpenSnackbar}
            />
            <ChangeEmailDialog
              open={modalChangeEmail.open}
              onClose={modalChangeEmail.onClose}
            />
            <ChangePasswordDialog
              open={modalChangePassword.open}
              onClose={modalChangePassword.onClose}
              onOpenSnackbar={onOpenSnackbar}
            />
            {requiredVerifyEmail && (
              <AlertMessage>
                <Trans>
                  メールアドレスの確認が完了していません。
                  <Link
                    color="secondary"
                    underline="hover"
                    fontWeight="bold"
                    href="#"
                    onClick={onClickSendEmailVerification}
                  >
                    確認メールを送信
                  </Link>
                  して、メールに記載されたリンクをクリックしてください。
                </Trans>
              </AlertMessage>
            )}
          </PlanArea>
          <Typography color="textSecondary" variant="caption" display="block">
            {t("SNS連携")}
          </Typography>
          <List disablePadding>
            {providerData.twitter ? (
              <ListItem>
                <ListItemText
                  primary={t("X（旧Twitter）連携済み")}
                  secondary={
                    <>
                      {providerData.twitter.displayName}{" "}
                      <Link
                        href="#"
                        onClick={() =>
                          dispatch(store.unlinkFromProvider("twitter"))
                        }
                        underline="hover"
                      >
                        {t("連携を解除する")}
                      </Link>
                    </>
                  }
                />
              </ListItem>
            ) : (
              <ListItem
                button
                onClick={() => dispatch(store.linkWithProvider("twitter"))}
              >
                <ListItemText
                  primary={t("X（旧Twitter）連携")}
                  secondary={t("Xアカウントと連携します")}
                />
              </ListItem>
            )}
            {providerData.google ? (
              <ListItem>
                <ListItemText
                  primary={t("Google連携済み")}
                  secondary={
                    <>
                      {providerData.google.displayName}{" "}
                      <Link
                        href="#"
                        onClick={() =>
                          dispatch(store.unlinkFromProvider("google"))
                        }
                        underline="hover"
                      >
                        {t("連携を解除する")}
                      </Link>
                    </>
                  }
                />
              </ListItem>
            ) : (
              <ListItem
                button
                onClick={() => dispatch(store.linkWithProvider("google"))}
              >
                <ListItemText
                  primary={t("Google連携")}
                  secondary={t("Googleアカウントと連携します")}
                />
              </ListItem>
            )}
          </List>
          <StyledList>
            <Typography color="textSecondary" variant="caption" display="block">
              {t("表示設定")}
            </Typography>
            <ListItem>
              <ListItemText
                primary={
                  <>
                    {t("告知をスキップ")}
                    <CcfoliaProIconStyled role="img" aria-label="PRO" />
                  </>
                }
                secondary={t("ルーム入室時の告知をスキップします")}
              />
              <ListItemSecondaryAction>
                <Switch
                  name="monitored"
                  checked={isPro && skipInfomation}
                  onChange={onChangeSkipInfomation}
                  disabled={!isPro}
                />
              </ListItemSecondaryAction>
            </ListItem>
          </StyledList>
          <ActionArea>
            <Button fullWidth color="secondary" size="small" onClick={onDelete}>
              {t("アカウントを削除する")}
            </Button>
          </ActionArea>
        </DialogContent>
      </Dialog>
      <Snackbar
        open={openSnackbar}
        autoHideDuration={2000}
        onClose={() => setOpenSnackbar(false)}
      >
        <Alert
          onClose={() => setOpenSnackbar(false)}
          severity="success"
          elevation={6}
          variant="filled"
        >
          {snackbarText}
        </Alert>
      </Snackbar>
    </>
  );
};

type ButtonChangePlanProps = {
  onClick: () => Promise<void>;
};

const ButtonChangePlan = ({ onClick }: ButtonChangePlanProps) => {
  const [t] = useTranslation();
  const [loading, setLoading] = useState(false);

  const handleClick = () => {
    setLoading(true);
    onClick().finally(() => setLoading(false));
  };

  return (
    <Button
      variant="contained"
      color="secondary"
      onClick={handleClick}
      disabled={loading}
    >
      {loading ? (
        <CircularProgress color="primary" size={24.5} />
      ) : (
        t("プランを変更する")
      )}
    </Button>
  );
};

const useModal = () => {
  const [open, setOpen] = useState(false);
  const onOpen = useCallback(() => setOpen(true), []);
  const onClose = useCallback(() => setOpen(false), []);

  return { open, onOpen, onClose };
};

const ProfileArea = styled.div`
  padding: 16px 0 8px;
`;

const Profile = styled.div`
  display: flex;
  align-items: center;
  padding: 8px 0;
`;

const ActionArea = styled.div`
  border-top: 1px solid rgba(255, 255, 255, 0.1);
  margin-top: 32px;
  padding: 16px 0 8px;
  text-align: center;
`;

const PlanArea = styled.div`
  margin: 16px 0;
`;

const PlanBody = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  row-gap: 8px;
`;

const PlanActions = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
`;

const EmailAuthActions = styled.ul`
  list-style-type: none;
`;

const EmailAuthAction = styled.li`
  margin-top: 4px;
`;

const StyledList = styled(List).attrs({ disablePadding: true })`
  margin: 16px 0;
`;

const CcfoliaProIconStyled = styled(CcfoliaProIcon)`
  margin-left: 6px;
  fill: ${theme.palette.text.primary};
`;

const CcfoliaProLogoStyled = styled(CcfoliaProLogo)`
  padding-left: 2px;
`;

export default memo(UserSettings);
