import { memo, useCallback, useEffect } from "react";
import { useAppDispatch, useAppSelector } from "stores";
import store from "stores/interfaces";
import { store as reduxStore } from "stores";
import {
  Dialog,
  DialogContent,
  DialogActions,
  AppBar,
  Toolbar,
  IconButton,
  Button,
  Typography,
} from "@mui/material";
import CharacterForm from "../CharacterForm";
import CloseIcon from "@mui/icons-material/Close";
import { useTranslation } from "react-i18next";
import {
  Character,
  CharacterRecord,
  UpdateCharacter,
} from "stores/modules/entities.room.characters";
import { FormProvider, useForm } from "react-hook-form";
import { addUndoUpdateCharacter } from "stores/modules/entities.room.characters/operations";
import { addUndo } from "stores/modules/entities.room.histories/slice";
import { produce } from "immer";
import { clamp } from "modules/clamp";

const finiteNumber = (x: number, defaultValue: number = 0): number => {
  return Number.isFinite(x) ? x : defaultValue;
};

type Value = Omit<Character, "active">;

type CharacterFormDialogProps = {
  uid: string;
  roomId: string;
};

const DEFAULT_VALUE = CharacterRecord({});

const CharacterFormDialog = ({ uid, roomId }: CharacterFormDialogProps) => {
  const [t] = useTranslation();
  const dispatch = useAppDispatch();
  const open = useAppSelector((state) =>
    store.getAppState(state, "openRoomCharacter")
  );
  const characterId = useAppSelector((state) =>
    store.getAppState(state, "openRoomCharacterId")
  );
  const character = useAppSelector((state) =>
    store.getCharacterById(state, characterId)
  );
  const active = useAppSelector(
    (state) => store.getCharacterById(state, characterId).active
  );

  const methods = useForm<Value>({ defaultValues: DEFAULT_VALUE });
  const {
    handleSubmit,
    reset,
    formState: { isDirty },
  } = methods;

  useEffect(() => {
    if (open) {
      const character = store.getCharacterById(
        reduxStore.getState(),
        characterId
      );
      const { active, ...initCharactervalues } = CharacterRecord(character);
      reset(initCharactervalues);
    }
  }, [open, characterId, reset]);

  const onSubmit = useCallback(
    (values: Value) => {
      if (characterId === null) return;

      const character: UpdateCharacter = produce(values, (draft) => {
        draft.initiative = finiteNumber(draft.initiative);
        draft.x = finiteNumber(draft.x);
        draft.y = finiteNumber(draft.y);
        draft.width = clamp(draft.width, { min: 1, max: 100 });
        draft.height = draft.width;

        for (const state of draft.status) {
          state.max = finiteNumber(state.max);
          state.value = finiteNumber(state.value);
        }
      });

      dispatch(store.updateRoomCharacter(characterId, character));
      dispatch(addUndoUpdateCharacter(characterId, character));
      dispatch(
        store.appStateMutate((state) => {
          state.openRoomCharacter = false;
        })
      );
    },
    [dispatch, characterId]
  );

  const onClose = useCallback(() => {
    if (isDirty) {
      handleSubmit(onSubmit)();
    } else {
      dispatch(
        store.appStateMutate((state) => {
          state.openRoomCharacter = false;
        })
      );
    }
  }, [dispatch, handleSubmit, onSubmit, isDirty]);

  const onDelete = useCallback(() => {
    if (window.confirm(t("本当に削除しますか？"))) {
      if (characterId === null) return;
      dispatch(store.deleteCharacter(roomId, characterId));
      dispatch(
        addUndo({
          kind: "update-character",
          id: characterId,
          before: character,
          after: null,
        })
      );
      dispatch(
        store.appStateMutate((state) => {
          state.openRoomCharacterId = null;
          state.openRoomCharacter = false;
        })
      );
    }
  }, [t, characterId, dispatch, roomId, character]);

  const onDuplicate = useCallback(() => {
    if (characterId === null) return;
    dispatch(store.duplicateRoomCharacter(characterId));
  }, [dispatch, characterId]);

  const onActive = useCallback(() => {
    if (characterId === null) return;
    dispatch(
      store.updateCurrentRoomCharacter({
        active: !active,
        owner: uid,
      })
    );
    dispatch(
      addUndoUpdateCharacter(characterId, {
        active: !active,
        owner: uid,
      })
    );
  }, [dispatch, active, uid, characterId]);

  return (
    <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
      <AppBar color="default" position="sticky">
        <Toolbar>
          <Typography style={{ flex: 1 }}>{t("キャラクター編集")}</Typography>
          <IconButton edge="end" onClick={onClose} size="large">
            <CloseIcon />
          </IconButton>
        </Toolbar>
      </AppBar>
      <DialogContent>
        <FormProvider {...methods}>
          <CharacterForm onSubmit={onSubmit} />
        </FormProvider>
      </DialogContent>
      <DialogActions>
        <Button fullWidth onClick={onDelete} color="secondary">
          {t("削除")}
        </Button>
        <Button fullWidth onClick={onDuplicate} color="primary">
          {t("複製")}
        </Button>
        <Button fullWidth onClick={onActive} color="primary">
          {active ? t("盤面から削除") : t("盤面に追加")}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default memo(CharacterFormDialog);
