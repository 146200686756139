import {
  AppBar,
  Divider,
  Drawer,
  IconButton,
  LinearProgress,
  Toolbar,
  Tooltip,
  Typography,
} from "@mui/material";
import { useCallback, useEffect, useRef, useState } from "react";
import { Document, Page, pdfjs } from "react-pdf";
import "react-pdf/dist/esm/Page/AnnotationLayer.css";
import "react-pdf/dist/esm/Page/TextLayer.css";
import ErrorDialog from "./ErrorDialog";
import styled from "styled-components";
import CloseIcon from "@mui/icons-material/Close";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import { useTranslation } from "react-i18next";
import { encodeUnicodeToBase64 } from "modules/base64";
import theme from "theme";
import { ErrorBoundary } from "react-error-boundary";

pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.mjs`;
const options = {
  standardFontDataUrl: `https://unpkg.com/pdfjs-dist@${pdfjs.version}/standard_fonts`,
  cMapUrl: `https://unpkg.com/pdfjs-dist@${pdfjs.version}/cmaps/`,
  cMapPacked: true,
};

type PDFViewerContentProps = {
  open: boolean;
  url: string;
  width: string;
  onClose: () => void;
  isPopupWindow?: boolean;
};

const PDFViewerContent = ({
  open,
  url,
  width,
  onClose,
  isPopupWindow = false,
}: PDFViewerContentProps) => {
  const [t] = useTranslation();

  const [numPages, setNumPages] = useState(0);

  const containerRef = useRef<HTMLDivElement>(null);
  const scrollRef = useRef<HTMLDivElement>(null);
  const timerRef = useRef<any>(null);
  const [size, setSize] = useState({ width: 300, height: 300 });
  const [zoom, setZoom] = useState<number>(100);

  useEffect(() => {
    if (!open) return;
    const update = () => {
      if (containerRef.current) {
        const { width, height } = containerRef.current.getBoundingClientRect();
        setSize({ width: width - 16, height });
      }
    };
    const resizeObserver = new ResizeObserver(() => {
      timerRef.current && clearTimeout(timerRef.current);
      timerRef.current = setTimeout(update, 300);
    });
    resizeObserver.observe(document.body);
    return (): void => {
      resizeObserver.disconnect();
      clearTimeout(timerRef.current);
    };
  }, [open]);

  const onDocumentLoadSuccess = useCallback(
    ({ numPages }) => {
      setNumPages(numPages);
    },
    [setNumPages]
  );

  const renderError = useCallback(() => {
    setNumPages(0);
    return <ErrorDialog url={url} onClose={onClose} />;
  }, [url, setNumPages, onClose]);

  const handleZoom = (zoomOn: boolean) => {
    if (!scrollRef.current) return;
    const scrollTop = scrollRef.current.scrollTop;

    // スクロールバーのちらつき防止
    scrollRef.current.style.overflow = "hidden";
    zoomOn ? setZoom(zoom + 50) : setZoom(zoom - 50);

    scrollRef.current.style.overflow = "auto";
    scrollRef.current.scrollTop = scrollTop;
  };

  const openPopupViewer = useCallback(() => {
    const base64 = encodeUnicodeToBase64(url);
    const path = `/tools/pdf?p=${base64}`;

    const width = 700;
    const height = 1000;

    window.open(
      path,
      `pdf:${base64}`,
      `resizable,scrollbars,status,width=${width},height=${height},left=${
        window.innerWidth * 0.5 - width * 0.5
      },top=${window.innerHeight * 0.5 - height * 0.5}`
    );
    onClose();
  }, [url, onClose]);

  return (
    <PDFDrawer
      open={open}
      onClose={onClose}
      width={width}
      anchor="right"
      variant="persistent"
    >
      <AppBar color="default" position="sticky">
        <Toolbar>
          <IconButton edge="start" onClick={onClose} size="large">
            <CloseIcon />
          </IconButton>
          <Spacer />
          {!isPopupWindow && (
            <Tooltip title={t("別窓で開く")}>
              <IconButton
                edge="start"
                size="large"
                disabled={numPages <= 0}
                onClick={openPopupViewer}
              >
                <OpenInNewIcon fontSize="small" />
              </IconButton>
            </Tooltip>
          )}
          <Divider
            orientation="vertical"
            flexItem
            sx={{ margin: "0 24px 0 12px" }}
          />
          <IconButton
            edge="start"
            onClick={() => handleZoom(false)}
            size="small"
            disabled={zoom <= 100}
          >
            <RemoveCircleOutlineIcon fontSize="small" />
          </IconButton>
          <Typography style={{ marginRight: "5px", marginLeft: "5px" }}>
            {zoom}%
          </Typography>
          <IconButton
            edge="start"
            onClick={() => handleZoom(true)}
            size="small"
            disabled={zoom >= 200}
          >
            <AddCircleOutlineIcon fontSize="small" />
          </IconButton>
        </Toolbar>
      </AppBar>
      <ScrollArea ref={scrollRef}>
        <Container ref={containerRef}>
          <Content>
            <ErrorBoundary FallbackComponent={renderError}>
              {url && (
                <Document
                  file={url}
                  onLoadSuccess={onDocumentLoadSuccess}
                  renderMode="canvas"
                  options={options}
                  externalLinkRel="noopener noreferrer nofollow"
                  externalLinkTarget="_blank"
                  loading={
                    <Box>
                      <Typography>Loading PDF...</Typography>
                      <Loader />
                    </Box>
                  }
                  error={renderError}
                >
                  {Array(numPages)
                    .fill(0)
                    .map((_, i) => (
                      <PageWrap key={i}>
                        <Page
                          scale={zoom * 0.01}
                          // リンク無効化
                          renderAnnotationLayer={false}
                          width={size.width}
                          pageNumber={i + 1}
                        />
                      </PageWrap>
                    ))}
                </Document>
              )}
            </ErrorBoundary>
          </Content>
        </Container>
      </ScrollArea>
    </PDFDrawer>
  );
};

const PDFDrawer = styled(Drawer)<{ width: string }>`
  > .MuiPaper-root {
    max-width: 100%;
    width: ${({ width }) => width};
    ${theme.breakpoints.down("sm")} {
      max-width: 100%;
      width: 100%;
      border-left: none;
    }
  }
`;

const Spacer = styled.div`
  flex-grow: 1;
`;

const ScrollArea = styled.div`
  overflow-x: auto;
  overflow-y: scroll;
  height: 100%;
`;

const Container = styled.div`
  position: relative;
`;

const Content = styled.div`
  box-sizing: border-box;
  padding: 16px 8px;
  position: relative;
  min-width: calc(100% - 16px);
  width: max-content;
`;

const Box = styled.div`
  padding: 24px;
`;

const Loader = styled(LinearProgress)`
  margin-top: 8px;
`;

const PageWrap = styled.div`
  & + & {
    margin-top: 16px;
  }
`;

export default PDFViewerContent;
