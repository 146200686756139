import DeleteIcon from "@mui/icons-material/Delete";
import { CircularProgress, IconButton, Typography } from "@mui/material";
import { MouseEvent, memo, useCallback, useEffect, useState } from "react";
import styled from "styled-components";
import theme from "theme";

type SavedataItemProps = {
  thumbnail: string | undefined | null;
  name: string;
  loading?: boolean;
  disabled?: boolean;
  onClick: () => void;
  onDelete?: () => void;
};

const SavedataItem = ({
  thumbnail,
  name,
  loading = false,
  disabled = false,
  onClick,
  onDelete,
}: SavedataItemProps) => {
  const handleDelete = useCallback(
    (event: MouseEvent) => {
      event.stopPropagation();

      if (onDelete) {
        onDelete();
      }
    },
    [onDelete]
  );

  return (
    <Row
      role="button"
      aria-disabled={disabled || loading}
      onClick={loading ? undefined : onClick}
    >
      <Thumbnail
        key={thumbnail}
        src={thumbnail || ""}
        loading={loading}
        disabled={disabled}
      />
      <SavedataName data-disabled={disabled}>
        <Typography>{name || "NODATA"}</Typography>
      </SavedataName>
      {onDelete && (
        <SecondaryAction>
          <IconButton onClick={handleDelete} size="large">
            <DeleteIcon />
          </IconButton>
        </SecondaryAction>
      )}
    </Row>
  );
};

type ThumbnailProps = {
  src: string;
  loading: boolean;
  disabled: boolean;
};

const Thumbnail = ({ src = "", loading, disabled }: ThumbnailProps) => {
  const isBlank = src === "";
  const [state, setState] = useState<"loading" | "noimage" | "loaded">(
    isBlank ? "noimage" : "loading"
  );

  useEffect(() => {
    if (isBlank) {
      return;
    }

    const img = new Image();
    img.src = src;

    img.onload = () => {
      setState("loaded");
    };
    img.onerror = () => {
      setState("noimage");
    };
  }, [src, isBlank, setState]);

  if (loading) {
    return (
      <ThumbnailWrap>
        <CircularProgress />
      </ThumbnailWrap>
    );
  }

  if (state === "loading") {
    return <ThumbnailWrap />;
  }

  if (state === "noimage") {
    return (
      <ThumbnailWrap>
        <ThumbnailNoimage data-disabled={disabled}>NOIMAGE</ThumbnailNoimage>
      </ThumbnailWrap>
    );
  }

  return (
    <ThumbnailWrap>
      <ThumbnailImage src={src} alt="" />
    </ThumbnailWrap>
  );
};

const Row = styled.div`
  box-sizing: border-box;
  display: flex;
  height: 90px;
  margin: 8px 16px;
  box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.3);
  cursor: pointer;
  user-select: none;

  &[aria-disabled="true"] {
    cursor: auto;
  }

  &:hover:not(:has(button:hover)):not([aria-disabled="true"]) {
    background-color: rgba(255, 255, 255, 0.12);

    > div:first-child {
      background-color: rgba(32, 32, 32, 0.7);
    }
  }
`;

const ThumbnailWrap = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 176px;
  flex-grow: 0;
  flex-shrink: 0;
  text-align: center;
  background-color: ${theme.palette.grey[900]};
`;

const ThumbnailImage = styled.img`
  max-width: 100%;
  max-height: 100%;
`;

const ThumbnailNoimage = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  font-size: 12px;

  &[data-disabled="true"] {
    opacity: 0.3;
  }
`;

const SavedataName = styled.div`
  display: flex;
  align-items: center;
  padding-left: 24px;
  height: 100%;
  width: 100%;

  &[data-disabled="true"] {
    opacity: 0.3;
  }
`;

const SecondaryAction = styled.div`
  display: flex;
  align-items: center;
  padding-right: 24px;
`;

export default memo(SavedataItem);
