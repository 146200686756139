import React, {
  useState,
  useCallback,
  useEffect,
  useRef,
  useMemo,
  memo,
} from "react";
import { DefaultRootState } from "stores";
import { useAppDispatch, useAppSelector } from "stores";
import styled from "styled-components";
import store from "stores/interfaces";
import theme from "theme";
import { useTranslation } from "react-i18next";

import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Slider from "@mui/material/Slider";

import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ArchiveIcon from "@mui/icons-material/Archive";
import EditIcon from "@mui/icons-material/Edit";
import LinkIcon from "@mui/icons-material/Link";
import { Tooltip } from "@mui/material";
import { Character } from "stores/modules/entities.room.characters";
import { getIsRoleAudience } from "stores/modules/entities.room.members/selectors";
import isValidUrlCheck from "modules/isValidUrl";
import { addUndoUpdateCharacter } from "stores/modules/entities.room.characters/operations";

type ActiveCharacterMenuProps = {
  onClose: () => void;
  roomId: string;
  uid: string;
  characterId: string | null;
};

const characterSelectorFactory =
  (id: string | null) => (state: DefaultRootState) =>
    store.getCharacterById(state, id);

const ActiveCharacterMenu = ({
  roomId,
  uid,
  characterId,
  onClose,
}: ActiveCharacterMenuProps) => {
  const character = useAppSelector(characterSelectorFactory(characterId));
  const [t] = useTranslation();

  const dispatch = useAppDispatch();
  const initialStatus = useCallback(() => {
    if (character.status) {
      return character.status.map((item) => ({ ...item }));
    } else {
      return [];
    }
  }, [character.status]);

  const [status, setStatus] = useState(initialStatus);
  const isRoleAudience = useAppSelector(getIsRoleAudience);

  useEffect(() => {
    if (character._id) {
      setStatus(initialStatus);
    } else {
      onClose();
    }
  }, [character, onClose, initialStatus]);

  const timer = useRef<{ [key: string]: number | undefined }>({});
  const preValue = useRef<{ [key: string]: number | undefined }>({});

  const onChangeStatus = useCallback(
    (index: number, value: number) => {
      const { label } = character.status[index];
      if (preValue.current[label] === undefined) {
        preValue.current[label] = character.status[index].value;
      }
      const nextStatus = [...status];
      nextStatus[index].value = value;
      setStatus(nextStatus);
      clearTimeout(timer.current[label]);
      timer.current[label] = window.setTimeout(() => {
        if (characterId === null) return;
        dispatch(
          store.updateCharacter(roomId, characterId, {
            status: nextStatus,
          })
        );
        if (!character.secret) {
          dispatch(
            store.addMessage(
              roomId,
              null,
              {
                text: `/system [ ${character.name} ] ${label} : ${preValue.current[label]} → ${nextStatus[index].value}`,
                name: "system",
                channel: "main",
              },
              false
            )
          );
          dispatch(
            addUndoUpdateCharacter(
              characterId,
              { status: nextStatus },
              { label: label, type: "status" }
            )
          );
        } else {
          dispatch(addUndoUpdateCharacter(characterId, { status: nextStatus }));
        }
        preValue.current[label] = undefined;
      }, 1200);
    },
    [roomId, characterId, character, status, setStatus, dispatch]
  );

  const onChangeActive = useCallback(() => {
    if (characterId !== null) {
      const updateData = {
        active: false,
        owner: uid,
      };
      dispatch(store.updateCharacter(roomId, characterId, updateData));
      dispatch(addUndoUpdateCharacter(characterId, updateData));
    }
    onClose();
  }, [uid, roomId, characterId, onClose, dispatch]);

  const onEdit = useCallback(() => {
    dispatch(
      store.appStateMutate((state) => {
        state.openRoomCharacter = true;
        state.openRoomCharacterId = characterId;
      })
    );
  }, [characterId, dispatch]);

  const onOpenExternalUrl = useCallback(() => {
    if (
      window.confirm(
        t("下記 URL を開こうとしてます。本当によろしいですか？\n") +
          character.externalUrl
      )
    ) {
      window.open(character.externalUrl, "_blank", "noreferrer");
    }
  }, [character, t]);

  const monitored = useAppSelector(store.getCurrentRoomIsMonitored);
  const isVisible = monitored || character.owner === uid || !character.secret;

  const isValidUrl = useMemo(() => {
    return isValidUrlCheck(character.externalUrl);
  }, [character.externalUrl]);

  return (
    <CharacterCard>
      <CharacterCardHead
        color={
          character.secret ? theme.palette.secondary.dark : "rgba(0, 0, 0, 0.1)"
        }
      >
        <CharacterCardTitle variant="subtitle2" noWrap>
          {character.name || t("NONAME")}
        </CharacterCardTitle>
        {isVisible && isValidUrl ? (
          <Tooltip title={"" + t("参照URLを開く")!}>
            <IconButton size="small" onClick={onOpenExternalUrl}>
              <LinkIcon fontSize="small" />
            </IconButton>
          </Tooltip>
        ) : null}
        {isVisible ? (
          <Tooltip title={"" + t("キャラクター編集")!}>
            <IconButton size="small" onClick={onEdit} disabled={isRoleAudience}>
              <EditIcon fontSize="small" />
            </IconButton>
          </Tooltip>
        ) : null}
        <Tooltip title={"" + t("キャラクターをしまう")!}>
          <IconButton
            size="small"
            onClick={onChangeActive}
            disabled={isRoleAudience}
          >
            <ArchiveIcon fontSize="small" />
          </IconButton>
        </Tooltip>
      </CharacterCardHead>
      {isVisible ? (
        <CharacterCardContent>
          <CharacterCardBody>
            {status.map((state, index) => (
              <State
                key={index}
                index={index}
                state={state}
                disabled={isRoleAudience}
                onChange={onChangeStatus}
              />
            ))}
          </CharacterCardBody>
        </CharacterCardContent>
      ) : null}
    </CharacterCard>
  );
};

type StateProps = {
  state: Character["status"][number];
  index: number;
  disabled: boolean;
  onChange: (index: number, value: number) => void;
};

const State = (props: StateProps) => {
  const max = Math.trunc(props.state.max);
  const label = props.state.label;
  const value = Math.trunc(props.state.value);

  return (
    <CharacterCardContentSlider>
      <Label variant="body2" noWrap>
        {label}
      </Label>
      <StateSlider
        value={value}
        min={0}
        max={max !== 0 ? max : value}
        step={1}
        disabled={max === 0 || props.disabled}
        onChange={(_, value) =>
          props.onChange(props.index, value instanceof Array ? value[0] : value)
        }
      />
      <Value variant="body2" noWrap>
        {value} {max === 0 || Number.isNaN(max) ? null : <>/{max}</>}
      </Value>
      <IconButton
        size="small"
        disabled={props.disabled}
        onClick={() => props.onChange(props.index, value + 1)}
      >
        <ArrowDropUpIcon />
      </IconButton>
      <IconButton
        size="small"
        edge="end"
        disabled={props.disabled}
        onClick={() => props.onChange(props.index, value - 1)}
      >
        <ArrowDropDownIcon />
      </IconButton>
    </CharacterCardContentSlider>
  );
};

const CharacterCard = styled.div`
  width: 280px;
`;

const CharacterCardHead = styled.div`
  padding: 4px 8px;
  display: flex;
  align-items: center;
  background: ${({ color }) => color};
`;

const CharacterCardContent = styled.div`
  padding: 8px;
  display: flex;
  align-items: flex-start;
`;

const CharacterCardTitle = styled(Typography)`
  flex-grow: 1;
`;

const CharacterCardBody = styled.div`
  padding: 0 8px;
  flex: 1;
`;

const Label = styled(Typography)`
  &.MuiTypography-root {
    min-width: 36px;
    max-width: 60px;
    flex: 1;
  }
`;

const Value = styled(Typography)`
  &.MuiTypography-root {
    min-width: 36px;
  }
`;

const CharacterCardContentSlider = styled.div`
  display: flex;
  align-items: center;
`;

const StateSlider = styled(Slider)`
  &.MuiSlider-root {
    margin: 0 16px 0 8px;
    opacity: ${({ disabled }) => (disabled ? 0.12 : 1)};
  }
  .MuiSlider-track {
    border-radius: 0;
    height: 16px;
    background: ${theme.palette.grey[100]};
  }
  .MuiSlider-rail {
    border-radius: 0;
    height: 16px;
    background: ${theme.palette.grey[100]};
  }
  .MuiSlider-trackAfter {
    background: rgba(0, 0, 0, 0.84);
  }
  .MuiSlider-thumb {
    margin-left: 0;
    border-radius: 0;
    width: 8px;
    height: 16px;
    background: #555;
  }
`;

export default memo(ActiveCharacterMenu);
