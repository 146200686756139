import { useState, useCallback, useRef, memo } from "react";
import { useAppDispatch, useAppSelector } from "stores";
import store from "stores/interfaces";
import { Menu, MenuItem, IconButton, Tooltip } from "@mui/material";
import SettingsIcon from "@mui/icons-material/Settings";
import { useTranslation } from "react-i18next";
import { downloadRoomLog } from "api";
import { appStateMutate } from "stores/modules/app.state/operations";
import { removeRoomMessageGroup } from "stores/modules/entities.rooms/operations";
import { deleteAllMessages } from "stores/modules/entities.room.messages/operations";
import { getCurrentTabName } from "stores/modules/entities.rooms/selectors";
import { getIsPro } from "stores/modules/app.user/selectors";
import CcfoliaProIcon from "containers/CcfoliaProIcon";

type ChatMenuProps = {
  uid: string;
  roomId: string;
  standalone: boolean;
};

const ChatMenu = ({ uid, roomId, standalone }: ChatMenuProps) => {
  const dispatch = useAppDispatch();
  const [t] = useTranslation();

  const anchorEl = useRef(null);
  const [open, setOpen] = useState(false);
  const tab = useAppSelector((state) =>
    store.getAppState(state, "roomChatTab")
  );
  const tabName = useAppSelector(getCurrentTabName);
  const isGroupTab = useAppSelector((state) => {
    const room = store.getRoomById(state, roomId);
    return room.messageGroups.findIndex((group) => group.id === tab) >= 0;
  });
  const hasEditableRole = useAppSelector(store.getHasEditableRole);

  const isPro = useAppSelector(getIsPro);

  const onOpen = useCallback(() => {
    setOpen(true);
  }, [setOpen]);

  const onClose = useCallback(() => {
    setOpen(false);
  }, [setOpen]);

  const onDeleteAllMessages = useCallback(() => {
    if (
      window.confirm(
        t("全てのルームログデータを削除します。本当に削除しますか？")
      )
    ) {
      setOpen(false);
      dispatch(deleteAllMessages(roomId)).then(() => {
        window.alert(
          t("ルームログ削除後も再ログインするまでメッセージは保持されます")
        );
      });
    }
  }, [roomId, setOpen, t, dispatch]);

  const onClickPopup = useCallback(() => {
    setOpen(false);
    dispatch(
      appStateMutate((state) => {
        state.openRoomDisplays = null;
      })
    );

    const width = 380;
    const height = 640;

    window.open(
      `/rooms/${roomId}/chat`,
      "ccfolia.chat",
      `resizable,scrollbars,status,width=${width},height=${height},left=${
        window.innerWidth * 0.5 - width * 0.5
      },top=${window.innerHeight * 0.5 - height * 0.5}`
    );
  }, [setOpen, dispatch, roomId]);

  const onClickWideMode = useCallback(() => {
    dispatch(
      appStateMutate((state) => {
        state.roomChatWideMode = !state.roomChatWideMode;
      })
    );
  }, [dispatch]);

  const onClickOpenChatTabSetting = useCallback(() => {
    dispatch(
      appStateMutate((state) => {
        state.openChatTabSettings = true;
        state.openChatTabSettingsId = tab;
      })
    );
    onClose();
  }, [tab, dispatch, onClose]);

  const updateProgress = useCallback(
    (value: number, total: number) => {
      dispatch(
        appStateMutate((state) => {
          state.progress.value = value;
          state.progress.total = total;
        })
      );
    },
    [dispatch]
  );

  const onClickExportLog = useCallback(async () => {
    if (!hasEditableRole) {
      return;
    }

    // todo: move to operation
    dispatch(
      appStateMutate((state) => {
        state.progress = {
          isVisible: true,
          value: 0,
          total: 0,
        };
      })
    );
    try {
      await downloadRoomLog(
        {
          roomId: roomId,
          uid: uid,
          includeSecret: true,
          channel: null,
          channelName: "all",
          isPro,
        },
        updateProgress
      );
    } catch (_) {
      window.alert(t("処理に失敗しました"));
    }
    dispatch(
      appStateMutate((state) => {
        state.progress = {
          isVisible: false,
          value: 0,
          total: 0,
        };
      })
    );
  }, [hasEditableRole, dispatch, roomId, uid, isPro, updateProgress, t]);

  const onClickExportCurrentTabLog = useCallback(async () => {
    // todo: move to operation
    dispatch(
      appStateMutate((state) => {
        state.progress = {
          isVisible: true,
          value: 0,
          total: 0,
        };
      })
    );
    try {
      await downloadRoomLog(
        {
          roomId: roomId,
          uid: uid,
          includeSecret: false,
          channel: tab,
          channelName: tabName,
          isPro,
        },
        updateProgress
      );
    } catch (_) {
      window.alert(t("処理に失敗しました"));
    }
    dispatch(
      appStateMutate((state) => {
        state.progress = {
          isVisible: false,
          value: 0,
          total: 0,
        };
      })
    );
  }, [dispatch, roomId, uid, tab, tabName, isPro, updateProgress, t]);

  const onClickMessageChannelRemove = useCallback(() => {
    if (window.confirm(t("本当に削除しますか？"))) {
      setOpen(false);
      dispatch(removeRoomMessageGroup(tab));
    }
  }, [tab, setOpen, t, dispatch]);

  return (
    <>
      <Tooltip title={t("チャットメニュー")}>
        <IconButton onClick={onOpen} ref={anchorEl} edge="end" size="large">
          <SettingsIcon />
        </IconButton>
      </Tooltip>
      <Menu open={open} anchorEl={anchorEl.current} onClose={onClose}>
        {!standalone && (
          <MenuItem onClick={onClickPopup}>{t("別窓で表示（beta）")}</MenuItem>
        )}
        {!standalone && (
          <MenuItem onClick={onClickWideMode}>
            {t("ワイドモード切り替え")}
          </MenuItem>
        )}
        <MenuItem onClick={onClickExportLog} disabled={!hasEditableRole}>
          {isPro ? (
            <>
              {t("全ログ出力+")}
              <CcfoliaProIcon height="12px" style={{ marginLeft: "6px" }} />
            </>
          ) : (
            t("全ログ出力")
          )}
        </MenuItem>
        <MenuItem onClick={onClickExportCurrentTabLog}>
          {isPro ? (
            <>
              {t("ログ出力+")}
              <CcfoliaProIcon height="12px" style={{ marginLeft: "6px" }} />
            </>
          ) : (
            t("ログ出力")
          )}
        </MenuItem>
        <MenuItem
          onClick={onClickOpenChatTabSetting}
          disabled={!isGroupTab || !hasEditableRole}
        >
          {t("タブの編集")}
        </MenuItem>
        <MenuItem
          onClick={onClickMessageChannelRemove}
          disabled={!isGroupTab || !hasEditableRole}
        >
          {t("現在タブ削除")}
        </MenuItem>
        <MenuItem onClick={onDeleteAllMessages} disabled={!hasEditableRole}>
          {t("ルームログ削除")}
        </MenuItem>
      </Menu>
    </>
  );
};

export default memo(ChatMenu);
