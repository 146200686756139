import React, { FC, useCallback, memo } from "react";
import { shallowEqual } from "react-redux";
import { useAppDispatch, useAppSelector } from "stores";
import store from "stores/interfaces";
import { Divider, Menu, MenuItem } from "@mui/material";
import { useTranslation } from "react-i18next";
import { addUndo } from "stores/modules/entities.room.histories/slice";
import { getRoomMarkerById } from "stores/modules/entities.rooms/selectors";

const MarkerMenu: FC = () => {
  const dispatch = useAppDispatch();
  const [t] = useTranslation();
  const [open, top, left, markerId, roomId] = useAppSelector((state) => {
    const markerId = store.getAppState(state, "openRoomMarkerMenuId") ?? "";
    const roomId = store.getAppState(state, "roomId") ?? "";
    return [
      store.getAppState(state, "openRoomMarkerMenu"),
      store.getAppState(state, "roomPointerY"),
      store.getAppState(state, "roomPointerX"),
      markerId,
      roomId,
    ] as const;
  }, shallowEqual);
  const marker = useAppSelector((state) =>
    getRoomMarkerById(state, roomId, markerId)
  );
  const onClose = useCallback(() => {
    dispatch(
      store.appStateMutate((state) => {
        state.openRoomMarkerMenu = false;
      })
    );
  }, [dispatch]);
  const onDeleteMarker = useCallback(() => {
    if (window.confirm(t("本当に削除しますか？"))) {
      if (markerId == null) return;
      dispatch(store.deleteRoomMarker(roomId, markerId));
      dispatch(
        addUndo({
          kind: "update-marker",
          id: markerId,
          before: marker,
          after: null,
        })
      );
      onClose();
    }
  }, [markerId, roomId, onClose, dispatch, t, marker]);
  const onEditMarker = useCallback(() => {
    dispatch(
      store.appStateMutate((state) => {
        state.openRoomMarkerDetail = true;
        state.openRoomMarkerDetailId = markerId;
      })
    );
    onClose();
  }, [markerId, onClose, dispatch]);
  const onCloneMarker = useCallback(() => {
    dispatch(store.cloneRoomMarker(markerId));
    onClose();
  }, [dispatch, markerId, onClose]);

  return (
    <MemoMarkerMenuInternal
      open={open}
      top={top}
      left={left}
      onClose={onClose}
      onDeleteMarker={onDeleteMarker}
      onEditMarker={onEditMarker}
      onCloneMarker={onCloneMarker}
    />
  );
};

type MarkerMenuInternalProps = {
  open: boolean;
  top: number;
  left: number;
  onClose: () => void;
  onDeleteMarker: () => void;
  onEditMarker: () => void;
  onCloneMarker: () => void;
};

const MarkerMenuInternal: React.FC<MarkerMenuInternalProps> = (props) => {
  const {
    open,
    left,
    top,
    onClose,
    onDeleteMarker,
    onEditMarker,
    onCloneMarker,
  } = props;
  const [t] = useTranslation();

  return (
    <Menu
      open={open}
      anchorReference="anchorPosition"
      anchorPosition={{ left, top }}
      onClose={onClose}
      MenuListProps={{ dense: true }}
      disableRestoreFocus
    >
      <MenuItem onClick={onEditMarker}>{t("編集")}</MenuItem>
      <MenuItem onClick={onCloneMarker}>{t("複製")}</MenuItem>
      <Divider style={{ marginTop: 8, marginBottom: 8 }} />
      <MenuItem onClick={onDeleteMarker}>{t("削除")}</MenuItem>
    </Menu>
  );
};

const MemoMarkerMenuInternal = memo(MarkerMenuInternal);

export default memo(MarkerMenu);
