import { Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import theme from "theme";

const community = [
  {
    img: "/images/top/community-x.png",
    name: "X",
    text: "ココフォリアのアップデートやCCFOLIA GAMES作品のリリース情報など、ココフォリアに関する最新の告知はこちら。",
    link: "https://x.com/ccfolia",
  },
  {
    img: "/images/top/community-discord.png",
    name: "Discord",
    text: "公式運営Discordサーバー。ココフォリアに関する質問やご要望はこちら。",
    link: "https://discord.gg/VTg5VHPZBw",
  },
  {
    img: "/images/top/community-fanbox.png",
    name: "開発支援（FANBOX）",
    text: "PIXIVFANBOXのココフォリア公式アカウント。開発支援者の方を募集しています。過去のアップデート内容の確認もこちら。",
    link: "https://ccfolia.fanbox.cc/",
  },
  {
    img: "/images/top/community-docs.png",
    name: "公式ドキュメント",
    text: "ココフォリアの使い方を解説。操作に関するよくある質問などもこちら。",
    link: "https://docs.ccfolia.com/",
  },
];

const Community = () => {
  const [t] = useTranslation();

  return (
    <CommunityContainer>
      <CommunityContainerTitle> {t("コミュニティ")}</CommunityContainerTitle>
      <CommunityList>
        {community.map((item, index) => (
          <CommunityListCard key={index}>
            <a href={item.link} target="_blank" rel="noreferrer">
              <div className="card__head">
                <img src={item.img} alt="" />
              </div>
              <CardTitle color="textPrimary">{t(item.name)}</CardTitle>
              <CardText color="textSecondary">{t(item.text)}</CardText>
            </a>
          </CommunityListCard>
        ))}
      </CommunityList>
    </CommunityContainer>
  );
};

const CommunityContainer = styled.section`
  margin: 80px auto 0;
  font-family: "Noto Sans JP", sans-serif;
  -webkit-font-smoothing: antialiased;
  ${theme.breakpoints.down("md")} {
    margin: 80px auto 0;
  }
`;
const CommunityContainerTitle = styled.h3`
  color: #fff;
  text-align: center;
  font-size: 32px;
  font-weight: 700;
  ${theme.breakpoints.down("md")} {
    font-size: 24px;
  }
`;
const CommunityList = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 0 calc(24 / 760 * 100%);
  width: calc(760 / 1400 * 100%);
  max-width: 760px;
  margin: 24px auto 0;
  ${theme.breakpoints.down("md")} {
    grid-template-columns: repeat(2, 1fr);
    gap: 24px;
    width: calc(342 / 390 * 100%);
  }
`;
const CommunityListCard = styled.div`
  a {
    text-decoration: none;
  }
  &:hover {
    opacity: 0.7;
    transition: opacity 0.15s ease-in-out;
  }
  .card__head {
    img {
      width: 100%;
    }
  }
  .card__title {
  }
`;

const CardTitle = styled(Typography)`
  margin-top: 8px;
  font-weight: 700;
  font-size: 16px;
  text-align: center;
  ${theme.breakpoints.down("md")} {
    font-size: 14px;
  }
`;
const CardText = styled(Typography)`
  margin-top: 8px;
  text-align: justify;
  font-feature-settings: "halt" on;
  font-size: 12px;
  font-weight: 400;
  line-height: 1.6;
`;

export default Community;
