import { actions } from "./slice";
import { db } from "initializer";
import { createSubscribeCollection } from "../firestoreModuleUtils/operators";
import { appStateMutate } from "../app.state/operations";
import {
  addMessage,
  addMessageWithRoll,
} from "../entities.room.messages/operations";
import { getRoomDiceById } from "./selectors";
import { DefaultThunk } from "stores";
import { DiceItemRecord, UpdateDiceItem } from "./records";
import { getAppState } from "../app.state/selectors";
import { getUid } from "../app.user/selectors";
import {
  DocumentData,
  FirestoreDataConverter,
  addDoc,
  collection,
  deleteDoc,
  doc,
  setDoc,
  updateDoc,
} from "firebase/firestore";
import { addUndo } from "../entities.room.histories/slice";
import { rollDiceSymbol } from "modules/diceroll/rollDicesymbol";
import i18next from "i18next";

const diceConverter: FirestoreDataConverter<DocumentData> = {
  toFirestore(item: UpdateDiceItem) {
    return item;
  },
  fromFirestore(snapshot, options): DocumentData {
    const data = snapshot.data(options)!;
    return DiceItemRecord(data);
  },
};

export const dicesRef = (roomId: string) =>
  collection(db, "rooms", roomId, "dices").withConverter(diceConverter);

export const subscribeRoomDices = createSubscribeCollection(actions, dicesRef);

export const addRoomDice =
  (data: UpdateDiceItem): DefaultThunk =>
  async (dispatch, getState) => {
    const state = getState();
    const uid = getUid(state);
    const roomId = getAppState(state, "roomId");
    const name = getAppState(state, "roomChatName") || "";

    if (!roomId || !uid) return null;
    const item = {
      x: typeof data.x === "number" && !isNaN(data.x) ? data.x : -1,
      y: typeof data.y === "number" && !isNaN(data.y) ? data.y : -1,
      width: 2,
      height: 2,
      faces: 6,
      value: 1,
      closed: false,
      name,
      owner: uid,
      changeCount: 0,
      createdAt: Date.now(),
      updatedAt: Date.now(),
    };
    const doc = await addDoc(dicesRef(roomId), item);
    dispatch(
      addUndo({
        kind: "update-dice",
        id: doc.id,
        before: null,
        after: item,
      })
    );
  };

export const addRoomDiceById =
  (diceId: string, item: UpdateDiceItem): DefaultThunk =>
  (_, getState) => {
    const roomId = getState().app.state.roomId;
    if (!roomId) return null;
    return setDoc(doc(dicesRef(roomId), diceId), {
      ...item,
      updatedAt: Date.now(),
    });
  };

export const updateRoomDice =
  (diceId: string, item: UpdateDiceItem): DefaultThunk =>
  (_, getState) => {
    const roomId = getState().app.state.roomId;
    if (!roomId) return null;
    return updateDoc(doc(dicesRef(roomId), diceId), {
      ...item,
      updatedAt: Date.now(),
    });
  };

export const updateCurrentRoomDice =
  (item: UpdateDiceItem, id?: string): DefaultThunk =>
  (dispatch, getState) => {
    const state = getState();
    const roomId = state.app.state.roomId;
    const name = state.app.state.roomChatName;
    const diceId = id ? id : state.app.state.openRoomDiceDetailId;
    if (!roomId || !diceId) return null;
    const dice = getRoomDiceById(state, diceId);
    if (!item.closed) {
      if (dice.closed) {
        const text =
          i18next.t("/system [ {{name}} ] がダイスシンボルを公開。", { name }) +
          (item.faces !== 0
            ? i18next.t("出目は {{value}} です。", { value: item.value })
            : "");
        dispatch(
          addMessage(
            roomId,
            null,
            {
              text,
              name: "system",
              channel: "main",
            },
            false
          )
        );
      } else if (item.value !== dice.value) {
        const text =
          item.faces !== 0
            ? i18next.t(
                "/system [ {{name}} ] がダイスシンボルを {{value}} に変更しました。",
                { name, value: item.value }
              )
            : i18next.t(
                "/system [ {{name}} ] がダイスシンボルを変更しました。",
                { name }
              );
        dispatch(
          addMessage(
            roomId,
            null,
            {
              text,
              name: "system",
              channel: "main",
            },
            false
          )
        );
      }
    }
    dispatch(appStateMutate((state) => (state.openRoomDiceDetail = false)));
    if (id) {
      return setDoc(doc(dicesRef(roomId), diceId), {
        ...item,
        updatedAt: Date.now(),
      });
    } else {
      return updateDoc(doc(dicesRef(roomId), diceId), {
        ...item,
        updatedAt: Date.now(),
      });
    }
  };

type DiceSymbolFormValue = {
  faces: number;
  closed: boolean;
};

export const updateRollRoomDice =
  (diceId: string, { faces, closed }: DiceSymbolFormValue): DefaultThunk =>
  async (dispatch, getState) => {
    const state = getState();
    const roomId = state.app.state.roomId;
    const dice = getRoomDiceById(state, diceId);

    if (!roomId) return null;

    const value = rollDiceSymbol(faces);

    if (value) {
      dispatch(
        addMessageWithRoll({
          textData: {
            text:
              `${closed ? "s" : ""}1D${faces} ` + i18next.t("ダイスシンボル"),
            channel: "main",
          },
          roll: {
            result: `(1D${faces}) ＞ ${value}`,
            dices: [{ kind: "normal", faces, value }],
            secret: closed,
            success: false,
            failure: false,
            critical: false,
            fumble: false,
          },
        })
      );

      await updateDoc(doc(dicesRef(roomId), diceId), {
        faces,
        value,
        closed,
        updatedAt: Date.now(),
      });
      dispatch(
        addUndo({
          kind: "update-dice",
          id: diceId,
          before: dice,
          after: { ...dice, faces, value, closed },
          hasChangeValueMessage: true,
        })
      );
    }
  };

export const deleteRoomDice =
  (diceId: string | undefined): DefaultThunk =>
  (dispatch, getState) => {
    if (diceId == null) return;
    const roomId = getState().app.state.roomId;
    if (!roomId) return null;
    dispatch(appStateMutate((state) => (state.openRoomDiceDetail = false)));
    return deleteDoc(doc(dicesRef(roomId), diceId));
  };
