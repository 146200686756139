import { memo, ReactNode } from "react";
import { DragOverlay, useDndContext } from "@dnd-kit/core";
import { createPortal } from "react-dom";
import theme from "theme";

type SortableListContextProps = {
  overlay: (id: string) => ReactNode;
};

const SortableListOverlay = ({ overlay }: SortableListContextProps) => {
  const { active } = useDndContext();

  return (
    <>
      {createPortal(
        <DragOverlay style={{ zIndex: theme.zIndex.drawer + 1 }}>
          {active && overlay(active.id.toString())}
        </DragOverlay>,
        document.body
      )}
    </>
  );
};

export default memo(SortableListOverlay);
