import styled from "styled-components";
import {
  Container,
  Typography,
  Button,
  Paper,
  Grid,
  Card,
  CardContent,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableBody,
  TableCell,
  CircularProgress,
  Link,
} from "@mui/material";
import theme from "theme";
import { useTranslation } from "react-i18next";
import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import { ReactComponent as CcfoliaProLogo } from "./CcfoliaProLogo.svg";
import StaticHeader from "containers/StaticHeader";
import { createSubscription } from "api";
import { auth } from "initializer";
import { useEffect, useState } from "react";
import { getIdTokenResult, onAuthStateChanged } from "firebase/auth";

const Welcome = () => {
  const [t] = useTranslation();
  const year = new Date().getFullYear();
  const [isPro, setIsPro] = useState(false);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        getIdTokenResult(user).then((token) => {
          setIsPro(token.claims.plan === "ccfolia-pro");
        });
      }
    });

    return unsubscribe;
  }, [setIsPro]);

  const onClickUpgrade = async () => {
    if (isPro) {
      return;
    }

    if (auth.currentUser == null || auth.currentUser.isAnonymous) {
      window.alert(t("CCFOLIA PROに加入するには、ログインしてください。"));
      return;
    }

    const { url } = await createSubscription();
    window.location.href = url;
  };

  return (
    <StyledWrapper>
      <StaticHeader />
      <FirstSection>
        <StyledTitle
          variant="h4"
          align="center"
          color="textPrimary"
          gutterBottom
        >
          {t("提供プラン")}
        </StyledTitle>
        <Container maxWidth="md">
          <Grid
            container
            spacing={3}
            alignItems="stretch"
            justifyContent="center"
          >
            <Grid item xs={12} sm={6}>
              <Card
                variant="outlined"
                style={{
                  height: "100%",
                  background: theme.palette.grey[900],
                }}
              >
                <CardContent
                  style={{
                    background: theme.palette.background.default,
                  }}
                >
                  <Typography align="center" style={{ marginBottom: "8px" }}>
                    CCFOLIA BASIC
                  </Typography>
                  <Typography
                    variant="caption"
                    align="center"
                    display="block"
                    color="textSecondary"
                  >
                    {t("これまで通りの基本プラン")}
                  </Typography>
                </CardContent>
                <PriceArea>
                  <Typography variant="h4" align="center" paragraph>
                    {t("無料")}
                  </Typography>
                </PriceArea>
              </Card>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Card
                variant="outlined"
                style={{
                  borderColor: theme.palette.secondary.main,
                  height: "100%",
                  background: theme.palette.grey[900],
                }}
              >
                <CardContent
                  style={{
                    background: theme.palette.background.default,
                  }}
                >
                  <LogoWrapper>
                    <CcfoliaProLogo role="img" aria-label="CCFOLIA PRO" />
                  </LogoWrapper>
                  <Typography
                    variant="caption"
                    align="center"
                    display="block"
                    color="textSecondary"
                  >
                    {t("ココフォリアのプロになれる新プラン")}
                  </Typography>
                </CardContent>
                <PriceArea>
                  <Typography variant="h4" align="center" paragraph>
                    <Typography variant="caption">{t("月々")}</Typography> 500{" "}
                    <Typography variant="caption">{t("円")}</Typography>
                  </Typography>
                  <ButtonUpgrade isPro={isPro} onClick={onClickUpgrade} />
                </PriceArea>
              </Card>
            </Grid>
          </Grid>
        </Container>
      </FirstSection>
      <GlaySection>
        <Container maxWidth="md">
          <SubHeading>{t("プランの比較")}</SubHeading>
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell size="small"></TableCell>
                  <TableCellPlanName>CCFOLIA BASIC</TableCellPlanName>
                  <TableCellPlanName>CCFOLIA PRO</TableCellPlanName>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  <TableCellTh>{t("価格")}</TableCellTh>
                  <TableCell align="center">
                    <Typography variant="h5">{t("無料")}</Typography>
                  </TableCell>
                  <TableCell align="center">
                    <Typography
                      variant="caption"
                      color="textSecondary"
                      display="inline"
                    >
                      {t("月々")}
                    </Typography>
                    <br />
                    <Typography variant="h4" display="inline">
                      500
                    </Typography>
                    <Typography
                      variant="caption"
                      color="textSecondary"
                      display="inline"
                    >
                      {t("円（税込）")}
                    </Typography>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCellTh>{t("基本機能")}</TableCellTh>
                  <TableCell align="center">
                    <Yes />
                  </TableCell>
                  <TableCell align="center">
                    <Yes />
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCellTh>
                    <Word>{t("サウンド")}</Word>
                    <Word>{t("ライブラリ")}</Word>
                    <br />
                    <Typography variant="caption" color="textSecondary">
                      {t("(Audiostock 提供楽曲)")}
                    </Typography>
                  </TableCellTh>
                  <TableCell align="center">
                    <No />
                  </TableCell>
                  <TableCell align="center">
                    <Yes />
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCellTh>{t("ルームデータ複製・出力")}</TableCellTh>
                  <TableCell align="center">
                    <No />
                  </TableCell>
                  <TableCell align="center">
                    <Yes />
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCellTh>{t("タイマー")}</TableCellTh>
                  <TableCell align="center">
                    <No />
                  </TableCell>
                  <TableCell align="center">
                    <Yes />
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCellTh>
                    <Word>{t("告知枠表示設定")}</Word>
                  </TableCellTh>
                  <TableCell align="center">
                    <No />
                  </TableCell>
                  <TableCell align="center">
                    <Yes />
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCellTh>
                    {t("スラッシュコマンド")}
                    <br />
                    <Typography variant="caption" color="textSecondary">
                      {t("(チャットでのルーム操作)")}
                    </Typography>
                  </TableCellTh>
                  <TableCell align="center">
                    <No />
                  </TableCell>
                  <TableCell align="center">
                    <Yes />
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCellTh>
                    {t("チャットログ出力")}{" "}
                    <Typography variant="caption" color="secondary">
                      NEW!
                    </Typography>
                  </TableCellTh>
                  <TableCell align="center">
                    <Typography variant="body1" display="inline">
                      {t("最大1万件")}
                    </Typography>
                  </TableCell>
                  <TableCell align="center">
                    <Typography variant="body1" display="inline">
                      {t("最大10万件")}
                    </Typography>
                    {/* <Trans>
                      <Typography variant="h5" display="inline">
                        100,000
                      </Typography>
                      <Typography variant="body1" display="inline">
                        件
                      </Typography>
                    </Trans> */}
                  </TableCell>
                </TableRow>
                {/* <TableRow>
                  <TableCellTh>
                    エモート
                    <Typography variant="caption" color="secondary">
                      NEW!
                    </Typography>
                  </TableCellTh>
                  <TableCell align="center">
                    <No />
                  </TableCell>
                  <TableCell align="center">
                    <Yes />
                  </TableCell>
                </TableRow> */}
              </TableBody>
            </Table>
          </TableContainer>
        </Container>
      </GlaySection>
      <Section>
        <SubHeading align="center">{t("よくある質問")}</SubHeading>
        <Container maxWidth="md">
          <ArticleQuestion>
            <Grid container wrap="nowrap" spacing={2}>
              <Grid item>
                <Typography variant="h6" color="textSecondary">
                  Q.
                </Typography>
              </Grid>
              <Grid item xs zeroMinWidth>
                <Typography color="textSecondary">
                  {t("サウンドライブラリとはどのようなものですか？")}
                </Typography>
              </Grid>
            </Grid>
            <Grid container wrap="nowrap" spacing={2}>
              <Grid item>
                <Typography variant="h6" color="secondary">
                  A.
                </Typography>
              </Grid>
              <Grid item xs zeroMinWidth>
                <Typography color="textPrimary">
                  {t(
                    "ツール内にあらかじめ用意されているセッションやセッション配信で自由に利用可能な音源素材です。音楽を使うことで、セッションをより盛り上げることができます。"
                  )}
                </Typography>
              </Grid>
            </Grid>
          </ArticleQuestion>
          <ArticleQuestion>
            <Grid container wrap="nowrap" spacing={2}>
              <Grid item>
                <Typography variant="h6" color="textSecondary">
                  Q.
                </Typography>
              </Grid>
              <Grid item xs zeroMinWidth>
                <Typography color="textSecondary">
                  {t("CCFOLIA PRO では告知枠が見えなくなってしまうのですか？")}
                </Typography>
              </Grid>
            </Grid>
            <Grid container wrap="nowrap" spacing={2}>
              <Grid item>
                <Typography variant="h6" color="secondary">
                  A.
                </Typography>
              </Grid>
              <Grid item xs zeroMinWidth>
                <Typography color="textPrimary">
                  {t(
                    "告知枠の表示設定はいつでも切り替えることができます。配信や録画などの関係で告知枠が表示されて欲しくない場合にオプションでお使いいただける機能です。"
                  )}
                </Typography>
              </Grid>
            </Grid>
          </ArticleQuestion>
          {/* <ArticleQuestion>
            <Grid container wrap="nowrap" spacing={2}>
              <Grid item>
                <Typography variant="h6" color="textSecondary">
                  Q.
                </Typography>
              </Grid>
              <Grid item xs zeroMinWidth>
                <Typography color="textSecondary">
                  クレジットカードを持っていないのですが、支払いはできますか？
                </Typography>
              </Grid>
            </Grid>
            <Grid container wrap="nowrap" spacing={2}>
              <Grid item>
                <Typography variant="h6" color="secondary">
                  A.
                </Typography>
              </Grid>
              <Grid item xs zeroMinWidth>
                <Typography color="textPrimary">
                  クレジットカードをお持ちでない場合は、Kyash等の電子ウォレットサービスをご利用いただくことができます。
                </Typography>
              </Grid>
            </Grid>
          </ArticleQuestion> */}
          <ArticleQuestion>
            <Grid container wrap="nowrap" spacing={2}>
              <Grid item>
                <Typography variant="h6" color="textSecondary">
                  Q.
                </Typography>
              </Grid>
              <Grid item xs zeroMinWidth>
                <Typography color="textSecondary">
                  {t("収益はどのように使われますか？")}
                </Typography>
              </Grid>
            </Grid>
            <Grid container wrap="nowrap" spacing={2}>
              <Grid item>
                <Typography variant="h6" color="secondary">
                  A.
                </Typography>
              </Grid>
              <Grid item xs zeroMinWidth>
                <Typography color="textPrimary">
                  {t(
                    "主にココフォリアのサーバーや開発・運営にかかる費用と、開発メンバーの給与に当てられます。"
                  )}
                </Typography>
              </Grid>
            </Grid>
          </ArticleQuestion>
          <ArticleQuestion>
            <Grid container wrap="nowrap" spacing={2}>
              <Grid item>
                <Typography variant="h6" color="textSecondary">
                  Q.
                </Typography>
              </Grid>
              <Grid item xs zeroMinWidth>
                <Typography color="textSecondary">
                  {t("今後、追加予定の機能はありますか？")}
                </Typography>
              </Grid>
            </Grid>
            <Grid container wrap="nowrap" spacing={2}>
              <Grid item>
                <Typography variant="h6" color="secondary">
                  A.
                </Typography>
              </Grid>
              <Grid item xs zeroMinWidth>
                <Typography color="textPrimary">
                  {t(
                    "よりセッションを盛り上げるための便利機能を追加していく予定です。お楽しみに！"
                  )}
                </Typography>
              </Grid>
            </Grid>
          </ArticleQuestion>
        </Container>
      </Section>
      <StyledFooter>
        <Typography color="textSecondary" align="center">
          &copy; {year} ccfolia.com
        </Typography>
        <div style={{ textAlign: "center" }}>
          <Typography variant="caption" color="textSecondary">
            <Link href="/termsOfService.html" target="_blank" underline="hover">
              {t("利用規約")}
            </Link>
          </Typography>
          <Typography
            variant="caption"
            color="textSecondary"
            style={{ marginLeft: 8 }}
          >
            <Link
              href="/termsOfSoundLibrary.html"
              target="_blank"
              underline="hover"
            >
              {t("音源利用規約")}
            </Link>
          </Typography>
          <Typography
            variant="caption"
            color="textSecondary"
            style={{ marginLeft: 8 }}
          >
            <Link href="/termsForSales.html" target="_blank" underline="hover">
              {t("特定商取引法に基づく表示")}
            </Link>
          </Typography>
        </div>
      </StyledFooter>
    </StyledWrapper>
  );
};

type ButtonUpgradeProps = {
  isPro: boolean;
  onClick: () => Promise<void>;
};

const ButtonUpgrade = ({ isPro, onClick }: ButtonUpgradeProps) => {
  const [t] = useTranslation();
  const [loading, setLoading] = useState(false);

  if (loading) {
    return (
      <Button variant="contained" fullWidth disabled>
        <CircularProgress color="primary" size={24.5} />
      </Button>
    );
  }

  const handleClick = () => {
    setLoading(true);
    onClick().finally(() => setLoading(false));
  };

  return (
    <Button
      variant="contained"
      color="secondary"
      fullWidth
      disabled={isPro}
      onClick={handleClick}
    >
      {isPro ? t("加入済み") : t("CCFOLIA PROに加入")}
    </Button>
  );
};

const Yes = () => <CheckIcon fontSize="large" color="secondary" />;
const No = () => <CloseIcon fontSize="large" color="disabled" />;

const StyledWrapper = styled.div`
  text-shadow: 0 0 2px rgba(0, 0, 0, 0.2);
  box-sizing: border-box;
  background-size: cover;
  //min-height: 100vh;
  position: relative;
`;

const FirstSection = styled(Container)`
  padding: 96px 0 56px;
`;

const Section = styled(Container)`
  padding: 56px 0;
`;

const GlaySection = styled(Paper)`
  padding: 56px 0;
`;

const StyledTitle = styled(Typography)`
  font-weight: bold;
  line-height: 1.4;
  margin-bottom: 24px;
`;

const StyledFooter = styled.div`
  margin-top: 32px;
  padding: 16px;
  // background: rgba(0, 0, 0, 0.6);
  position: relative;
  z-index: 1;
`;

const SubHeading = styled(Typography).attrs({
  variant: "h6",
  color: "textPrimary",
  align: "center",
})`
  margin: 0 0 24px 0;
`;

const PriceArea = styled(CardContent)`
  background: ${theme.palette.grey[900]};
  padding: 24px 16px;
`;

const TableCellPlanName = styled(TableCell).attrs({
  align: "center",
  size: "small",
})`
  background: ${theme.palette.grey[900]};
`;

const TableCellTh = styled(TableCell).attrs({
  component: "th",
  scope: "row",
  align: "center",
})`
  background: #202020;
  color: ${theme.palette.text.secondary};
`;

const ArticleQuestion = styled.div`
  margin: 24px 0;
`;

const Word = styled.span`
  white-space: nowrap;
`;

const LogoWrapper = styled.div`
  display: flex;
  justify-content: center;

  padding: 4px 0;
  margin-bottom: 8px;
`;

export default Welcome;
