/**
 * マーカーパネル
 */

import React, { MouseEvent, TouchEvent, useCallback, useState } from "react";
import styled from "styled-components";
import { shallowEqual } from "react-redux";
import { useAppDispatch, useAppSelector } from "stores";
import store from "stores/interfaces";
import { Tooltip } from "@mui/material";
import useLongTap from "hooks/longTap";
import toCDNUrl from "modules/toCDNUrl";
import { handleClickAction } from "modules/clickActionHandler";
import { addUndo } from "stores/modules/entities.room.histories/slice";
import DraggableItem from "containers/DraggableItem";
import { getCurrentRoom } from "stores/modules/entities.rooms/selectors";
import { fixSelectionOnDragStop } from "modules/drag";

const enableProps = {
  right: true,
  bottom: true,
  bottomRight: true,
};

type MarkerProps = {
  markerId: string;
  roomId: string;
};

const Marker: React.FC<MarkerProps> = (props) => {
  const { markerId, roomId } = props;
  const dispatch = useAppDispatch();
  const [dragging, setDragging] = useState(false);
  const isClicked = React.useRef(false);
  const { marker, isRoleAudience } = useAppSelector((state) => {
    return {
      marker: store.getRoomMarkerById(state, roomId, markerId),
      isRoleAudience: store.getIsRoleAudience(state),
    };
  }, shallowEqual);
  const alignWithGrid = useAppSelector(
    (state) => getCurrentRoom(state)?.alignWithGrid ?? true
  );

  const onContextMenu = React.useCallback(
    (e) => {
      e.preventDefault();
      e.stopPropagation();
      if (isRoleAudience) {
        return;
      }

      dispatch(
        store.appStateMutate((state) => {
          state.openRoomMarkerMenu = true;
          state.openRoomMarkerMenuId = markerId;
          state.roomPointerY = e.pageY || e.touches[0]?.clientY || 0;
          state.roomPointerX = e.pageX || e.touches[0]?.clientX || 0;
        })
      );
    },
    [markerId, isRoleAudience, dispatch]
  );
  const onClick = useCallback(
    (e: React.MouseEvent) => {
      e.preventDefault();

      if (marker.clickAction != null && isClicked.current) {
        return;
      }

      isClicked.current = true;

      setTimeout(() => {
        isClicked.current = false;
      }, 350);

      if (dragging || marker.clickAction == null || isRoleAudience) {
        return;
      }

      handleClickAction(marker.clickAction, dispatch);
    },
    [dragging, marker.clickAction, isRoleAudience, dispatch]
  );
  const onDoubleClick = React.useCallback(
    (e) => {
      e.stopPropagation();
      if (isRoleAudience || marker.clickAction != null) {
        return;
      }

      dispatch(
        store.appStateMutate((state) => {
          state.openRoomMarkerDetail = true;
          state.openRoomMarkerDetailId = markerId;
        })
      );
    },
    [markerId, isRoleAudience, marker.clickAction, dispatch]
  );
  const onUpdate = React.useCallback(
    (position) => {
      dispatch(store.updateRoomMarker(roomId, markerId, position));
      dispatch(
        addUndo({
          kind: "update-marker",
          id: markerId,
          before: marker,
          after: { ...marker, ...position },
        })
      );
    },
    [markerId, roomId, dispatch, marker]
  );
  const longTapProps = useLongTap(onContextMenu);

  const onMouseDown = useCallback(
    (e: MouseEvent | TouchEvent) => {
      if (!marker.locked && !isRoleAudience) {
        e.stopPropagation();
        fixSelectionOnDragStop();
      }
    },
    [marker.locked, isRoleAudience]
  );

  const stopPropagation = useCallback(
    (e: MouseEvent | TouchEvent) => {
      if (!marker.locked && !isRoleAudience) {
        e.stopPropagation();
      }
    },
    [marker.locked, isRoleAudience]
  );

  const hasClickAction = marker.clickAction != null;
  const disableDragging = marker.locked || isRoleAudience;
  const enableResizing = !marker.locked && !marker.freezed && !isRoleAudience;
  const imageUrl = marker.imageUrl || "/blank.gif";

  return (
    <DraggableItem
      initialPosition={{
        x: marker.x,
        y: marker.y,
        width: marker.width,
        height: marker.height,
      }}
      draggableId={markerId}
      disableDragging={disableDragging}
      enableResizing={enableResizing}
      enableProps={enableProps}
      alignWithGrid={alignWithGrid}
      setDragging={setDragging}
      onUpdate={onUpdate}
      onContextMenu={onContextMenu}
      onClick={onClick}
      onDoubleClick={onDoubleClick}
      onTouchStart={stopPropagation}
      onMouseDown={onMouseDown}
      style={{
        zIndex: marker.z,
      }}
    >
      <Tooltip title={marker.text || ""} placement="bottom">
        <MarkerContainer
          {...longTapProps}
          locked={disableDragging}
          style={{ cursor: hasClickAction ? "pointer" : undefined }}
        >
          <img
            src={toCDNUrl(imageUrl)}
            draggable={false}
            width="100%"
            height="100%"
          />
        </MarkerContainer>
      </Tooltip>
    </DraggableItem>
  );
};

const MarkerContainer = styled.div<{ locked: boolean }>`
  width: 100%;
  height: 100%;
  &:hover {
    background: ${(p: { locked: boolean }) =>
      p.locked ? "none" : "rgba(0, 0, 0, 0.2)"};
  }
  img {
    pointer-events: none;
  }
`;

export default React.memo(Marker);
