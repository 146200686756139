import { PointerEvent, useCallback, useRef, useState } from "react";
import { shallowEqual } from "react-redux";
import { useAppDispatch, useAppSelector } from "stores";
import store from "stores/interfaces";
import { Divider, Menu, MenuItem, Typography, styled } from "@mui/material";
import ArrowRightIcon from "@mui/icons-material/ArrowRight";
import { useTranslation } from "react-i18next";
import { addUndoDeleteDeck } from "stores/modules/entities.room.decks/operations";
import { addUndo } from "stores/modules/entities.room.histories/slice";

const DeckMenu = () => {
  const [t] = useTranslation();
  const dispatch = useAppDispatch();
  const [open, top, left, deckId] = useAppSelector((state) => {
    return [
      store.getAppState(state, "openRoomDeckMenu"),
      store.getAppState(state, "roomPointerY"),
      store.getAppState(state, "roomPointerX"),
      store.getAppState(state, "openRoomDeckMenuId"),
    ] as const;
  }, shallowEqual);

  const refDrawPrivateMenuAnchor = useRef<HTMLLIElement>(null);
  const refDrawClosedMenuAnchor = useRef<HTMLLIElement>(null);

  const [modeMultiDraw, setModeMultiDraw] = useState<"private" | "closed">(
    "private"
  );
  const [drawMenuAnchorEl, setDrawMenuAnchorEl] = useState<null | HTMLElement>(
    null
  );
  const openDrawMenu = drawMenuAnchorEl != null;
  const onOpenPrivateDrawMenu = useCallback(() => {
    if (open) {
      setModeMultiDraw("private");
      setDrawMenuAnchorEl(refDrawPrivateMenuAnchor.current);
    }
  }, [open, refDrawPrivateMenuAnchor, setDrawMenuAnchorEl]);
  const onOpenClosedDrawMenu = useCallback(() => {
    if (open) {
      setModeMultiDraw("closed");
      setDrawMenuAnchorEl(refDrawClosedMenuAnchor.current);
    }
  }, [open, refDrawClosedMenuAnchor, setDrawMenuAnchorEl]);

  const onEnterPrivateDrawMenu = useCallback(
    (event: PointerEvent) => {
      if (event.pointerType === "mouse") {
        onOpenPrivateDrawMenu();
      }
    },
    [onOpenPrivateDrawMenu]
  );
  const onEnterClosedDrawMenu = useCallback(
    (event: PointerEvent) => {
      if (event.pointerType === "mouse") {
        onOpenClosedDrawMenu();
      }
    },
    [onOpenClosedDrawMenu]
  );

  const onOpenCurrentMenu = useCallback(() => {
    if (modeMultiDraw === "private") {
      setDrawMenuAnchorEl(refDrawPrivateMenuAnchor.current);
    } else {
      setDrawMenuAnchorEl(refDrawClosedMenuAnchor.current);
    }
  }, [modeMultiDraw, refDrawPrivateMenuAnchor, refDrawClosedMenuAnchor]);

  const onEnterCurrentMenu = useCallback(
    (event: PointerEvent) => {
      if (event.pointerType === "mouse") {
        onOpenCurrentMenu();
      }
    },
    [onOpenCurrentMenu]
  );

  const onCloseDrawMenu = useCallback(() => {
    setDrawMenuAnchorEl(null);
  }, [setDrawMenuAnchorEl]);

  const isLocked = useAppSelector(
    (state) => store.getRoomDeckById(state, deckId || "")?.locked
  );
  const onToggleLock = useCallback(() => {
    if (deckId == null) return;
    dispatch(store.updateRoomDeck(deckId, { locked: !isLocked }));
    dispatch(
      addUndo({
        kind: "update-deck",
        id: deckId,
        cards: null,
        before: { locked: isLocked },
        after: { locked: !isLocked },
      })
    );
  }, [deckId, isLocked, dispatch]);

  const belowDeckId = useAppSelector((state) =>
    store.getRoomNearDeckId(state, deckId || "")
  );
  const onClose = useCallback(() => {
    onCloseDrawMenu();
    dispatch(
      store.appStateMutate((state) => {
        state.openRoomDeckMenu = false;
      })
    );
  }, [dispatch, onCloseDrawMenu]);
  const onEditDeck = useCallback(() => {
    dispatch(
      store.appStateMutate((state) => {
        state.openRoomDeckDetail = true;
        state.openRoomDeckDetailId = deckId;
      })
    );
    onClose();
  }, [deckId, onClose, dispatch]);

  const onDraw = useCallback(
    (amount: number) => {
      if (deckId == null) return;
      dispatch(
        store.addRandomRoomItemFromRoomDeck({ deckId, amount, mode: "private" })
      );
      onClose();
    },
    [deckId, onClose, dispatch]
  );
  const onClosedDraw = useCallback(
    (amount: number) => {
      if (deckId == null) return;
      dispatch(
        store.addRandomRoomItemFromRoomDeck({ deckId, amount, mode: "closed" })
      );
      onClose();
    },
    [deckId, onClose, dispatch]
  );

  const onDrawMulti = useCallback(
    (amount: number) => {
      if (deckId == null) return;
      dispatch(
        store.addRandomRoomItemFromRoomDeck({
          deckId,
          amount,
          mode: modeMultiDraw,
        })
      );
      onClose();
    },
    [deckId, modeMultiDraw, dispatch, onClose]
  );

  const onReset = useCallback(() => {
    if (deckId == null) return;
    dispatch(store.addRoomDeckItemsById(deckId));
    onClose();
  }, [deckId, onClose, dispatch]);
  const onMerge = useCallback(() => {
    if (belowDeckId == null || deckId == null) return;
    dispatch(store.mergeRoomDecks({ src: deckId, dest: belowDeckId }));
    onClose();
  }, [deckId, belowDeckId, onClose, dispatch]);
  const onDelete = useCallback(
    (e) => {
      if (deckId == null) return;
      e.stopPropagation();
      if (window.confirm(t("本当に削除しますか？"))) {
        dispatch(store.deleteRoomDeck(deckId));
        dispatch(addUndoDeleteDeck(deckId));
      }
      onClose();
    },
    [deckId, onClose, dispatch, t]
  );

  return (
    <>
      <Menu
        open={open}
        anchorReference="anchorPosition"
        anchorPosition={{ left, top }}
        onClose={onClose}
        MenuListProps={{ dense: true }}
        className={"user-select-none"}
        disableRestoreFocus
      >
        <MenuItem onClick={onEditDeck}>{t("編集")}</MenuItem>
        <MenuItem onClick={onToggleLock}>
          {isLocked ? t("配置固定を解除") : t("配置固定")}
        </MenuItem>
        <MenuDivider />
        <MenuItem onClick={() => onDraw(1)}>{t("1枚引く")}</MenuItem>
        <MenuItem
          ref={refDrawPrivateMenuAnchor}
          onClick={onOpenPrivateDrawMenu}
          onPointerEnter={onEnterPrivateDrawMenu}
          onPointerLeave={onCloseDrawMenu}
        >
          {t("複数枚引く")}
          <MenuItemNavIcon>
            <ArrowRightIcon fontSize="small" />
          </MenuItemNavIcon>
        </MenuItem>
        <MenuItem onClick={() => onClosedDraw(1)}>
          {t("裏向きのまま1枚引く")}
        </MenuItem>
        <MenuItem
          ref={refDrawClosedMenuAnchor}
          onClick={onOpenClosedDrawMenu}
          onPointerEnter={onEnterClosedDrawMenu}
          onPointerLeave={onCloseDrawMenu}
        >
          {t("裏向きのまま複数枚引く")}
          <MenuItemNavIcon>
            <ArrowRightIcon fontSize="small" />
          </MenuItemNavIcon>
        </MenuItem>
        <MenuDivider />
        <MenuItem onClick={onReset}>{t("すべて集める")}</MenuItem>
        <MenuItem disabled={!belowDeckId} onClick={onMerge}>
          {t("山札を合わせる")}
        </MenuItem>
        <MenuDivider />
        <MenuItem onClick={onDelete}>{t("削除")}</MenuItem>
      </Menu>
      <Menu
        open={openDrawMenu}
        anchorEl={drawMenuAnchorEl}
        onClose={onCloseDrawMenu}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        MenuListProps={{ dense: true, sx: { pointerEvents: "auto" } }}
        hideBackdrop
        sx={{ pointerEvents: "none" }}
        onPointerEnter={onEnterCurrentMenu}
        onPointerLeave={onCloseDrawMenu}
      >
        {Array.from({ length: 10 }, (_, index) => (
          <MenuItem onClick={() => onDrawMulti(index + 1)}>
            {t("{{cards}} 枚", { cards: index + 1 })}
          </MenuItem>
        ))}
      </Menu>
    </>
  );
};

const MenuDivider = styled(Divider)({
  marginTop: "8px",
  marginBottom: "8px",
});

const MenuItemNavIcon = styled(Typography)`
  display: inline-flex;
  align-self: center;
  justify-content: end;
  flex: 1;
  width: 100%;
  margin-right: -8px;
`;

export default DeckMenu;
