import React, {
  memo,
  useState,
  useCallback,
  useRef,
  useEffect,
  useMemo,
} from "react";
import { useAppSelector } from "stores";
import styled from "styled-components";
import store from "stores/interfaces";
import { Snackbar, IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import MusicNoteIcon from "@mui/icons-material/MusicNoteOutlined";
import theme from "theme";
import toCDNUrl from "modules/toCDNUrl";
import { createHowl } from "modules/sound";

const ContentProps = { style: { background: "#222", color: "#ccc" } };

type EffectProps = {
  effectId: string;
};

const Effect = (props: EffectProps) => {
  const effect = useAppSelector((state) =>
    store.getRoomEffectById(state, props.effectId)
  );
  const muted = useAppSelector((state) => store.getAppState(state, "muted"));
  const enableGoCdn = useAppSelector(
    (state) => state.app.state.config.enabledGoCdnOnAudio
  );
  const [openTime, setOpenTime] = useState(effect.playTime);
  const playedId = useRef<number | null>(null);
  const onClose = useCallback(() => {
    if (playedId.current) {
      setOpenTime(playedId.current);
    }
  }, [setOpenTime, playedId]);
  const sound = useMemo(() => {
    return createHowl({
      src: [toCDNUrl(effect.soundUrl, { enableGoCdn })],
      autoplay: false,
      loop: false,
      volume: 0.2,
      format: ["mp4", "ogg", "wav"],
      onend: onClose,
    });
  }, [effect.soundUrl, enableGoCdn, onClose]);
  useEffect(() => {
    sound.volume(effect.soundVolume);
  }, [sound, effect.soundVolume]);
  useEffect(() => {
    sound.mute(muted);
  }, [sound, muted]);
  useEffect(() => {
    if (playedId.current !== effect.playTime && openTime < effect.playTime) {
      playedId.current = effect.playTime;
      sound.stop();
      sound.play();
    } else {
      sound.stop();
    }
  }, [sound, effect.playTime, openTime]);
  useEffect(() => {
    return () => {
      sound.stop();
    };
  });
  return (
    <>
      {effect.imageUrl ? (
        <Container open={openTime < effect.playTime} onClick={onClose}>
          <FloatWindow onClick={onClose}>
            {effect.imageUrl ? (
              <img src={toCDNUrl(effect.imageUrl)} draggable={false} />
            ) : null}
          </FloatWindow>
        </Container>
      ) : (
        <Snackbar
          anchorOrigin={{ vertical: "top", horizontal: "right" }}
          ContentProps={ContentProps}
          message={
            <SnackBarText>
              <MusicNoteIcon />
              <span>{effect.name}</span>
            </SnackBarText>
          }
          action={[
            <IconButton
              key="close"
              aria-label="close"
              color="inherit"
              onClick={onClose}
              size="large"
            >
              <CloseIcon />
            </IconButton>,
          ]}
          open={openTime < effect.playTime}
        />
      )}
    </>
  );
};

const SnackBarText = styled.div`
  display: flex;
  align-items: center;
  .MuiSvgIcon-root {
    margin-right: 8px;
  }
`;

const Container = styled.div<{ open: boolean }>`
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  overflow: hidden;
  z-index: ${theme.zIndex.modal};
  display: ${({ open }) => (open ? "block" : "none")};
  background: rgba(0, 0, 0, 0.4);
`;

const FloatWindow = styled.div`
  margin: auto;
  position: absolute;
  top: 16%;
  left: 16%;
  right: 16%;
  bottom: 16%;
  max-width: 480px;
  max-height: 480px;
  outline: none;
  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
`;

export default memo(Effect);
