import { useCallback, memo, MouseEvent, useState, useRef } from "react";
import { useAppDispatch, useAppSelector } from "stores";
import store from "stores/interfaces";
import {
  Avatar,
  IconButton,
  List,
  ListItemAvatar,
  ListItemButton,
  ListItemSecondaryAction,
  ListItemText,
} from "@mui/material";
import LibraryMusicIcon from "@mui/icons-material/LibraryMusic";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import styled from "styled-components";
import sendEvent from "modules/sendEvent";
import MediumListOfficialPlaylistItemMenu from "./MediumListOfficialPlaylistItemMenu";
import { useDispatch } from "react-redux";
import { appStateMutate } from "stores/modules/app.state/operations";

const MediumListOfficialPlaylist = () => {
  const dispatch = useDispatch();

  const [menuSoundId, setMenuSoundId] = useState<string | null>(null);
  const [openMenu, setOpenMenu] = useState(false);
  const refAnchor = useRef<Element | null>(null);
  const onOpenMenu = useCallback((soundId: string, el: Element) => {
    refAnchor.current = el;
    setMenuSoundId(soundId);
    setOpenMenu(true);
  }, []);
  const onCloseMenu = useCallback(() => {
    setOpenMenu(false);
    window.setTimeout(() => {
      dispatch(
        appStateMutate((state) => {
          state.previewMediaUrl = null;
        })
      );
    }, 100);
  }, [dispatch]);

  const tab = useAppSelector((state) => {
    return store.getAppState(state, "userMediumListOfficialPlaylistTab");
  });
  const playlists = useAppSelector((state) => {
    return store.getAppState(state, "officialPlaylists");
  });
  const isPro = useAppSelector((state) => {
    return store.getAppState(state, "plan") === "ccfolia-pro";
  });

  const playlist = playlists.find((playlist) => playlist.id === tab);

  if (playlist == null) {
    return null;
  }

  const disabled = !playlist.free && !isPro;

  return (
    <Container>
      <List component="div">
        {playlist.sounds.map((sound) => (
          <div key={sound.id}>
            <MediumListOfficialPlaylistItem
              id={sound.id}
              name={sound.name}
              provider={sound.provider}
              url={sound.url}
              volume={sound.volume}
              repeat={sound.repeat}
              disabled={disabled}
              onOpenMenu={onOpenMenu}
            />
          </div>
        ))}
      </List>
      <MediumListOfficialPlaylistItemMenu
        open={openMenu}
        soundId={menuSoundId}
        anchorEl={refAnchor.current}
        onClose={onCloseMenu}
      />
    </Container>
  );
};

type MediumListOfficialPlaylistItemProps = {
  id: string;
  name: string;
  provider: string;
  url: string;
  volume: number;
  repeat: boolean;
  disabled?: boolean;
  onOpenMenu: (soundId: string, el: Element) => void;
};

const MediumListOfficialPlaylistItem = ({
  id,
  name,
  provider,
  url,
  volume,
  repeat,
  disabled,
  onOpenMenu,
}: MediumListOfficialPlaylistItemProps) => {
  const dispatch = useAppDispatch();
  const dir = useAppSelector((state) =>
    store.getAppState(state, "openRoomMediaDir")
  );
  const officialPlaylistVolume = useAppSelector((state) =>
    store.getAppState(state, "officialPlaylistVolume")
  );

  const onPreview = useCallback(
    (e: MouseEvent) => {
      e.stopPropagation();
      onOpenMenu(id, e.currentTarget);
    },
    [id, onOpenMenu]
  );

  const onPlay = useCallback(() => {
    if (url) {
      sendEvent("clickOfficialSound", {
        event_category: name,
        event_label: id,
      });
      if (dir === "bgm") {
        dispatch(
          store.updateCurrentRoomField({
            mediaUrl: url,
            mediaType: "file",
            mediaName: name,
            mediaVolume: volume * officialPlaylistVolume,
            mediaRepeat: repeat,
          })
        );
      } else if (dir === "sound") {
        dispatch(
          store.updateCurrentRoomField({
            soundUrl: url,
            soundName: name,
            soundVolume: volume * officialPlaylistVolume,
            soundRepeat: repeat,
          })
        );
      } else if (dir === "effect") {
        dispatch(
          store.updateCurrentRoomEffect({
            soundUrl: url,
            soundName: name,
            soundVolume: volume * officialPlaylistVolume,
          })
        );
      }
      // 試聴を停止
      dispatch(
        store.appStateMutate((state) => {
          state.previewMediaUrl = null;
        })
      );
    }
  }, [dispatch, id, url, name, repeat, volume, dir, officialPlaylistVolume]);

  return (
    <>
      <ListItemButton
        disabled={disabled}
        style={{
          paddingRight: "100px",
        }}
        onClick={onPlay}
      >
        <ListItemAvatar>
          <Avatar>
            <Avatar>
              <LibraryMusicIcon />
            </Avatar>
          </Avatar>
        </ListItemAvatar>
        <ListItemTextWrapped primary={name} secondary={provider} />
        <ListItemSecondaryAction>
          <IconButton onClick={onPreview} disabled={disabled}>
            <MoreVertIcon />
          </IconButton>
        </ListItemSecondaryAction>
      </ListItemButton>
    </>
  );
};

const ListItemTextWrapped = styled(ListItemText)`
  word-wrap: break-word;
`;

const Container = styled.div`
  padding: 0;
  flex: 1;
  overflow: auto;
`;

export default memo(MediumListOfficialPlaylist);
