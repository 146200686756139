import { memo, ReactNode, useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import { useAppDispatch, useAppSelector } from "stores";
import store from "stores/interfaces";
import styled from "styled-components";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
  Card,
  CardContent,
  Grid,
  CircularProgress,
} from "@mui/material";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import { ReactComponent as CcfoliaProLogo } from "./CcfoliaProLogo.svg";
import { createBillingPortalSession, createSubscription } from "api";
import theme from "theme";

const PlanSelect = () => {
  const dispatch = useAppDispatch();
  const open = useAppSelector((state) =>
    store.getAppState(state, "openPlanSelect")
  );
  const fromUserSettings = useAppSelector(
    (state) =>
      store.getAppState(state, "destOnClosePlanSelect") === "userSettings"
  );

  const plan = useAppSelector((state) => store.getAppState(state, "plan"));
  const isPro = plan === "ccfolia-pro";

  const onClose = useCallback(() => {
    dispatch(
      store.appStateMutate((state) => {
        if (state.destOnClosePlanSelect === "userSettings") {
          state.openUserSettings = true;
        }
        state.openPlanSelect = false;
      })
    );
  }, [dispatch]);

  const onClickUpgrade = async () => {
    const { url } = await createSubscription();
    window.location.href = url;
  };

  const onClickBillingPortal = async () => {
    const { url } = await createBillingPortalSession();
    window.location.href = url;
  };

  const [t] = useTranslation();
  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="xs">
      <DialogTitle>{t("プランの変更")}</DialogTitle>
      <DialogContent>
        <Grid container spacing={3} alignItems="stretch">
          <Grid item sm={6} xs={12}>
            <Card
              variant="outlined"
              style={{
                borderColor: !isPro ? theme.palette.secondary.main : undefined,
                height: "100%",
                background: theme.palette.grey[900],
              }}
            >
              <CardContent
                style={{
                  background: theme.palette.background.default,
                }}
              >
                <Typography align="center" style={{ marginBottom: "8px" }}>
                  {t("CCFOLIA BASIC")}
                </Typography>
                <Typography
                  variant="caption"
                  align="center"
                  display="block"
                  color="textSecondary"
                  sx={{ whiteSpace: "pre-wrap" }}
                >
                  {t("これまで通りの\n基本プラン")}
                </Typography>
              </CardContent>
              <PriceArea>
                <Typography variant="h4" align="center" paragraph>
                  {t("無料")}
                </Typography>
                {!isPro && (
                  <Typography
                    variant="body2"
                    color="textSecondary"
                    align="center"
                  >
                    {t("現在のプラン")}
                  </Typography>
                )}
              </PriceArea>
            </Card>
          </Grid>
          <Grid item sm={6} xs={12}>
            <Card
              variant="outlined"
              style={{
                borderColor: isPro ? theme.palette.secondary.main : undefined,
                height: "100%",
                background: theme.palette.grey[900],
              }}
            >
              <CardContent
                style={{
                  background: theme.palette.background.default,
                }}
              >
                <LogoWrapper>
                  <CcfoliaProLogo role="img" aria-label="CCFOLIA PRO" />
                </LogoWrapper>
                <Typography
                  variant="caption"
                  align="center"
                  display="block"
                  color="textSecondary"
                  sx={{ whiteSpace: "pre-wrap" }}
                >
                  {t("ココフォリアのプロに\nなれる新プラン")}
                </Typography>
              </CardContent>
              <PriceArea>
                <Typography variant="h4" align="center" paragraph>
                  <Typography variant="caption">{t("月々")}</Typography> 500{" "}
                  <Typography variant="caption">{t("円")}</Typography>
                </Typography>
                {isPro ? (
                  <ButtonLoading
                    variant="outlined"
                    onClick={onClickBillingPortal}
                  >
                    {t("解約")}
                  </ButtonLoading>
                ) : (
                  <ButtonLoading variant="contained" onClick={onClickUpgrade}>
                    {t("アップグレード")}
                  </ButtonLoading>
                )}
              </PriceArea>
            </Card>
          </Grid>
        </Grid>
        <ActionArea>
          <Button
            variant="text"
            href="/plans"
            target="_blank"
            fullWidth
            endIcon={<OpenInNewIcon />}
          >
            {t("プランについての詳細はこちら")}
          </Button>
        </ActionArea>
      </DialogContent>
      <DialogActions>
        <Button color="primary" fullWidth onClick={onClose}>
          {fromUserSettings ? t("アカウント設定に戻る") : t("閉じる")}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

type ButtonLoadingProps = {
  variant: "outlined" | "contained";
  onClick: () => Promise<void>;
  children: ReactNode;
};

const ButtonLoading = ({ variant, onClick, children }: ButtonLoadingProps) => {
  const [loading, setLoading] = useState(false);

  if (loading) {
    return (
      <Button variant="contained" fullWidth disabled>
        <CircularProgress color="primary" size={24.5} />
      </Button>
    );
  }

  const handleClick = () => {
    setLoading(true);
    onClick().finally(() => setLoading(false));
  };

  return (
    <Button variant={variant} color="secondary" fullWidth onClick={handleClick}>
      {children}
    </Button>
  );
};

const ActionArea = styled.div`
  border-top: 1px solid rgba(255, 255, 255, 0.1);
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
  margin-top: 24px;
  padding: 8px 0;
  text-align: center;
`;

const PriceArea = styled(CardContent)`
  background: ${theme.palette.grey[900]};
  padding: 24px 16px;
`;

const LogoWrapper = styled.div`
  display: flex;
  justify-content: center;

  padding: 4px 0;
  margin-bottom: 8px;
`;

export default memo(PlanSelect);
