import React, { memo, useCallback } from "react";
import { useAppDispatch, useAppSelector } from "stores";
import styled from "styled-components";
import store from "stores/interfaces";
import { List } from "@mui/material";

import PanelListItem from "../PanelListItem";
import { DropProps } from "stores/modules/firestoreModuleUtils/ordaring";
import SortableList from "containers/SortableList";
import SortableItem from "containers/SortableItem";

const PanelList = () => {
  const d = useAppDispatch();
  const panelIds = useAppSelector(store.getSortedRoomItemIds);
  const roomId = useAppSelector((state) => store.getAppState(state, "roomId"));
  const onReorder = useCallback(
    (order: DropProps) => {
      if (roomId) {
        d(store.reorderItems(roomId, order));
      }
    },
    [roomId, d]
  );

  return (
    <List dense>
      <Content>
        <SortableList
          ids={panelIds}
          noPadding={true}
          sortableItem={(id) => (
            <DraggableItem>
              <SortableItem id={id}>
                <PanelListItem panelId={id} />
              </SortableItem>
            </DraggableItem>
          )}
          overlay={(id) => (
            <OverlayItem>
              <PanelListItem panelId={id} />
            </OverlayItem>
          )}
          onReorder={onReorder}
        />
      </Content>
    </List>
  );
};
const Content = styled.div`
  padding: 2px;
`;

const DraggableItem = styled.div`
  padding: 2px;
`;

const OverlayItem = styled.div`
  color: white;
  list-style: none;
  background: rgb(0 0 0 / 0.5);
`;

export default memo(PanelList);
