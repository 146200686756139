import React, { memo, useCallback, useEffect, useMemo, useState } from "react";
import { useAppDispatch, useAppSelector } from "stores";
import styled from "styled-components";
import store from "stores/interfaces";
import {
  Dialog,
  AppBar,
  DialogContent,
  Typography,
  Link,
  Divider,
  Button,
  IconButton,
  Avatar,
  Toolbar,
  TextField,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import ClearIcon from "@mui/icons-material/Clear";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import { useForm } from "react-hook-form";
import {
  signInWithEmail,
  signUpWithEmail,
} from "stores/modules/app.user/operations";
import LoadingSubmitButton from "./LoadingSubmitButton";
import GoogleLoginButton from "containers/LoginButton/GoogleLoginButton";
import XLoginButton from "containers/LoginButton/XLoginButton";
import theme from "theme";
import { useLocation } from "react-router-dom";

interface LoginProps {
  isHome?: boolean;
  open: boolean;
  onlyLogin?: boolean;
  onClose?: () => void;
}

const Login: React.FC<LoginProps> = ({
  isHome,
  open,
  onClose,
  onlyLogin = false,
}) => {
  const dispatch = useAppDispatch();
  const location = useLocation();

  const isLogin = useAppSelector((state) =>
    store.getAppState(state, "registered")
  );
  const setIsLogin = React.useCallback(
    (isLogin) => {
      dispatch(
        store.appStateMutate((state) => {
          state.registered = isLogin;
        })
      );
    },
    [dispatch]
  );
  const onBack = React.useCallback(() => {
    window.history.back();
  }, []);

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    if (params.get("m") === "signup") {
      dispatch(
        store.appStateMutate((state) => {
          state.registered = false;
        })
      );
      window.history.replaceState(null, "", location.pathname);
    }
  }, [dispatch, location]);

  const [t] = useTranslation();
  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="xs" fullScreen>
      <AppBar position="static" color="transparent" elevation={0}>
        <Toolbar>
          <Typography variant="h6" style={{ flex: 1 }}>
            Account
          </Typography>
          <IconButton edge="end" onClick={onClose || onBack} size="large">
            <ClearIcon />
          </IconButton>
        </Toolbar>
      </AppBar>
      {isLogin || onlyLogin ? (
        <DialogContent>
          <Avatar
            src="/ccfolia.png"
            style={{ width: 128, height: 128, margin: "16px auto" }}
          />
          <Typography variant="h4" align="center" gutterBottom>
            {t("Signin")}
          </Typography>
          <Typography component="p" variant="body2" align="center" gutterBottom>
            {t(
              "ログインに失敗する場合は、ブラウザやセキュリティソフトの設定でCookieや通信がブロックされていないことをご確認ください。"
            )}
          </Typography>
          <Wrap>
            <SigninFormWithPassowrd />
            <Typography variant="subtitle2" marginBottom="8px">
              {t("SNS認証でログインする")}
            </Typography>
            <ButtonArea>
              <XLoginButton
                onClick={() => dispatch(store.signInWithProvider("twitter"))}
              >
                {t("Signin with X")}
              </XLoginButton>
              <GoogleLoginButton
                onClick={() => dispatch(store.signInWithProvider("google"))}
              >
                {t("Signin with Google")}
              </GoogleLoginButton>
            </ButtonArea>
          </Wrap>
          {!onlyLogin && (
            <>
              <Divider />
              <SwitchArea>
                <Typography
                  component="p"
                  align="center"
                  variant="subtitle2"
                  gutterBottom
                  marginBottom="16px"
                >
                  {t("アカウントをお持ちでない方")}
                </Typography>
                <Button
                  variant="contained"
                  size="large"
                  onClick={() => setIsLogin(false)}
                  fullWidth
                >
                  {t("新規アカウント作成")}
                </Button>
              </SwitchArea>
            </>
          )}
        </DialogContent>
      ) : (
        <DialogContent>
          <Avatar style={{ width: 128, height: 128, margin: "16px auto" }}>
            <AccountCircleIcon style={{ fontSize: 128 }} />
          </Avatar>
          <Typography variant="h4" align="center" gutterBottom>
            {isHome
              ? t("ルーム作成にはアカウント作成が必要です")
              : t("新規アカウント作成")}
          </Typography>
          <Typography component="p" variant="body2" align="center" gutterBottom>
            {t("連携されたアカウントはログインのためだけに利用されます。")}
            <br />
            {t(
              "ココフォリアがユーザーの同意なくアカウント上のコンテンツの閲覧・作成・変更を行うことはありません。"
            )}
          </Typography>
          <LinkArea>
            <Typography component="p" align="center" variant="body2">
              <Link
                href="/termsOfService.html"
                target="_blank"
                underline="hover"
              >
                {t("利用規約")}
              </Link>
            </Typography>
            <Typography component="p" align="center" variant="body2">
              <Link
                href="/privacyPolicy.html"
                target="_blank"
                underline="hover"
              >
                {t("プライバシーポリシー")}
              </Link>
            </Typography>
          </LinkArea>
          <Wrap>
            <SignupFormWithPassowrd />
            <Typography variant="subtitle2" marginBottom="8px">
              {t("SNS認証でアカウントを新規作成する")}
            </Typography>
            <ButtonArea>
              <XLoginButton
                onClick={() => dispatch(store.signUpWithProvider("twitter"))}
              >
                {t("Link with X")}
              </XLoginButton>
              <GoogleLoginButton
                onClick={() => dispatch(store.signUpWithProvider("google"))}
              >
                {t("Link with Google")}
              </GoogleLoginButton>
            </ButtonArea>
          </Wrap>
          <Divider />
          <SwitchArea>
            <Typography
              component="p"
              align="center"
              variant="subtitle2"
              gutterBottom
            >
              {t("既にアカウントをお持ちの方")}
            </Typography>
            <Button
              variant="contained"
              size="large"
              onClick={() => setIsLogin(true)}
              fullWidth
            >
              {t("ログイン")}
            </Button>
          </SwitchArea>
        </DialogContent>
      )}
    </Dialog>
  );
};

const useFirebaseAuthError = () => {
  const [t] = useTranslation();
  const [errorCode, setErrorCode] = useState<string>("");
  const onError = useCallback(
    (error: any) => {
      if (typeof error?.code === "string") {
        setErrorCode(error.code);
      }
    },
    [setErrorCode]
  );
  const errorMessage: string = useMemo(() => {
    if (!errorCode) {
      return "";
    }

    switch (errorCode) {
      case "auth/email-already-in-use":
        return t("このメールアドレスはすでに使われています。");
      case "auth/invalid-email":
        return t("メールアドレスの形式に誤りがあります。");
      case "auth/wrong-password":
      case "auth/user-not-found":
      case "auth/invalid-login-credentials":
        return t(
          "ログインに失敗しました。メールアドレスかパスワードに誤りがあります。"
        );
      default:
        return t("不明なエラーが発生しました。") + ` : ${errorCode}`;
    }
  }, [errorCode, t]);

  return { errorMessage, onError };
};

type FormParams = {
  email: string;
  password: string;
};

const SignupFormWithPassowrd = () => {
  const [t] = useTranslation();
  const dispatch = useAppDispatch();
  const {
    register,
    handleSubmit,
    formState: { isValid },
  } = useForm<FormParams>({
    defaultValues: { email: "", password: "" },
    reValidateMode: "onChange",
  });
  const [loading, setLoading] = useState(false);
  const { errorMessage, onError } = useFirebaseAuthError();

  const onSubmit = useCallback(
    ({ email, password }: FormParams) => {
      setLoading(true);
      dispatch(signUpWithEmail(email, password))
        .then(() => {
          window.location.reload();
        })
        .catch(onError)
        .finally(() => {
          setLoading(false);
        });
    },
    [dispatch, setLoading, onError]
  );

  return (
    <PasswordArea>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Typography variant="subtitle2" marginBottom="8px">
          {t("メールアドレスでアカウントを作成する")}
        </Typography>
        <TextField
          variant="standard"
          type="email"
          label={t("メールアドレス")}
          fullWidth
          style={{ marginBottom: "16px" }}
          {...register("email", { required: true })}
        />
        <TextField
          variant="standard"
          type="password"
          label={t("パスワード")}
          helperText={t("6文字以上")}
          fullWidth
          style={{ marginBottom: "16px" }}
          {...register("password", { required: true, minLength: 6 })}
        />
        {errorMessage && (
          <Typography color="error" variant="body2" paragraph>
            {errorMessage}
          </Typography>
        )}
        <LoadingSubmitButton
          type="submit"
          variant="outlined"
          color="white"
          size="large"
          fullWidth
          disabled={!isValid}
          loading={loading}
        >
          {t("アカウントを作成")}
        </LoadingSubmitButton>
      </form>
    </PasswordArea>
  );
};

const SigninFormWithPassowrd = () => {
  const [t] = useTranslation();
  const dispatch = useAppDispatch();
  const {
    register,
    handleSubmit,
    formState: { isValid },
  } = useForm<FormParams>({
    defaultValues: { email: "", password: "" },
    reValidateMode: "onChange",
  });
  const [loading, setLoading] = useState(false);
  const { errorMessage, onError } = useFirebaseAuthError();

  const onSubmit = useCallback(
    ({ email, password }: FormParams) => {
      setLoading(true);
      dispatch(signInWithEmail(email, password))
        .then(() => {
          window.location.reload();
        })
        .catch(onError)
        .finally(() => {
          setLoading(false);
        });
    },
    [dispatch, setLoading, onError]
  );

  return (
    <PasswordArea>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Typography variant="subtitle2" marginBottom="8px">
          {t("メールアドレスでログインする")}
        </Typography>
        <TextField
          variant="standard"
          type="email"
          label={t("メールアドレス")}
          fullWidth
          style={{ marginBottom: "16px" }}
          {...register("email", { required: true })}
        />
        <TextField
          variant="standard"
          type="password"
          label={t("パスワード")}
          fullWidth
          style={{ marginBottom: "16px" }}
          {...register("password", { required: true, minLength: 6 })}
        />
        {errorMessage && (
          <Typography color="error" variant="body2" paragraph>
            {errorMessage}
          </Typography>
        )}
        <LoadingSubmitButton
          type="submit"
          variant="outlined"
          color="white"
          size="large"
          fullWidth
          disabled={!isValid}
          loading={loading}
        >
          {t("ログイン")}
        </LoadingSubmitButton>
        <Link
          href="/reset-password"
          variant="body2"
          align="center"
          display="block"
          target="_blank"
          style={{ marginTop: "8px" }}
        >
          {t("パスワードを忘れた場合")}
        </Link>
      </form>
    </PasswordArea>
  );
};

const LinkArea = styled.div`
  padding: 8px 0;
  .MuiTypography-root + .MuiTypography-root {
    margin-top: 4px;
  }
`;

const Wrap = styled.div`
  margin: 0 auto;
  max-width: 480px;
`;

const ButtonArea = styled.div`
  margin-top: 16px;
  margin-bottom: 40px;
  display: flex;

  & > button + button {
    margin-left: 8px;
  }

  ${theme.breakpoints.down("sm")} {
    flex-direction: column;

    & > button {
      margin-bottom: 8px;
    }

    & > button + button {
      margin-left: 0;
    }
  }
`;

const PasswordArea = styled.div`
  margin: 40px auto 32px;
  box-sizing: border-box;
`;

const SwitchArea = styled.div`
  margin: 40px auto;
  max-width: 480px;
`;

export default memo(Login);
