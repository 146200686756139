import { produce } from "immer";
import { saveAs } from "file-saver";
import JSZip from "jszip";
import mime from "mime/lite";
import TypedArrays from "crypto-js/lib-typedarrays";
import sha256 from "crypto-js/sha256";
import { reduceDeep } from "deepdash/standalone";

import { uploadFile, getFileMetadata } from "api";
import { UpdateRoom } from "stores/modules/entities.rooms";
import { UpdateItem } from "stores/modules/entities.room.items";
import { UpdateCharacter } from "stores/modules/entities.room.characters";
import { UpdateScene } from "stores/modules/entities.room.scenes";
import { UpdateDeck } from "stores/modules/entities.room.decks";
import toCDNUrl from "./toCDNUrl";
import { UpdateEffect } from "stores/modules/entities.room.effects";
import { UpdateNote } from "stores/modules/entities.room.notes";
import {
  UpdateSavedata,
  UpdateSnapshot,
} from "stores/modules/entities.room.savedatas";
import isValidUrl from "./isValidUrl";
import i18next from "i18next";

type FileEntry = {
  name: string;
  data: Blob | string;
  type: string;
};

type FileEntries = {
  [id: string]: FileEntry;
};

export type Entities = {
  room: UpdateRoom;
  items: {
    [id: string]: UpdateItem;
  };
  decks: {
    [id: string]: UpdateDeck;
  };
  notes: {
    [id: string]: UpdateNote;
  };
  characters: {
    [id: string]: UpdateCharacter;
  };
  scenes: {
    [id: string]: UpdateScene;
  };
  effects: {
    [id: string]: UpdateEffect;
  };
  savedatas: {
    [id: string]: UpdateSavedata;
  };
  snapshots: {
    [id: string]: UpdateSnapshot;
  };
};

export type ZipPackage = {
  meta: {
    version: string;
    // owner: string;
  };
  entities: Entities;
  resources: {
    [id: string]: {
      // size: number;
      type: string;
    };
  };
};

const SANITIZE_KEYS = [
  "items",
  "decks",
  "notes",
  "scenes",
  "characters",
  "effects",
  "savedatas",
  "snapshots",
] as const;

const IGNORE_KEYS_ZIP_EXPORT = [
  "_id",
  "owner",
  "ownerName",
  "ownerColor",
  "defaultRole",
  // room
  "features",
  "timer",
  "diceBotName",
  "diceBotSystem",
  "video",
  "publishedRoomPackageId",
  "parentRoomPackageId",
  "parentProductId",
  "appliedExtentionProductIds",
  // media
  "mediaName",
  "mediaUrl",
  "mediaType",
  "mediaRepeat",
  "mediaVolume",
  "soundName",
  "soundUrl",
  "soundRepeat",
  "soundVolume",
  // timestamps
  "createdAt",
  "updatedAt",
];

export const FILE_URL_KEYS = [
  "backgroundUrl",
  "foregroundUrl",
  "coverImageUrl",
  "imageUrl",
  "iconUrl",
  "thumbnail",
];

export const exportData = async (entities: Entities) => {
  const pack = filterForZip(entities);
  return toFiles(pack).then(zip).then(encrypt).then(save);
};

export const importData = async (source: File) => {
  return await decrypt(source).then(unzip).then(parse);
};

export const filter =
  (ignorKeys: string[]) =>
  (entities: Entities): ZipPackage => {
    const filtered = produce(entities, (draft) => {
      for (let ignoreKey of ignorKeys) {
        delete draft.room[ignoreKey];
      }
      delete draft.room.name;
      for (let key of SANITIZE_KEYS) {
        const items = draft[key];
        for (let itemId in items) {
          const item = items[itemId];
          for (let ignoreKey of ignorKeys) {
            delete item[ignoreKey];
          }
        }
      }
      for (const snapshotId in draft.snapshots) {
        const snapshot = draft.snapshots[snapshotId];
        if (snapshot.room) {
          for (const ignoreKey of ignorKeys) {
            delete snapshot.room[ignoreKey];
          }
        }
        for (const key of SANITIZE_KEYS) {
          const items = snapshot[key];
          if (items) {
            for (let itemId in items) {
              const item = items[itemId];
              for (const ignoreKey of ignorKeys) {
                delete item[ignoreKey];
              }
            }
          }
        }
      }
    });

    return {
      meta: {
        version: "1.1.0",
      },
      entities: filtered,
      resources: {},
    };
  };

const filterForZip = filter(IGNORE_KEYS_ZIP_EXPORT);

const toFiles = async (pack: ZipPackage): Promise<FileEntries> => {
  const imageUrls = reduceDeep(
    pack,
    (reduced, value, key) => {
      if (FILE_URL_KEYS.includes(key) && isValidUrl(value)) {
        if (!reduced.includes(value)) reduced.push(value);
      }
      return reduced;
    },
    []
  );
  const files = await getFilesFromUrls(imageUrls);
  const result = await produce(pack, (draft) => {
    draft.resources = {};
    reduceDeep(
      draft,
      (reduced, value, key, parent) => {
        if (files[value] && FILE_URL_KEYS.includes(key)) {
          const file = files[value];
          parent[key] = file.name;
          reduced.resources[file.name] = {
            type: file.type,
          };
        }
        return reduced;
      },
      draft
    );
  });
  const json = JSON.stringify(result);
  const hash = sha256(json).toString();
  const token = "0." + hash;
  return {
    ...files,
    "__data.json": {
      name: "__data.json",
      data: JSON.stringify(result),
      type: "application/json",
    },
    ".token": {
      name: ".token",
      data: token,
      type: "text/plain",
    },
  };
};

const getFilesFromUrls = async (urls: string[]): Promise<FileEntries> => {
  const files = await Promise.all(
    urls.map(async (url) => {
      const file = await getFileFromUrl(url);
      return [url, file];
    })
  );
  return files.reduce((reduced, value) => {
    reduced[String(value[0])] = value[1];
    return reduced;
  }, {});
};

const getFileFromUrl = async (url: string): Promise<FileEntry> => {
  const res = await fetch(toCDNUrl(url));

  const arrayBuffer = await res.clone().arrayBuffer();
  const blob = await res.clone().blob();

  const hash = sha256(
    TypedArrays.create(arrayBuffer as unknown as number[])
  ).toString(); // TODO: fix type
  const ext = mime.getExtension(blob.type);

  return {
    name: `${hash}.${ext}`,
    data: blob,
    type: blob.type,
  };
};

const zip = async (files: FileEntries): Promise<Blob> => {
  const zip = new JSZip();
  for (let k of Object.keys(files)) {
    zip.file(files[k].name, files[k].data);
  }
  const blob = await zip.generateAsync({
    type: "blob",
    compression: "DEFLATE",
    compressionOptions: {
      level: 6,
    },
  });
  return blob;
};

const unzip = async (source: Blob): Promise<JSZip> => {
  const zip = new JSZip();
  await zip.loadAsync(source);
  return zip;
};

const parse = async (zip: JSZip): Promise<Entities> => {
  const tokenFile = zip.files[".token"];
  if (!tokenFile) throw new Error("token is not found");
  const jsonFile = zip.files["__data.json"];
  if (!tokenFile) throw new Error("data is not found");
  const token = await tokenFile.async("text");
  const json = await jsonFile.async("text");
  const hash = sha256(json).toString();
  const [tv, th] = token.split(".");
  if (tv !== "0") throw new Error("invalid token version");
  if (
    th !== hash &&
    !window.confirm(
      i18next.t(
        "外部ツールで作成および編集されたデータです。信頼できるデータのみ取り込むようにしてください。"
      )
    )
  ) {
    throw new Error("verification is failed");
  }
  const data: ZipPackage = JSON.parse(json); // todo: format
  const urls = await Promise.all(
    Object.keys(data.resources).map(async (key, index) => {
      const resource = data.resources[key];
      const file = zip.files[key];
      const _blob = await file.async("blob");
      const blob = new Blob([_blob], { type: resource.type });
      const arrayBuffer = await blob.arrayBuffer();
      const hash = sha256(
        TypedArrays.create(arrayBuffer as unknown as number[])
      ).toString(); //TODO: fix type
      const ext = mime.getExtension(resource.type);
      if (`${hash}.${ext}` !== key) return [key, null];
      const filePath = `shared/${key}`;
      let metadata = await getFileMetadata(filePath).catch(() => null);
      if (!metadata || metadata.size === "0") {
        metadata = await uploadFile({
          filePath,
          file: blob,
        });
      }
      if (!metadata) {
        return [key, null];
      }
      const downloadURL = [process.env.REACT_APP_CDN_URL, metadata.name].join(
        "/"
      );
      return [key, downloadURL];
    })
  );
  const urlsMap = urls.reduce((reduced, value) => {
    if (value[0] && value[1]) {
      reduced[value[0]] = value[1];
    }
    return reduced;
  }, {});
  const result = reduceDeep(
    data.entities,
    (reduced, value, key, parent) => {
      if (urlsMap[value] && FILE_URL_KEYS.includes(key)) {
        const url = urlsMap[value];
        parent[key] = url;
      }
      return reduced;
    },
    data.entities
  );
  return result;
};

const encrypt = async (zip: Blob): Promise<Blob> => {
  return zip; // todo
};
const decrypt = async (source: File): Promise<Blob> => {
  return source; // todo
};

const save = (blob: Blob): Blob => {
  saveAs(blob, "room.zip");
  return blob;
};
