import theme from "theme";
import { ReactComponent as CcfoliaProSvg } from "./CcfoliaPro.svg";
import { SVGProps } from "react";

const CcfoliaProIcon = ({
  height = "14px",
  fill = theme.palette.text.primary,
  ...props
}: SVGProps<SVGSVGElement>) => {
  return <CcfoliaProSvg height={height} fill={fill} {...props} />;
};

export default CcfoliaProIcon;
