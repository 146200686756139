import { createSelector } from "reselect";
import { sortBy } from "lodash-es";
import { Character, CharacterRecord } from "./records";
import { originalName } from "./operations";
import { DefaultRootState } from "stores";
import { getUid } from "../app.user/selectors";

const getCharacters = (state: DefaultRootState) => {
  return state.entities.roomCharacters.entities;
};

const emptyCharacter = CharacterRecord({
  name: "",
  active: true,
  initiative: 0,
  hideStatus: false,
});
export const getCharacterById = (
  state: DefaultRootState,
  id: string | null
) => {
  if (id === null) return emptyCharacter;
  return state.entities.roomCharacters.entities[id] || emptyCharacter;
};

const emptyList: string[] = [];
export const getRoomCharacterIds = (state: DefaultRootState) => {
  return state.entities.roomCharacters.ids || emptyList;
};

export const getUserCharacterIds = (
  state: DefaultRootState,
  { uid }: { uid: string | null }
) => {
  if (!uid) return emptyList;
  return state.entities.roomCharacters.idsGroupBy[uid] || emptyList;
};

export const getMyRoomCharacterIds = (state: DefaultRootState) => {
  const uid = getUid(state);
  if (!uid) return emptyList;
  return state.entities.roomCharacters.idsGroupBy[uid] || emptyList;
};

const getNameProps = (_: any, props: { name: string }): string => props.name;

const pickUpNum = (name: string): number => {
  const match = name.match(/\((\d+)\)$/);
  return match ? parseInt(match[1]) : 0;
};
export const getCharacterCountByName = createSelector(
  [getMyRoomCharacterIds, getCharacters, getNameProps],
  (characterIds, characters, name) => {
    return characterIds.reduce((num, characterId) => {
      const targetName = characters[characterId]?.name || "";
      if (originalName(targetName) === originalName(name)) {
        return Math.max(pickUpNum(targetName) + 1, num);
      } else {
        return num;
      }
    }, 0);
  }
);

export const getSortedMyRoomCharacterIds = createSelector(
  [getMyRoomCharacterIds, getCharacters],
  (characterIds, characters) => {
    return sortBy(characterIds, (id) => characters[id].name);
  }
);

export const getRoomActiveCharacterIds = createSelector(
  [getRoomCharacterIds, getCharacters],
  (characterIds, characters) => {
    return characterIds
      .filter((characterId) => {
        const character = characters[characterId];
        return character.active;
      })
      .sort((a, b) => {
        const ac = characters[a];
        const bc = characters[b];
        return (
          Number(bc.initiative) - Number(ac.initiative) || // higher is first
          (ac._id || "").localeCompare(bc._id || "") // lower is first
        );
      });
  }
);

export const getMyRoomActiveCharacterIds = createSelector(
  [getMyRoomCharacterIds, getCharacters],
  (characterIds, characters) => {
    return characterIds.filter((characterId) => {
      const character = characters[characterId];
      return character.active;
    });
  }
);

export const getMyRoomIncativeCharacterIds = createSelector(
  [getMyRoomCharacterIds, getCharacters],
  (characterIds, characters) => {
    const filteredCharacterIds = characterIds.filter((characterId) => {
      const character = characters[characterId];
      return !character.active;
    });
    return sortBy(filteredCharacterIds, (id) => characters[id].name);
  }
);

export const getUserCharacters = createSelector(
  [getUserCharacterIds, getCharacters],
  (characterIds, characters) => {
    return characterIds.map((id) => {
      return characters[id];
    });
  }
);

export const getMyRoomCharacters = createSelector(
  [getMyRoomCharacterIds, getCharacters],
  (characterIds, characters) => {
    return characterIds.reduce((current, id) => {
      current[id] = characters[id];
      return current;
    }, {} as Record<string, Character>);
  }
);

export const getUserCharacterIdByName = createSelector(
  [getUserCharacterIds, getCharacters, getNameProps],
  (characterIds, characters, name) => {
    if (!name) return;
    return characterIds.find((characterId) => {
      return characters[characterId].name === name;
    });
  }
);

export const getUserCharacterByName = createSelector(
  [getUserCharacterIds, getCharacters, getNameProps],
  (characterIds, characters, name) => {
    if (!name) return;
    const characterId = characterIds.find((characterId) => {
      return characters[characterId].name === name;
    });
    if (characterId === undefined) return;
    return characters[characterId];
  }
);

export const getRoomShowStatusCharacterIds = createSelector(
  [getRoomActiveCharacterIds, getCharacters],
  (characterIds, characters) => {
    return characterIds.filter((characterId) => {
      const character = characters[characterId];
      return !character.hideStatus;
    });
  }
);
