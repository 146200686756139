import React, { useMemo } from "react";
import { useAppSelector } from "stores";
import styled from "styled-components";
import store from "stores/interfaces";
import { Modal, LinearProgress, Typography } from "@mui/material";

const Progress: React.FC<{}> = () => {
  const { isVisible, total, value } = useAppSelector((state) =>
    store.getAppState(state, "progress")
  );

  const progress = useMemo(() => {
    if (total === 0) {
      return 0;
    }
    return (value / total) * 100;
  }, [value, total]);

  return (
    <Modal open={isVisible}>
      <Center>
        <LinearProgress value={progress} variant={"determinate"} />
        <Typography
          padding="16px 0"
          fontSize="20px"
          align="center"
          sx={{ color: "text.secondary" }}
        >{`${value} / ${total}`}</Typography>
      </Center>
    </Modal>
  );
};

const Center = styled.div`
  position: absolute;
  outline: none;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 30%;
`;

export default React.memo(Progress);
