import React, {
  useState,
  useRef,
  useCallback,
  MouseEvent,
  RefObject,
  PropsWithChildren,
  TouchEvent,
} from "react";
import { shallowEqual } from "react-redux";
import { useAppDispatch, useAppSelector } from "stores";
import { Menu, MenuItem } from "@mui/material";
import store from "stores/interfaces";
import styled from "styled-components";
import { addRoomDice } from "stores/modules/entities.room.dices/operations";
import { useTranslation } from "react-i18next";
import useLongTap from "hooks/longTap";
import { getIsRoleAudience } from "stores/modules/entities.room.members/selectors";

type ContextMenuProps = PropsWithChildren<{
  centerRef: RefObject<HTMLDivElement>;
}>;

const ContextMenu = ({ centerRef, children }: ContextMenuProps) => {
  const screenRef = useRef<HTMLDivElement>(null);
  const menuRef = useRef<HTMLDivElement>(null);
  const [t] = useTranslation();
  const isRoleAudience = useAppSelector(getIsRoleAudience);

  const d = useAppDispatch();
  const [open, setOpen] = useState(false);
  const [mouseEvent, setMouseEvent] = useState({ pageX: 1, pageY: 1 });

  const [position, cellSize, scale] = useAppSelector((state) => {
    return [
      store.getAppState(state, "contextMouseCellPosition"),
      store.getAppState(state, "roomScreenCellSize"),
      store.getAppState(state, "roomScreenScale"),
    ] as const;
  }, shallowEqual);

  const onClose = useCallback(() => {
    setOpen(false);
  }, [setOpen]);

  const onSelectContextMenu = ({
    event,
    centerX,
    centerY,
  }: {
    event: MouseEvent | TouchEvent;
    centerX: number;
    centerY: number;
  }) => {
    const { x, y } = getEventPos(event);

    setOpen(true);
    setMouseEvent({
      pageX: x || 0,
      pageY: y || 0,
    });
    d(
      store.appStateMutate((state) => {
        state.contextMouseCellPosition = relativeCell({
          x: x || 0,
          y: y || 0,
          scale,
          cellSize,
          centerX,
          centerY,
        });
      })
    );
    event.preventDefault();
  };

  const onSelectItemImage = useCallback(() => {
    d(
      store.appStateMutate((state) => {
        state.openRoomImageSelect = true;
        state.openRoomImageSelectDir = "item";
        state.openRoomImageSelectTarget = "item/new";
        state.fromContextMenu = true;
      })
    );
    onClose();
  }, [onClose, d]);

  const onSelectMarkerImage = useCallback(() => {
    d(
      store.appStateMutate((state) => {
        state.openRoomImageSelect = true;
        state.openRoomImageSelectDir = "marker";
        state.openRoomImageSelectTarget = "marker/new";
        state.fromContextMenu = true;
      })
    );
    onClose();
  }, [onClose, d]);

  const onAddDice = useCallback(() => {
    d(addRoomDice({ x: position.x - 1, y: position.y - 1 }));
    onClose();
  }, [position, onClose, d]);

  const onEditField = useCallback(() => {
    d(
      store.appStateMutate((state) => {
        state.openRoomFieldEdit = true;
      })
    );
    onClose();
  }, [onClose, d]);

  const onAddDeck = useCallback(() => {
    d(store.addRoomDeck({ x: position.x - 1, y: position.y - 1 }));
    onClose();
  }, [onClose, position, d]);

  const onPasteCharacter = useCallback(async () => {
    const textFromClipboard = await navigator.clipboard.readText();
    d(store.importRoomCharacterFromClipboardText(textFromClipboard));
    onClose();
  }, [onClose, d]);

  const onContextMenu = (event: MouseEvent | TouchEvent) => {
    event.preventDefault();
    if (isRoleAudience) {
      return;
    }

    const center = centerRef.current?.getBoundingClientRect() || {
      left: 0,
      top: 0,
    };
    onSelectContextMenu({
      event,
      centerX: center?.left ?? 0,
      centerY: center?.top ?? 0,
    });
  };
  const longTapProps = useLongTap(onContextMenu);

  return (
    <>
      <MenuScreen
        ref={screenRef}
        onContextMenu={onContextMenu}
        {...longTapProps}
      >
        {children}
      </MenuScreen>
      <MenuBase
        ref={menuRef}
        style={{ top: mouseEvent.pageY, left: mouseEvent.pageX }}
      />
      <Menu
        anchorEl={menuRef.current}
        open={open}
        onClose={onClose}
        MenuListProps={{ dense: true }}
      >
        <MenuItem onClick={onEditField}>{t("前景・背景を変更")}</MenuItem>
        <MenuItem onClick={onSelectItemImage}>{t("スクリーンパネル")}</MenuItem>
        <MenuItem onClick={onSelectMarkerImage}>{t("マーカーパネル")}</MenuItem>
        <MenuItem onClick={onAddDice}>{t("ダイスシンボル")}</MenuItem>
        <MenuItem onClick={onAddDeck}>{t("カードデッキ（トランプ）")}</MenuItem>
        <MenuItem onClick={onPasteCharacter}>{t("貼り付け")}</MenuItem>
      </Menu>
    </>
  );
};

const relativeCell = ({
  x,
  y,
  scale,
  cellSize,
  centerX,
  centerY,
}: {
  x: number;
  y: number;
  scale: number;
  cellSize: number;
  centerX: number;
  centerY: number;
}): { x: number; y: number } => {
  // 相対位置 = 中心点からの距離をcellの大きさ(縮尺込み)で割ったもの
  return {
    x: Math.round((x - centerX) / cellSize / scale),
    y: Math.round((y - centerY) / cellSize / scale),
  };
};

const getEventPos = (
  event: MouseEvent | TouchEvent
): {
  x: number | undefined;
  y: number | undefined;
} => {
  if (isTouchEvent(event)) {
    return {
      x: event.touches[0].clientX,
      y: event.touches[0].clientY,
    };
  } else {
    return {
      x: event.clientX,
      y: event.clientY,
    };
  }
};

// for Safari
const isTouchEvent = (x: MouseEvent | TouchEvent): x is TouchEvent => {
  return x !== null && "touches" in x;
};

const MenuScreen = styled.div`
  height: 100%;
  user-select: none;
`;

const MenuBase = styled.div`
  position: fixed;
`;

export default ContextMenu;
