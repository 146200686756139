import {
  ClipboardEvent,
  CSSProperties,
  memo,
  ReactNode,
  useCallback,
} from "react";
import { useAppDispatch, useAppSelector } from "stores";
import store from "stores/interfaces";
import styled from "styled-components";
import theme from "theme";
import { useDropzone } from "react-dropzone";
import { getIsRoleAudience } from "stores/modules/entities.room.members/selectors";
import { useMediaQuery } from "@mui/material";

type FrameProps = {
  children: ReactNode;
};

const Frame = ({ children }: FrameProps) => {
  const d = useAppDispatch();
  const openRight = useAppSelector(
    (state) => !!store.getAppState(state, "openRoomDisplays") // todo
  );
  const openLeft = useAppSelector(
    (state) => !!store.getAppState(state, "openRoomControls")
  );
  const width = useAppSelector(store.getMenuWidth);
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const pdfOpen = useAppSelector((state) => state.app.state.openPDFViewer);
  const pdfWidth = useAppSelector(
    (state) => state.app.state.openPDFViewerWidth
  );
  const isRoleAudience = useAppSelector(getIsRoleAudience);

  const onSelectBackground = useCallback(() => {
    if (isRoleAudience) {
      return;
    }

    d(
      store.appStateMutate((state) => {
        state.openRoomImageSelect = true;
        state.openRoomImageSelectDir = "background";
        state.openRoomImageSelectTarget = "background/set";
      })
    );
  }, [d, isRoleAudience]);
  const onPaste = useCallback(
    (e: ClipboardEvent<HTMLDivElement>) => {
      e.preventDefault();
      if (isRoleAudience) {
        return;
      }

      const text = e.clipboardData.getData("text/plain");
      d(store.importRoomCharacterFromClipboardText(text));
    },
    [d, isRoleAudience]
  );
  const onDrop = useCallback(
    (files: File[]) => {
      const images = files.filter((file) => /^image\/.+/.test(file.type));
      const zip = files.find((file) => /^application\/.+/.test(file.type));
      d(store.addRoomItemsByImagesDrop(images));
      if (zip) d(store.importRoomDataFromZipFile(zip));
    },
    [d]
  );
  const dropzone = useDropzone({
    onDrop,
    noClick: true,
    accept: ["image/*", "application/*"],
    disabled: isRoleAudience,
  });

  const getStyle = (): CSSProperties => {
    if (pdfOpen) {
      return { right: pdfWidth };
    }

    if (openRight || openLeft) {
      return { right: isMobile ? "100%" : width };
    }

    return { right: 0 };
  };

  return (
    <StyledFrame
      style={getStyle()}
      onDoubleClick={onSelectBackground}
      onPaste={onPaste}
      {...dropzone.getRootProps()}
    >
      {children}
    </StyledFrame>
  );
};

const StyledFrame = styled.div`
  overflow: hidden;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  outline: none;
  ${theme.breakpoints.down("sm")} {
    left: 0;
    right: 0;
  }
`;

export default memo(Frame);
