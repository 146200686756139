import { FormEventHandler, memo, useCallback } from "react";
import { useAppDispatch, useAppSelector } from "stores";
import styled from "styled-components";
import store from "stores/interfaces";
import {
  AppBar,
  Toolbar,
  DialogContent,
  Switch,
  Button,
  TextField,
  DialogActions,
  Typography,
  List,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { useTranslation } from "react-i18next";
import { Controller, useFormContext } from "react-hook-form";
import FormClickAction from "containers/PanelDetail/FormClickAction";
import { addUndo } from "stores/modules/entities.room.histories/slice";

export type FormData = {
  width: number;
  height: number;
  z: number;
  text: string;
  locked: boolean;
  freezed: boolean;

  clickAction: "none" | "link" | "message";
  linkUrl: string;
  messageText: string;
};

type MarkerDetailProps = {
  roomId: string;
  markerId: string;
  onClose: () => void;
  onSubmit: FormEventHandler<HTMLFormElement>;
};

const MarkerDetail = ({
  roomId,
  markerId,
  onClose,
  onSubmit,
}: MarkerDetailProps) => {
  const dispatch = useAppDispatch();
  const [t] = useTranslation();
  const marker = useAppSelector((state) =>
    store.getRoomMarkerById(state, roomId, markerId)
  );

  const { control, register } = useFormContext<FormData>();

  const onImageSelect = useCallback(() => {
    dispatch(
      store.appStateMutate((state) => {
        state.openRoomImageSelect = true;
        state.openRoomImageSelectDir = "marker";
        state.openRoomImageSelectTarget = "marker/update";
        state.enabledUserFilesLoader = true;
      })
    );
  }, [dispatch]);
  const onDelete = useCallback(() => {
    if (window.confirm(t("本当に削除しますか？"))) {
      dispatch(store.deleteRoomMarker(roomId, markerId));
      dispatch(
        addUndo({
          kind: "update-marker",
          id: markerId,
          before: marker,
          after: null,
        })
      );
      dispatch(
        store.appStateMutate((state) => {
          state.openRoomMarkerDetail = false;
        })
      );
    }
  }, [roomId, markerId, marker, dispatch, t]);

  if (!marker) return null;
  return (
    <>
      <AppBar position="static" color="secondary" elevation={0}>
        <Toolbar variant="dense">
          <Typography variant="subtitle2">{t("マーカーパネル設定")}</Typography>
        </Toolbar>
      </AppBar>
      <DialogContent>
        <form onSubmit={onSubmit}>
          <Group>
            <TextField
              variant="standard"
              label={t("横幅")}
              defaultValue={marker.width || 1}
              margin="dense"
              type="number"
              {...register("width", { valueAsNumber: true })}
            />
            <TextField
              variant="standard"
              label={t("縦幅")}
              defaultValue={marker.height || 1}
              margin="dense"
              type="number"
              {...register("height", { valueAsNumber: true })}
            />
            <TextField
              variant="standard"
              label={t("重なり優先度")}
              defaultValue={marker.z || 0}
              margin="dense"
              type="number"
              {...register("z", { valueAsNumber: true })}
            />
          </Group>
          <TextField
            label={t("メモ")}
            variant="filled"
            defaultValue={marker.text || ""}
            multiline
            rows={4}
            maxRows={8}
            fullWidth
            margin="dense"
            {...register("text")}
          />
          <List>
            <ListItem dense>
              <ListItemText
                primary={t("配置固定")}
                secondary={t("現在の位置とサイズでオブジェクトを固定")}
              />
              <ListItemSecondaryAction>
                <Controller
                  name="locked"
                  control={control}
                  render={({ field: { onChange, value, name, ref } }) => (
                    <Switch
                      onChange={onChange}
                      checked={value}
                      name={name}
                      inputRef={ref}
                    />
                  )}
                />
              </ListItemSecondaryAction>
            </ListItem>
            <ListItem dense>
              <ListItemText
                primary={t("サイズ固定")}
                secondary={t("現在のサイズのみを固定します")}
              />
              <ListItemSecondaryAction>
                <Controller
                  name="freezed"
                  control={control}
                  render={({ field: { onChange, value, name, ref } }) => (
                    <Switch
                      onChange={onChange}
                      checked={value}
                      name={name}
                      inputRef={ref}
                    />
                  )}
                />
              </ListItemSecondaryAction>
            </ListItem>
          </List>
          <Button onClick={onImageSelect} variant="outlined" fullWidth>
            {t("画像選択")}
          </Button>
          <StyledAccordion
            elevation={0}
            defaultExpanded={marker.clickAction != null}
          >
            <StyledAccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Typography>{t("高度な設定")}</Typography>
              <Typography variant="body2" color="textSecondary">
                {t("クリックアクションの設定ができます")}
              </Typography>
            </StyledAccordionSummary>
            <StyledAccordionDetails>
              <FormClickAction />
            </StyledAccordionDetails>
          </StyledAccordion>
        </form>
      </DialogContent>
      <DialogActions>
        <Button onClick={onDelete} fullWidth color="secondary">
          {t("削除")}
        </Button>
        <Button onClick={onClose} fullWidth color="primary">
          {t("保存")}
        </Button>
      </DialogActions>
    </>
  );
};

const Group = styled.div`
  display: flex;
  .MuiFormControl-root + .MuiFormControl-root {
    margin-left: 16px;
  }
`;

const StyledAccordion = styled(Accordion)`
  margin-top: 16px;

  background-color: unset;
  &.Mui-expanded {
    margin: auto;
    margin-top: 16px;
  }

  &:before {
    display: none;
  }
`;

const StyledAccordionSummary = styled(AccordionSummary)`
  padding: 0;
  min-height: 48px;
  &.Mui-expanded {
    min-height: 48px;
  }
  .MuiAccordionSummary-content {
    flex-direction: column;
  }
  .MuiAccordionSummary-content.Mui-expanded {
    margin: 12px 0;
  }
`;

const StyledAccordionDetails = styled(AccordionDetails)`
  flex-direction: column;
  padding-top: 24px;
`;

export default memo(MarkerDetail);
