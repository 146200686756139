import React, { useCallback, useState, useEffect, useRef, memo } from "react";
import { shallowEqual } from "react-redux";
import { DefaultRootState } from "stores";
import { useAppDispatch, useAppSelector } from "stores";
import styled from "styled-components";
import store from "stores/interfaces";
import theme from "theme";
import { Tooltip, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import toCDNUrl from "modules/toCDNUrl";
import { fixSelectionOnDragStop } from "modules/drag";
import useLongTap from "hooks/longTap";
import { addUndoUpdateCharacter } from "stores/modules/entities.room.characters/operations";
import { addUndo } from "stores/modules/entities.room.histories/slice";
import DraggableItem, { Position } from "containers/DraggableItem";

type PieceProps = {
  uid: string;
  roomId: string;
  characterId: string;
};

const pieceStateSelectorGenerater =
  (characterId: string) => (state: DefaultRootState) => {
    return {
      character: store.getCharacterById(state, characterId),
      cellSize: store.getAppState(state, "roomScreenCellSize"),
      scale: store.getAppState(state, "roomScreenScale"),
      hasEditableRole: store.getHasEditableRole(state),
      disabled: store.getIsRoleAudience(state),
    };
  };

type PieceState = ReturnType<ReturnType<typeof pieceStateSelectorGenerater>>;

const Piece: React.FC<PieceProps> = (props) => {
  const selector = React.useCallback(
    (state: DefaultRootState) =>
      pieceStateSelectorGenerater(props.characterId)(state),
    [props.characterId]
  );
  const state = useAppSelector(selector, shallowEqual);
  const $ = useEnhance(props, state);
  const [t] = useTranslation();

  const onMouseDown = useCallback((e) => {
    e.stopPropagation();
    fixSelectionOnDragStop();
  }, []);
  return (
    <DraggableItem
      initialPosition={{
        x: $.position.x / state.cellSize,
        y: $.position.y / state.cellSize,
        width: $.size.width,
        height: $.size.height,
      }}
      style={{
        zIndex: 101,
      }}
      onMouseDown={onMouseDown}
      onTouchStart={$.stopPropagation}
      draggableId={props.characterId}
      disableDragging={state.disabled}
      enableResizing={false}
      alignWithGrid={false}
      onUpdate={$.onDragStop}
    >
      <Figure
        style={{
          width: $.size.width * state.cellSize,
          height: $.size.height * state.cellSize,
        }}
        onTouchEnd={$.onTouchCurrent}
        onDoubleClick={$.onCurrent}
        onContextMenu={$.onContextMenu}
        onKeyDown={$.onKeyDown}
        tabIndex={-1}
      >
        <Tooltip title={state.character.memo || ""} placement="top">
          <Image
            src={toCDNUrl(state.character.iconUrl) || "/ccfolia.png"}
            draggable={false}
            style={{
              transform: `rotate(${~~state.character.angle}deg)`,
            }}
            {...$.longTapProps}
          />
        </Tooltip>
        {state.character.width > 1 ? (
          <Label variant="caption" noWrap>
            {state.character.name || t("NONAME")}
          </Label>
        ) : null}
      </Figure>
    </DraggableItem>
  );
};

const Label = styled(Typography)`
  margin-top: -8px;
  position: absolute;
  top: 100%;
  left: 50%;
  transform: translate(-50%, 0);
  max-width: 100%;
  font-weight: bold;
  text-shadow: 1px 1px 0 ${theme.palette.grey[100]},
    -1px -1px 0 ${theme.palette.grey[100]},
    -1px 1px 0 ${theme.palette.grey[100]}, 1px -1px 0 ${theme.palette.grey[100]},
    0px 1px 0 ${theme.palette.grey[100]}, 0-1px 0 ${theme.palette.grey[100]},
    -1px 0 0 ${theme.palette.grey[100]}, 1px 0 0 ${theme.palette.grey[100]};
`;

const Figure = styled.figure`
  position: absolute;
  top: 0;
  left: 0;
  transition: all 160ms ease-out;
  &:hover img {
    filter: drop-shadow(0 0 8px rgba(255, 255, 255, 0.9));
  }
  &:active {
    transition: none;
  }
`;

const Image = styled.img`
  width: 100%;
  position: absolute;
  left: 0;
  bottom: 0;
  right: 0;
  :focus {
    outline: none;
    filter: drop-shadow(0 0 8px rgba(255, 255, 255, 0.9));
  }
`;

const getDisplaySize = ({ width, height }, cellSize) => {
  return {
    width: width,
    height: height,
  };
};

const useEnhance = (props: PieceProps, state: PieceState) => {
  const dispatch = useAppDispatch();
  const [t] = useTranslation();
  const stopPropagation = useCallback(
    (e) => {
      if (!state.disabled) {
        e.stopPropagation();
      }
    },
    [state.disabled]
  );

  const [position, setPosition] = useState({
    x: state.character.x || 0,
    y: state.character.y || 0,
  });

  const [size, setSize] = useState(
    getDisplaySize(state.character, state.cellSize)
  );

  useEffect(() => {
    setPosition({
      x: state.character.x,
      y: state.character.y,
    });
    setSize(getDisplaySize(state.character, state.cellSize));
  }, [
    state.character.x,
    state.character.y,
    state.character.width,
    state.character.height,
    state.character,
    state.cellSize,
  ]);

  const onDragStop = useCallback(
    (data: Position) => {
      fixSelectionOnDragStop();
      const nextPosition = {
        x: Math.round(data.x * state.cellSize),
        y: Math.round(data.y * state.cellSize),
      };
      setPosition(nextPosition);
      if (nextPosition.x !== position.x || nextPosition.y !== position.y) {
        dispatch(
          store.updateCharacter(props.roomId, props.characterId, nextPosition)
        );
        dispatch(addUndoUpdateCharacter(props.characterId, nextPosition));
      }
    },
    [
      position.x,
      position.y,
      dispatch,
      props.roomId,
      props.characterId,
      state.cellSize,
    ]
  );

  const onCurrent = useCallback(
    (e) => {
      e.stopPropagation();
      if (state.disabled) {
        return;
      }

      if (
        props.uid === state.character.owner ||
        !state.character.secret ||
        state.hasEditableRole
      ) {
        dispatch(
          store.appStateMutate((state) => {
            state.openRoomCharacterId = props.characterId;
            state.openRoomCharacter = true;
          })
        );
      }
    },
    [
      dispatch,
      props.characterId,
      props.uid,
      state.character.owner,
      state.character.secret,
      state.hasEditableRole,
      state.disabled,
    ]
  );

  const prev = useRef(0);
  const onTouchCurrent = useCallback(
    (e) => {
      const now = Date.now();
      if (now - prev.current < 320) {
        onCurrent(e);
      }
      prev.current = now;
    },
    [prev, onCurrent]
  );
  const onContextMenu = useCallback(
    (e) => {
      e.preventDefault();
      e.stopPropagation();
      if (state.disabled) {
        return;
      }

      dispatch(
        store.appStateMutate((state) => {
          state.openRoomCharacterMenu = true;
          state.openRoomCharacterMenuId = props.characterId;
          state.roomPointerY = e.pageY || e.touches[0]?.clientY || 0;
          state.roomPointerX = e.pageX || e.touches[0]?.clientX || 0;
        })
      );
    },
    [dispatch, props.characterId, state.disabled]
  );

  const disabledCopyToClipboard = useAppSelector((state) => {
    const room = store.getCurrentRoom(state);
    return room?.parentRoomPackageId != null;
  });
  const keyDownTimer = useRef(0);
  const onKeyDown = useCallback(
    (e) => {
      if (state.disabled) {
        return;
      }

      // Skip Paste
      if (e.keyCode === 86 && (e.metaKey || e.ctrlKey)) {
        return;
      }

      // Skip Undo/Redo
      if (
        (e.code === "KeyZ" || e.code === "KeyY") &&
        (e.metaKey || e.ctrlKey)
      ) {
        return;
      }

      e.preventDefault();
      e.stopPropagation();
      if (Date.now() - keyDownTimer.current < 100) {
        keyDownTimer.current = Date.now();
        return;
      }
      keyDownTimer.current = Date.now();
      switch (e.keyCode) {
        // Take
        case 84:
          if (props.characterId) {
            dispatch(
              store.updateRoomCharacter(props.characterId, {
                owner: props.uid,
              })
            );
            dispatch(
              addUndoUpdateCharacter(props.characterId, {
                owner: props.uid,
              })
            );
          }
          break;
        // Store
        case 83:
          if (props.characterId) {
            dispatch(
              store.updateRoomCharacter(props.characterId, {
                active: false,
                owner: props.uid,
              })
            );
            dispatch(
              addUndoUpdateCharacter(props.characterId, {
                active: false,
                owner: props.uid,
              })
            );
          }
          break;
        // Rotate
        case 82:
          if (props.characterId) {
            const angleUnit = 45;
            const addAngle = e.shiftKey ? -angleUnit : angleUnit;
            dispatch(
              store.updateRoomCharacter(props.characterId, {
                angle: state.character.angle + addAngle,
              })
            );
            dispatch(
              addUndoUpdateCharacter(props.characterId, {
                angle: state.character.angle + addAngle,
              })
            );
          }
          break;
        // Copy to clipboard
        case 67:
          if (
            props.characterId &&
            (e.metaKey || e.ctrlKey) &&
            !disabledCopyToClipboard
          ) {
            dispatch(store.copyRoomCharacterToClipboard(props.characterId));
          }
          break;
        // Duplicate
        case 68:
          if (props.characterId && (e.metaKey || e.ctrlKey)) {
            dispatch(store.duplicateRoomCharacter(props.characterId));
          }
          break;
        // Delete（Backspace）
        case 8:
          if (props.characterId && (e.metaKey || e.ctrlKey)) {
            dispatch(store.deleteCurrentRoomItem(props.characterId));
            if (window.confirm(t("本当に削除しますか？"))) {
              dispatch(store.deleteRoomCharacter(props.characterId));
              dispatch(
                addUndo({
                  kind: "update-character",
                  id: props.characterId,
                  before: state.character,
                  after: null,
                })
              );
            }
          }
          break;
      }
    },
    [
      props.characterId,
      props.uid,
      dispatch,
      state.character,
      t,
      state.disabled,
      disabledCopyToClipboard,
    ]
  );

  const longTapProps = useLongTap(onContextMenu);

  return {
    stopPropagation,
    position,
    setPosition,
    size,
    setSize,
    onDragStop,
    onCurrent,
    onTouchCurrent,
    onContextMenu,
    onKeyDown,
    longTapProps,
  };
};

export default memo(Piece);
