import { DndContext, DragEndEvent, Modifier, useSensor } from "@dnd-kit/core";
import { DraggableData } from "containers/DraggableItem";
import { memo, PropsWithChildren, useCallback, useEffect, useRef } from "react";
import { useAppSelector } from "stores";
import { getAppState } from "stores/modules/app.state/selectors";
import { fixSelectionOnDragStop } from "modules/drag";
import { CancelablePointerSensor } from "modules/cancelablePointSensor";

type Position = {
  x: number;
  y: number;
};

// macOS Safariでは TouchEvent が定義されておらず `x instanceof TouchEvent` と判定できないため、要素で判定する
const isTouchEvent = (x: Event | null): x is TouchEvent => {
  return x !== null && "touches" in x;
};

const DraggableContext = ({ children }: PropsWithChildren) => {
  const sensor = useSensor(CancelablePointerSensor, {
    activationConstraint: {
      distance: 2,
    },
  });
  const startPosition = useRef<Position | null>(null);
  const mousePosition = useRef<Position | null>(null);

  const updateMousePosition = useCallback((e: MouseEvent | TouchEvent) => {
    const positionEvent = isTouchEvent(e) ? e.touches[0] : e;
    mousePosition.current = {
      x: positionEvent.clientX,
      y: positionEvent.clientY,
    };
  }, []);

  useEffect(() => {
    return () => {
      window.removeEventListener("mousemove", updateMousePosition);
      window.removeEventListener("touchmove", updateMousePosition);
    };
  }, [updateMousePosition]);

  const CustomModifier: Modifier = useCallback((args) => {
    const { active, activatorEvent, draggingNodeRect, transform } = args;
    if (!active || !draggingNodeRect) {
      startPosition.current = null;
      mousePosition.current = null;
      return transform;
    }

    const isMouse = activatorEvent instanceof MouseEvent;
    const isTouch = isTouchEvent(activatorEvent);
    if (isMouse || isTouch) {
      const event = isTouch ? activatorEvent.touches[0] : activatorEvent;
      if (!startPosition.current) {
        startPosition.current = { x: event.clientX, y: event.clientY };
      }
      if (!mousePosition.current) {
        mousePosition.current = { x: event.clientX, y: event.clientY };
      }
    }

    return {
      ...transform,
      x: (mousePosition.current?.x || 0) - (startPosition.current?.x || 0),
      y: (mousePosition.current?.y || 0) - (startPosition.current?.y || 0),
    };
  }, []);

  const scale = useAppSelector((state) =>
    getAppState(state, "roomScreenScale")
  );
  const cellSize = useAppSelector((state) =>
    getAppState(state, "roomScreenCellSize")
  );

  const handleDragStart = useCallback(() => {
    window.addEventListener("mousemove", updateMousePosition);
    window.addEventListener("touchmove", updateMousePosition);
  }, [updateMousePosition]);

  const handleDragEnd = useCallback(
    (e: DragEndEvent) => {
      const { active, delta } = e;
      const data = active.data.current as DraggableData;
      const { initialPosition, onUpdate, alignWithGrid } = data;
      if (delta.x || delta.y) {
        const newX = initialPosition.x + (delta.x ?? 0) / scale / cellSize;
        const newY = initialPosition.y + (delta.y ?? 0) / scale / cellSize;
        const gridX = alignWithGrid ? Math.round(newX) : newX;
        const gridY = alignWithGrid ? Math.round(newY) : newY;

        const updatePosition = {
          ...initialPosition,
          x: gridX,
          y: gridY,
        };

        onUpdate(updatePosition);
      }
      fixSelectionOnDragStop();
      startPosition.current = null;
      mousePosition.current = null;
      window.removeEventListener("mousemove", updateMousePosition);
      window.removeEventListener("touchmove", updateMousePosition);
    },
    [scale, cellSize, updateMousePosition]
  );

  return (
    <DndContext
      sensors={[sensor]}
      onDragEnd={handleDragEnd}
      onDragStart={handleDragStart}
      modifiers={[CustomModifier]}
      autoScroll={false}
    >
      {children}
    </DndContext>
  );
};

export default memo(DraggableContext);
