import React, { memo } from "react";
import styled from "styled-components";
import toCDNUrl from "modules/toCDNUrl";

type BackgroundProps = {
  backgroundUrl: string | null;
};

const Background: React.FC<BackgroundProps> = (props) => {
  const { backgroundUrl } = props;
  return (
    <>
      <BackgroundFrame>
        {backgroundUrl ? (
          <BackgroundImage
            style={{
              backgroundImage: `url(${toCDNUrl(backgroundUrl)})`,
            }}
          />
        ) : null}
      </BackgroundFrame>
    </>
  );
};

const BackgroundFrame = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  overflow: hidden;
  max-height: 100%;
  max-width: 100%;
  user-select: none;
  -webkit-user-select: none;
`;

const BackgroundImage = styled.div`
  position: absolute;
  top: -8px;
  left: -8px;
  right: -8px;
  bottom: -8px;
  background-size: cover;
  background-color: #444;
  background-position: 50% 50%;
  /* filter: brightness(30%) grayscale(100%) contrast(60%); */
  filter: blur(8px);
`;

export default memo(Background);
