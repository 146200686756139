import { Howl, HowlOptions } from "howler";
import dice from "assets/dice.mp3";
import { startInactiveSpan } from "@sentry/react";
import { kindofHost } from "./toCDNUrl";

export const soundDice = new Howl({
  src: [dice],
  autoplay: false,
  loop: false,
  volume: 0.2,
});

const TIMEOUT_SENTRY_SPAN_MS = 100000; // 100 sec

export const createHowl = ({
  onload,
  onloaderror,
  ...options
}: HowlOptions): Howl => {
  const src = Array.isArray(options.src) ? options.src[0] : options.src;

  const sentrySpan = startInactiveSpan({
    name: "load-media",
    attributes: { "ccfolia.sound.host_kind": kindofHost(src) },
  });
  const timer = window.setTimeout(() => {
    sentrySpan?.setStatus("timeout");
    sentrySpan?.end();
  }, TIMEOUT_SENTRY_SPAN_MS);

  return new Howl({
    ...options,
    onload: (soundId) => {
      window.clearTimeout(timer);
      sentrySpan?.setStatus("ok");
      sentrySpan?.end();

      if (onload) {
        onload(soundId);
      }
    },
    onloaderror: (soundId, error) => {
      window.clearTimeout(timer);
      sentrySpan?.setStatus("internal_error");
      sentrySpan?.end();

      if (onloaderror) {
        onloaderror(soundId, error);
      }
    },
  });
};

export const isPlayableAudioUrl = (url: string): Promise<boolean> => {
  return new Promise((resolve) => {
    createHowl({
      src: [url],
      autoplay: false,
      volume: 1,
      format: ["mp4", "ogg", "wav"],
      mute: true,
      html5: true,
      onload: () => {
        resolve(true);
      },
      onloaderror: () => {
        resolve(false);
      },
    });
  });
};
