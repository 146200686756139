import React from "react";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import { Menu, MenuItem, IconButton, Button } from "@mui/material";

import LanguageIcon from "@mui/icons-material/Language";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";

type StyleProps = {
  buttonStyle: "BorderButton" | "Normal" | "Small";
};

const languages = {
  ja: "日本語",
  en: "English",
  ko: "한국어",
  zhTw: "繁體中文",
};

const TranslationMenu = ({ buttonStyle }: StyleProps) => {
  const [, i18n] = useTranslation();
  const [open, setOpen] = React.useState(false);
  const onClick = (lang) => {
    i18n.changeLanguage(lang);
    setOpen(false);
  };
  const anchorRef = React.useRef(null);

  return (
    <>
      {(buttonStyle === "BorderButton" || buttonStyle === "Normal") && (
        <StyledButton
          ref={anchorRef}
          onClick={() => setOpen(true)}
          startIcon={<LanguageIcon />}
          endIcon={<ArrowDropDownIcon />}
          buttonStyle={buttonStyle}
        >
          {languages[i18n.language]}
          {i18n.language === "ja" ? null : "(beta)"}
        </StyledButton>
      )}
      {buttonStyle === "Small" && (
        <IconButton ref={anchorRef} onClick={() => setOpen(true)} size="large">
          <LanguageIcon />
        </IconButton>
      )}

      <StyledMenu
        open={open}
        anchorEl={anchorRef.current}
        onClose={() => setOpen(false)}
      >
        <MenuItem onClick={() => onClick("ja")}>{languages.ja}</MenuItem>
        <MenuItem onClick={() => onClick("en")}>{languages.en} (beta)</MenuItem>
        <MenuItem onClick={() => onClick("ko")}>{languages.ko} (beta)</MenuItem>
        <MenuItem onClick={() => onClick("zhTw")}>
          {languages.zhTw} (beta)
        </MenuItem>
      </StyledMenu>
    </>
  );
};

const StyledButton = styled(Button)<StyleProps>`
  .MuiButton-endIcon {
    margin-left: 4px;
  }
  ${(props) =>
    props.buttonStyle === "BorderButton" &&
    `
    font-size: 10px;
    line-height: 1px;
    border-radius: 4px;
    border: 1px solid rgba(255, 255, 255, 0.12);
    .MuiButton-startIcon {
      margin-right: 4px;
      margin-left: 0px;

      > svg {
        font-size: 15px;
      }
    }
    .MuiButton-endIcon {
      margin-top: 2px;
    }
    `}
`;

const StyledMenu = styled(Menu)`
  .MuiMenu-paper {
    background: rgba(0, 0, 0, 0.87);
  }
`;

export default React.memo(TranslationMenu);
