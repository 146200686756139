import { memo, useEffect } from "react";
import styled from "styled-components";
import { Tab, TabProps } from "@mui/material";
import { getUserMediumDirectoryById } from "stores/modules/entities.user.mediumDirectories/selectors";
import { useSortable } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import { useAppSelector } from "stores";

interface MediumListLibrarySortableTabProps extends TabProps {
  id: string;
  setIsDragging?: (isDragging: boolean) => void;
}

const MediumListLibrarySortableTab = ({
  id,
  setIsDragging,
  ...props
}: MediumListLibrarySortableTabProps) => {
  const tab = useAppSelector((state) => getUserMediumDirectoryById(state, id));
  const {
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition,
    isDragging,
  } = useSortable({
    id,
  });

  useEffect(() => {
    if (setIsDragging) {
      setIsDragging(isDragging);
    }
  }, [isDragging, setIsDragging]);

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
    opacity: isDragging ? 0 : 1,
  };

  return (
    <MediumListTab
      {...props}
      label={props.label ? props.label : tab?.name}
      ref={setNodeRef}
      style={style}
      {...attributes}
      {...listeners}
    />
  );
};

const MediumListTab = styled(Tab)`
  min-width: 80px;
`;

export default memo(MediumListLibrarySortableTab);
