import { xxHash32 } from "js-xxhash";
import { actions } from "./slice";
import {
  getDiceBotSystems,
  getDiceBotSystemsInfo,
  checkClientVersion,
  getOfficialPlaylists,
  publishRoomPackage as publishRoomPackageApi,
  deleteRoomPackage as deleteRoomPackageApi,
  getProductById,
  getPaymentCount,
  getOfficialImageDirs,
} from "api";
import sendEvent from "modules/sendEvent";
import { db } from "initializer";
import { getCurrentRoom } from "../entities.rooms/selectors";
// import { getRoomMessageIdsByType } from "../entities.room.messages/selectors";
// import { loadRoomMessages } from "../entities.room.messages/operations";
// import { DefaultRootState } from "stores";
import { importRoomItems } from "../entities.room.items/operations";
import { importRoomDecks } from "../entities.room.decks/operations";
import { importRoomCharacters } from "../entities.room.characters/operations";
import { DefaultThunk } from "stores";
import AES from "crypto-js/aes";
import UTF8 from "crypto-js/enc-utf8";
import PresetOverroid from "presets/overroid.json";
import PresetOverroid_en from "presets/overroid_en.json";
import PresetOverroid_zhCN from "presets/overroid_zh-CN.json";
import PresetOverroid_zhTW from "presets/overroid_zh-TW.json";
import PresetOverroid_ko from "presets/overroid_ko.json";
import { UpdateRoom } from "../entities.rooms";
import { updateCurrentRoom } from "../entities.rooms/operations";

import { exportData, importData } from "modules/exporter";
import { getMyRoomCharacters } from "../entities.room.characters/selectors";
import { getAuthedUid, getIsPro, getUid } from "../app.user/selectors";
import { getAppState, getRoomMemberDirectMessageTabs } from "./selectors";
import { importRoomEffects } from "../entities.room.effects/operations";
import { importRoomScenes } from "../entities.room.scenes/operations";
import { importRoomNotes } from "../entities.room.notes/operations";
import { produce } from "immer";
import {
  fetchSnapshot,
  importRoomSavedatas,
  importRoomSnapshots,
} from "../entities.room.savedatas/operations";
import { Savedata, UpdateSnapshot } from "../entities.room.savedatas";
import { DocumentReference, doc, getDoc } from "firebase/firestore";
import { getRefererBuyClick } from "modules/referer";
import { soundDice } from "modules/sound";
import i18next from "i18next";
// import { saveAs } from "file-saver";
// import JSZip from "jszip/dist/jszip.min.js";
// import mime from "mime/lite";
// import TypedArrays from "crypto-js/lib-typedarrays";
// import sha256 from "crypto-js/sha256";

export const appStateMutate = actions.appStateMutate;

export const openRoomDetail = (roomId: string) => {
  return actions.appStateMutate((draft) => {
    draft.openRoomDetailId = roomId;
    draft.openRoomDetail = true;
  });
};

export const checkClient = (): DefaultThunk => async (dispatch) => {
  const updatedVersion = await checkClientVersion();
  if (updatedVersion) {
    dispatch(
      actions.appStateMutate((draft) => {
        draft.updatedVersion = updatedVersion;
      })
    );
  }
};

export const closeRoomDetail = () => {
  return actions.appStateMutate((draft) => {
    draft.openRoomDetail = false;
  });
};

let diceBotSystemsLoaded = false;
export const loadDiceBotSystems = (): DefaultThunk => async (dispatch) => {
  if (!diceBotSystemsLoaded) {
    diceBotSystemsLoaded = true;
    const res = await getDiceBotSystems();
    if (res) {
      dispatch(
        appStateMutate((draft) => {
          if (res == null) return;
          draft.diceBotTypes = res.names;
        })
      );
    }
  }
};

interface Count {
  value?: number;
}
const countsRef = doc(db, "counts", "supporters") as DocumentReference<Count>;

export const loadSupportersCount = (): DefaultThunk => async (dispatch, _) => {
  const count = await getDoc(countsRef);
  if (count) {
    dispatch(
      appStateMutate((draft) => {
        draft.supportersCount = {
          value: count.data()?.value || 0,
        };
      })
    );
  }
};

export const loadDiceBotInfo =
  (): DefaultThunk => async (dispatch, getState) => {
    const room = getCurrentRoom(getState());
    if (!room) return null;
    if (room.diceBotSystem == null) {
      dispatch(
        appStateMutate((draft) => {
          draft.openRoomDiceBotInfo = true;
          draft.openRoomDiceBotInfoText = "※ ダイスボット未設定";
        })
      );
    }

    dispatch(
      appStateMutate((draft) => {
        draft.openRoomDiceBotInfo = true;
        draft.openRoomDiceBotInfoText = "Loading...";
      })
    );

    const res = await getDiceBotSystemsInfo(room.diceBotSystem); // todo
    if (res) {
      dispatch(
        appStateMutate((draft) => {
          if (draft.openRoomDiceBotInfo) {
            draft.openRoomDiceBotInfoText = res.systeminfo.info || "※説明なし";
          }
        })
      );
    }
  };

export const createDirectMessageChannelKey = (ids: string[]) =>
  xxHash32(ids.sort().join(","), 0).toString(16);

export const currentDirectMessageTab =
  (to: string): DefaultThunk =>
  (dispatch, getState) => {
    const uid = getUid(getState());
    if (uid == null || uid === to) return null;
    const state = getState();
    const directMessageChannels = getRoomMemberDirectMessageTabs(state);
    const channelKey = createDirectMessageChannelKey([uid, to]);
    const directMessageChannel = directMessageChannels.find(
      (dm) => dm.key === channelKey
    );
    if (!directMessageChannel) return;
    dispatch(
      appStateMutate((draft) => {
        draft.roomChatTab = channelKey;
      })
    );
  };

export const changeRoomTab =
  (tab: string): DefaultThunk =>
  (dispatch) => {
    dispatch(
      appStateMutate((draft) => {
        draft.roomChatTab = tab;
        draft.roomChatTabs[tab] = 0;
      })
    );
    // const state = getState();
    // const messages = getRoomMessageIdsByType(state);
    // console.log(messages);
    // if (messages.length < 1) {
    //   dispatch(loadRoomMessages());
    // }
  };

// const toSHA256hash = (file: File) => {
//   return new Promise<string>((resolve) => {
//     const reader = new FileReader();
//     reader.onload = function () {
//       resolve(TypedArrays.create(reader.result as ArrayBuffer).toString());
//     };
//     reader.readAsArrayBuffer(file);
//   });
// };

// export const exportRoomData = (): DefaultThunk => async (_, getState) => {
//   const state = getState();
//   const roomData = {
//     meta: {
//       version: "1.0.0",
//     },
//     entities: {
//       items: state.entities.roomItems.byId,
//       decks: state.entities.roomDecks.byId,
//       scenes: state.entities.roomScenes.byId,
//       characters: state.entities.roomCharacters.byId,
//       effects: state.entities.roomEffects.byId,
//       notes: state.entities.roomNotes.byId,
//     },
//   };
//   const encrypted = AES.encrypt(JSON.stringify(roomData), "ccfolia").toString();
//   // const decrypted = AES.decrypt(encrypted, "ccfolia").toString(UTF8);
// };

export const importRoomData =
  (encryptedData: string): DefaultThunk =>
  (dispatch) => {
    try {
      const decrypted = AES.decrypt(encryptedData, "ccfolia").toString(UTF8);
      const roomData = JSON.parse(decrypted);
      dispatch(importRoomItems(roomData.entities.items));
    } catch (err) {
      window.alert(i18next.t("失敗しました。"));
    }
  };

const presets = {
  ja: PresetOverroid,
  en: PresetOverroid_en,
  ko: PresetOverroid_ko,
  zhCN: PresetOverroid_zhCN,
  zhTW: PresetOverroid_zhTW,
} as const;

export const insertRoomDataFromPreset =
  (presetId: keyof typeof presets): DefaultThunk =>
  (dispatch) => {
    const preset = presets[presetId];
    if (!preset) return null;
    try {
      dispatch(importRoomItems(preset.items));
      dispatch(importRoomDecks(preset.decks));
      dispatch(updateCurrentRoom(preset.room));
    } catch (err) {
      window.alert(i18next.t("失敗しました。"));
    }
  };

export const exportRoomDataForPreset =
  (options: {
    room: boolean;
    characters: boolean;
    items: boolean;
    decks: boolean;
    notes: boolean;
    effects: boolean;
    scenes: boolean;
    savedatas: boolean;
  }): DefaultThunk =>
  async (_, getState) => {
    const state = getState();
    const uid = getUid(state);
    const roomId = getAppState(state, "roomId");
    const room: UpdateRoom = { ...getCurrentRoom(state) };
    if (!room || !uid || room.owner !== uid) return null;
    const items = state.entities.roomItems.entities;
    const decks = state.entities.roomDecks.entities;
    const notes = state.entities.roomNotes.entities;
    const effects = state.entities.roomEffects.entities;
    const scenes = state.entities.roomScenes.entities;
    const savedatas = state.entities.roomSavedatas.entities;
    const snapshots =
      options.savedatas && roomId
        ? await fetchSnapshots(roomId, savedatas)
        : {};
    const characters = getMyRoomCharacters(state);
    const roomData = {
      room: options.room ? room : {},
      items: options.items ? items : {},
      decks: options.decks ? decks : {},
      notes: options.notes ? notes : {},
      characters: options.characters ? characters : {},
      effects: options.effects ? effects : {},
      scenes: options.scenes ? scenes : {},
      savedatas: options.savedatas ? savedatas : {},
      snapshots,
    };

    exportData(roomData);
  };

const fetchSnapshots = async (
  roomId: string,
  savedatas: Record<string, Savedata>
): Promise<Record<string, UpdateSnapshot>> => {
  const snapshots = await Promise.all(
    Object.values(savedatas).map(async (savedata) => {
      return {
        id: savedata.snapshotId,
        date: await fetchSnapshot(roomId, savedata),
      };
    })
  );

  const record: Record<string, UpdateSnapshot> = {};
  for (const snapshot of snapshots) {
    if (snapshot.date) {
      record[snapshot.id] = snapshot.date;
    }
  }
  return record;
};

export const importRoomDataFromZipFile =
  (file: File): DefaultThunk =>
  async (dispatch) => {
    const roomData = await importData(file);
    try {
      dispatch(importRoomItems(roomData.items || {}));
      dispatch(importRoomDecks(roomData.decks || {}));
      dispatch(importRoomNotes(roomData.notes || {}));
      dispatch(importRoomCharacters(roomData.characters || {}));
      dispatch(importRoomEffects(roomData.effects || {}));
      dispatch(importRoomScenes(roomData.scenes || {}));
      dispatch(updateCurrentRoom(roomData.room || {}));
      dispatch(importRoomSavedatas(roomData.savedatas || {}));
      dispatch(importRoomSnapshots(roomData.snapshots || {}));
    } catch (err) {
      window.alert(i18next.t("失敗しました。"));
    }
  };

export const insertRoomData = (): DefaultThunk => (dispatch) => {
  try {
    dispatch(importRoomItems(PresetOverroid.items));
    dispatch(importRoomDecks(PresetOverroid.decks));
    dispatch(updateCurrentRoom(PresetOverroid.room));
  } catch (err) {
    window.alert(i18next.t("失敗しました。"));
  }
};

export const publishRoomPackage =
  (): DefaultThunk<Promise<void>> =>
  async (dispatch, getState): Promise<void> => {
    const state = getState();
    const uid = getUid(state);
    const roomId = getAppState(state, "roomId");
    const currentRoom = getCurrentRoom(state);
    if (!roomId || !currentRoom || !uid || currentRoom.owner !== uid) return;

    const { roomPackageId } = await publishRoomPackageApi(roomId);
    dispatch(updateCurrentRoom({ publishedRoomPackageId: roomPackageId }));
  };

export const deleteRoomPackage =
  (roomPackageId: string): DefaultThunk<Promise<void>> =>
  async (dispatch): Promise<void> => {
    await deleteRoomPackageApi(roomPackageId);
    await dispatch(updateCurrentRoom({ publishedRoomPackageId: null }));
  };

export const addNotification =
  (text: string): DefaultThunk =>
  (dispatch) => {
    return dispatch(
      appStateMutate((draft) => {
        if (!draft.notifications.includes(text)) {
          draft.notifications.push(text);
        }
      })
    );
  };

export const popNotifications = (): DefaultThunk => (dispatch) => {
  return dispatch(
    appStateMutate((draft) => {
      draft.notifications.pop();
    })
  );
};

export const shiftNotifications = (): DefaultThunk => (dispatch) => {
  return dispatch(
    appStateMutate((draft) => {
      draft.notifications.shift();
    })
  );
};

let officialPlaylistsLoaded = false;
export const loadOfficialPlaylists = (): DefaultThunk => async (dispatch) => {
  if (!officialPlaylistsLoaded) {
    officialPlaylistsLoaded = true;
    const playlists = await getOfficialPlaylists();
    dispatch(
      appStateMutate((draft) => {
        draft.officialPlaylists = playlists;

        const playlist = playlists[0];
        if (playlist != null) {
          draft.userMediumListOfficialPlaylistTab = playlist.id;
        }
      })
    );
  }
};

let officialImagesLoaded = false;
export const loadOfficialImages = (): DefaultThunk => async (dispatch) => {
  if (!officialImagesLoaded) {
    officialImagesLoaded = true;
    const dirs = await getOfficialImageDirs();
    dispatch(
      appStateMutate((draft) => {
        draft.officialImages = dirs;

        const dir = dirs[0];
        if (dir != null) {
          draft.openOfficialImageSelectDir = dir.id;
        }
      })
    );
  }
};

export const loadParentProduct =
  (productId: string): DefaultThunk =>
  async (dispatch) => {
    const product = await getProductById(productId);
    dispatch(
      appStateMutate((draft) => {
        draft.parentProduct = product;
      })
    );
  };

export const focusMediumDirectory = (directoryId: string) => {
  return actions.appStateMutate((state) => {
    state.userMediumListLibraryTab = directoryId;
    if (!state.subscribedUserMediumDir.includes(directoryId)) {
      state.subscribedUserMediumDir = produce(
        state.subscribedUserMediumDir,
        (draft) => {
          // firebase で in に含められる条件が10個まで
          if (draft.length === 10) {
            draft.shift();
          }
          draft.push(directoryId);
        }
      );
    }
  });
};

type ViewProductPageOptions = {
  productId: string;
  referer: string;
  refererKind: "external" | "internal" | "unknown";
};

export const sendViewProductPageEvent =
  ({ productId, referer, refererKind }: ViewProductPageOptions): DefaultThunk =>
  async (_, getState) => {
    const state = getState();

    const isPro = getIsPro(state);
    const isLoginUser = getAuthedUid(state) != null;

    const hasPayments = isLoginUser ? (await getPaymentCount()) > 0 : false;

    sendEvent("viewProductPage", {
      product_id: productId,
      is_pro: isPro,
      is_login_user: isLoginUser,
      has_payments: hasPayments,
      referer: referer || "unknown",
      referer_kind: refererKind || "unknown",
    });
  };

export const sendPurchaseProductEvent =
  (productId: string): DefaultThunk =>
  async (_, getState) => {
    const state = getState();

    const isPro = getIsPro(state);
    const isLoginUser = getAuthedUid(state) != null;
    const refererData = getRefererBuyClick(productId);

    const isFirstPayment = isLoginUser
      ? (await getPaymentCount()) === 0
      : false;

    sendEvent("purchaseProduct", {
      product_id: productId,
      is_pro: isPro,
      is_first_payment: isFirstPayment,
      referer: refererData?.referer || "unknown",
      referer_kind: refererData?.refererKind || "unknown",
    });
  };

export const playDiceSound = (): DefaultThunk => (_, getState) => {
  const state = getState();
  const muted = getAppState(state, "muted");
  const masterVolume = getAppState(state, "roomMediaVolume");
  soundDice.mute(muted);
  soundDice.volume(masterVolume * 0.4);
  soundDice.play();
};

// Feature config

type FeatureConfig = Partial<{
  enabledGoCdnOnAudio: boolean;
}>;

const defaultFeatureConfig: FeatureConfig = {
  enabledGoCdnOnAudio: false,
};

const featureConfigDoc = doc(
  db,
  "configs",
  "feature"
) as DocumentReference<FeatureConfig>;

const fetchFeatureConfig = async (): Promise<FeatureConfig> => {
  try {
    const snapshot = await getDoc(featureConfigDoc);
    return snapshot.data() || defaultFeatureConfig;
  } catch {
    return defaultFeatureConfig;
  }
};

export const loadFeatureConfig = (): DefaultThunk => async (dispatch) => {
  const config = await fetchFeatureConfig();

  dispatch(
    appStateMutate((state) => {
      state.loadedConfig = true;
      state.config = {
        enabledGoCdnOnAudio: config.enabledGoCdnOnAudio ?? false,
      };
    })
  );
};
