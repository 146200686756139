import { actions } from "./slice";
import { db } from "initializer";
import sendEvent from "modules/sendEvent";
import { createSubscribeCollection } from "../firestoreModuleUtils/operators";
import { sendDiceBotCommand, deleteAllRoomMessages } from "api";
import { getChannelName, getFirstRoomMessage } from "./selectors";
import {
  getCharacterById,
  getUserCharacterIdByName,
} from "../entities.room.characters/selectors";
import { getRoomById } from "../entities.rooms/selectors";
import { getAppState } from "../app.state/selectors";
import { getAppChatState } from "../app.chat/selectors";
import { Character, UpdateCharacter } from "../entities.room.characters/";
import {
  addUndoUpdateCharacter,
  updateRoomCharacter,
} from "../entities.room.characters/operations";
import { activeRoomEffectByText } from "../entities.room.effects/operations";
import {
  addUndoSceneChange,
  applyScene,
  preloadSceneResources,
} from "../entities.room.scenes/operations";
import { appStateMutate } from "../app.state/operations";
import { appChatStateMutate } from "../app.chat/operations";
import { Message, MessageType, UpdateMessage } from "./";
import { DefaultThunk } from "stores";
import {
  updateCurrentRoom,
  updateCurrentRoomField,
} from "../entities.rooms/operations";
import shortid from "shortid";
import ReactPlayer from "react-player";
import { ThunkDispatch } from "redux-thunk";
import { DefaultRootState } from "stores";
import { getRoomSceneIdByName } from "../entities.room.scenes/selectors";
import { getCurrentRoom } from "../entities.rooms/selectors";
import {
  getRoomSavedataByName,
  getRoomSavedataIdByName,
} from "../entities.room.savedatas/selectors";
import { produce } from "immer";
import {
  addDoc,
  collection,
  limit,
  orderBy,
  query,
  Timestamp,
  serverTimestamp,
  setDoc,
  doc,
  deleteDoc,
  getDocs,
  where,
  startAfter,
  writeBatch,
} from "firebase/firestore";
import { filterDiceSkinSettings } from "modules/diceSkin";
import { getHasEditableRole } from "../entities.room.members/selectors";
import YouTubePlayer from "react-player/youtube";
import { isPlayableAudioUrl } from "modules/sound";
import { loadRoomBySnapshotId } from "../entities.room.savedatas/operations";
import { AES } from "crypto-js";
import Utf8 from "crypto-js/enc-utf8";
import i18next from "i18next";
import { getUid } from "../app.user/selectors";
import toCDNUrl from "modules/toCDNUrl";

export const DEFAULT_COMMAND = ["initiative"];

const ORIGIN_WHITE_LIST = [
  "https://ccfolia.com",
  "https://x.com",
  "https://twitter.com",
  "https://social-plugins.line.me",
  "https://www.facebook.com",
];

const messagesRef = (roomId: string) =>
  collection(db, "rooms", roomId, "messages");

export const shiftAddedRoomMessage = (): DefaultThunk => (dispatch) => {
  return dispatch(actions.shiftAddedRoomMessage());
};

export const subscribeRoomMessages = createSubscribeCollection(
  // TODO: fix any
  actions as any,
  (roomId: string) =>
    query(messagesRef(roomId), orderBy("createdAt", "desc"), limit(50)),
  true
);

const getMessageType = (text: string) => {
  if (!text) return "text";
  const matcher = /^\/(.+?)\s/;
  const matches = text.match(matcher);
  if (matches) {
    if (matches[1] === "note") {
      return "note";
    } else if (matches[1] === "system") {
      return "system";
    }
  }
  return "text";
};
const trimText = (text: string) => {
  if (!text) return "";
  const prefixMatcher = /^\/(.+?)\s/;
  const suffixMatcher = /@\S+$/;
  return text.replace(prefixMatcher, "").replace(suffixMatcher, "");
};
const getUserDefine = (character: Character) => {
  return character.commands
    .split(/[\r\n]+/)
    .map((s) => String(s))
    .filter((str) => str.slice(0, 2) === "//")
    .map((userdef) =>
      userdef.slice(2).replace(/[　]/g, " ").replace(/[＝]/g, "=")
    );
};

type Control = {
  label: string;
  ope: "+" | "-" | "=";
  val: number;
  rest: string;
};

const parseControl = (text: string): Control | null => {
  const reg = /^:(.+)(\+|-|=)(\d+)(.*)/;
  const res = reg.exec(text);
  if (res === null) return null;
  const label = res[1];
  const ope = res[2];
  const val = parseInt(res[3]);
  const rest = res[4];
  if (isNaN(val)) return null;
  switch (ope) {
    case "+":
    case "-":
    case "=":
      return {
        label,
        ope,
        val,
        rest,
      };
  }
  return null;
};

const changeValueWithMessage =
  (
    control: Control,
    type: "status" | "default",
    idx: number,
    character: Character,
    roomId: string
  ): DefaultThunk =>
  (dispatch) => {
    if (character._id === undefined) return;
    const currentValue =
      type === "status" ? character[type][idx].value : character[control.label];
    const nextValue =
      control.ope === "+"
        ? currentValue + control.val
        : control.ope === "-"
        ? currentValue - control.val
        : control.val;
    let updateData: UpdateCharacter = {};
    if (type === "status") {
      const nextValues = character[type].map((v) =>
        v.label === control.label ? { ...v, value: nextValue } : v
      );
      updateData = { [type]: nextValues };
      dispatch(updateRoomCharacter(character._id, updateData));
    }
    if (type === "default") {
      updateData = { [control.label]: nextValue };
      dispatch(updateRoomCharacter(character._id, updateData));
    }
    if (!character.secret) {
      dispatch(
        addMessage(
          roomId,
          null,
          {
            text: `/system [ ${character.name} ] ${control.label} : ${currentValue} → ${nextValue}`,
            name: "system",
            channel: "main",
          },
          false
        )
      );
      dispatch(
        addUndoUpdateCharacter(character._id, updateData, {
          label: control.label,
          type: type,
        })
      );
    } else {
      dispatch(addUndoUpdateCharacter(character._id, updateData));
    }
    return;
  };

const isValidUrl = (url: string): boolean => {
  try {
    new URL(url);
    return true;
  } catch (error) {
    return false;
  }
};

type TextData = {
  to?: string;
  roomChatToName?: string;
  name?: string;
  iconUrl?: string | null;
  text?: string;
  color?: string;
  channel?: string;
  channelName?: string;
};

const SYSTEM_MESSAGE_COLOR = "#888888";

export const addMessage =
  (
    roomId: string,
    characterId: string | null,
    textData: TextData = {},
    clearText: boolean = true,
    standalone: boolean = false
  ): DefaultThunk =>
  async (dispatch, getState) => {
    const startTime = Date.now();
    const state = getState();
    const room = getRoomById(state, roomId);
    const character = getCharacterById(state, characterId);
    const from = getAppState(state, "uid");
    const to = textData.to || getAppState(state, "roomChatTo");
    const toName =
      textData.roomChatToName || getAppState(state, "roomChatToName");
    const name = textData.name || getAppState(state, "roomChatName");
    const iconUrl = textData.iconUrl || null;
    const imageUrl = textData.iconUrl || null;
    const inputText = textData.text || getAppChatState(state, "inputText");
    const color =
      textData.color ||
      (characterId ? character.color : null) || // character.color がない場合は roomChatColor を使う
      getAppState(state, "roomChatColor");
    const channel = textData.channel || getAppState(state, "roomChatTab");
    const channelName = textData.channelName || getChannelName(state, channel);
    const diceSkinSettings = character._id
      ? character.diceSkin
      : state.entities.userSetting.diceSkin;
    const isPro = getAppState(state, "plan") === "ccfolia-pro";

    if (!room || !from || !inputText) return;

    const slashCommandHandler = getSlashCommandHandler(inputText);
    if (slashCommandHandler != null) {
      const handled = await slashCommandHandler({
        roomId,
        characterId,
        textData,
        inputText,
        startTime,
        isPro,
        dispatch,
        getState,
        isStandalone: standalone,
      });

      if (handled) {
        if (clearText) {
          dispatch(
            appChatStateMutate((state) => {
              state.inputText = "";
            })
          );
        }
        return;
      }
    }

    // secret function
    if (inputText === "/preload") {
      return dispatch(preloadSceneResources());
    }
    if (inputText === "/open scenes") {
      window.open(
        `/rooms/${roomId}/studio`,
        "ccfolia.room.scenes",
        "top=100,left=100,width=320,height=480"
      );
      return;
    }

    const data = {
      to,
      toName,
      from,
      name,
      iconUrl,
      color,
      imageUrl,
      text: inputText,
    };

    // clear text field
    if (clearText) {
      dispatch(
        appChatStateMutate((state) => {
          state.inputText = "";
        })
      );
    }

    const type = getMessageType(data.text);
    if (type === "note" || type === "system") {
      // シナリオテキストとシステムメッセージの場合、色をデフォルトに変更
      data.color = SYSTEM_MESSAGE_COLOR;
    }

    // room variables
    if (type === "text") {
      data.text = expandParams(data.text, (p1) => {
        const upperCaseLabel = p1.toUpperCase();
        const variable = room.variables.find(
          (variable) => variable.label.toUpperCase() === upperCaseLabel
        );

        return variable?.value;
      });
    }

    // variables
    if (character._id && type === "text") {
      const control = parseControl(data.text);
      if (control !== null) {
        const stateIdx = character.status.findIndex(
          (state) => state.label === control.label
        );
        const defaultCommandIdx = DEFAULT_COMMAND.findIndex(
          (command) => command === control.label
        );
        if (stateIdx !== -1) {
          dispatch(
            changeValueWithMessage(
              control,
              "status",
              stateIdx,
              character,
              roomId
            )
          );
          return;
        } else if (defaultCommandIdx !== -1) {
          dispatch(
            changeValueWithMessage(
              control,
              "default",
              defaultCommandIdx,
              character,
              roomId
            )
          );
          return;
        }
      }
      data.name = character.name;
      data.iconUrl = character.iconUrl;
      data.imageUrl = character.invisible ? null : character.iconUrl;
      const userdefs = getUserDefine(character);

      data.text = expandParams(data.text, (p1) => {
        const upperCaseLabel = p1.toUpperCase();
        const state = character.status.find(
          (state) => state.label && state.label.toUpperCase() === upperCaseLabel
        );
        const param = character.params.find(
          (param) => param.label && param.label.toUpperCase() === upperCaseLabel
        );
        const userdef = userdefs.find((def) => {
          const variable = def.split("=", 1)[0].trim().toUpperCase();
          return variable === upperCaseLabel;
        });

        if (
          upperCaseLabel === "INITIATIVE" ||
          upperCaseLabel === "イニシアティブ"
        ) {
          return character.initiative.toString();
        } else if (state) {
          return state.value.toString();
        } else if (param) {
          return param.value;
        } else if (userdef) {
          let tmp = userdef.split("=");
          tmp.shift();
          return tmp.join("=").trim();
        }

        return;
      });
    }

    // roll
    const roll = await sendDiceBotCommand(room.diceBotSystem, data.text);
    const extend = roll
      ? {
          roll: {
            ...roll,
            skin: filterDiceSkinSettings(
              diceSkinSettings,
              room.diceBotSystem,
              isPro
            ),
          },
        }
      : {};

    // faces
    if (character.faces) {
      const face = character.faces
        .slice()
        .sort((a, b) => {
          return b.label.length - a.label.length;
        })
        .find((face) => {
          if (!face.label) return false;
          const endsWithText = data.text.endsWith(face.label);
          const endsWithDiceResult = roll && roll.result.endsWith(face.label);
          return endsWithText || endsWithDiceResult;
        });
      if (face) {
        data.iconUrl = face.iconUrl;
        data.imageUrl = character.invisible ? null : face.iconUrl;
        if (character._id !== undefined) {
          dispatch(
            updateRoomCharacter(character._id, { iconUrl: face.iconUrl })
          );
          dispatch(
            addUndoUpdateCharacter(character._id, { iconUrl: face.iconUrl })
          );
        }
      }
    }

    // effects
    const effectText = extend.roll ? extend.roll.result : data.text;
    if (
      (channel === "main" || channel === "info" || channel === "other") &&
      (!roll || !roll.secret)
    ) {
      dispatch(activeRoomEffectByText(roomId, effectText));
    }

    const displayText = trimText(data.text);
    if (displayText) {
      return addDoc(messagesRef(roomId), {
        ...data,
        text: displayText,
        type,
        channel,
        channelName,
        extend,
        edited: false,
        updatedAt: serverTimestamp(),
        createdAt: serverTimestamp(),
      }).then((res) => {
        sendEvent("addRoomMessage", { value: ~~(Date.now() - startTime) });
        return res;
      });
    }
  };

const MAX_EXPAND_TIMES = 5;
const MAX_EXPAND_STRING_SIZE = 10000;

const expandParams = (
  text: string,
  find: (label: string) => string | undefined
): string => {
  const regexp = /\{([^}]+)\}/gm;
  const replacer = (match: string, label: string) => {
    const s = find(label);
    return s == null ? match : s;
  };

  let prevText = "";
  for (
    let i = 0;
    i < MAX_EXPAND_TIMES &&
    prevText !== text &&
    text.length < MAX_EXPAND_STRING_SIZE;
    i++
  ) {
    prevText = text;
    text = text.replace(regexp, replacer);
  }

  return text;
};

export const addMessageCurrentRoom =
  (text: string, clearText: boolean = true): DefaultThunk =>
  (dispatch, getState) => {
    const state = getState();
    const uid = getAppState(state, "uid");
    const roomId = getAppState(state, "roomId");
    const name = getAppState(state, "roomChatName");
    const characterId = getUserCharacterIdByName(state, {
      name,
      uid,
    });

    if (roomId == null) {
      return;
    }

    dispatch(addMessage(roomId, characterId || null, { text }, clearText));
  };

type AddMessageWithRollOptions = {
  roll: NonNullable<Message["extend"]["roll"]>;
  textData: TextData;
};

export const addMessageWithRoll =
  ({ textData, roll }: AddMessageWithRollOptions): DefaultThunk =>
  async (dispatch, getState) => {
    const startTime = Date.now();
    const state = getState();

    const uid = getUid(state);
    const roomId = getAppState(state, "roomId") || "";
    const room = getRoomById(state, roomId);

    const from = getAppState(state, "uid");
    const to = textData.to || getAppState(state, "roomChatTo");
    const toName =
      textData.roomChatToName || getAppState(state, "roomChatToName");
    const name = textData.name || getAppState(state, "roomChatName");
    const characterId = getUserCharacterIdByName(state, {
      name,
      uid,
    });
    const character = getCharacterById(state, characterId || "");
    const iconUrl = textData.iconUrl || null;
    const imageUrl = textData.iconUrl || null;
    const inputText = textData.text || getAppChatState(state, "inputText");
    const color =
      textData.color ||
      (characterId ? character.color : null) || // character.color がない場合は roomChatColor を使う
      getAppState(state, "roomChatColor");
    const channel = textData.channel || getAppState(state, "roomChatTab");
    const channelName = textData.channelName || getChannelName(state, channel);
    const diceSkinSettings = character._id
      ? character.diceSkin
      : state.entities.userSetting.diceSkin;
    const isPro = getAppState(state, "plan") === "ccfolia-pro";

    if (!room || !from || !inputText) return;

    const data = {
      to,
      toName,
      from,
      name,
      iconUrl,
      color,
      imageUrl,
      text: inputText,
    };

    const type = getMessageType(data.text);

    // variables
    if (character._id && type === "text") {
      data.name = character.name;
      data.iconUrl = character.iconUrl;
      data.imageUrl = character.invisible ? null : character.iconUrl;
    }

    // roll
    const extend: Message["extend"] = {
      roll: {
        ...roll,
        skin: filterDiceSkinSettings(
          diceSkinSettings,
          room.diceBotSystem,
          isPro
        ),
      },
    };

    // faces
    if (character.faces) {
      const face = character.faces
        .slice()
        .sort((a, b) => {
          return b.label.length - a.label.length;
        })
        .find((face) => {
          if (!face.label) return false;
          const endsWithText = data.text.endsWith(face.label);
          const endsWithDiceResult = roll && roll.result.endsWith(face.label);
          return endsWithText || endsWithDiceResult;
        });
      if (face) {
        data.iconUrl = face.iconUrl;
        data.imageUrl = character.invisible ? null : face.iconUrl;
        if (character._id !== undefined) {
          dispatch(
            updateRoomCharacter(character._id, { iconUrl: face.iconUrl })
          );
          dispatch(
            addUndoUpdateCharacter(character._id, { iconUrl: face.iconUrl })
          );
        }
      }
    }

    // effects
    const effectText = extend.roll ? extend.roll.result : data.text;
    if (
      (channel === "main" || channel === "info" || channel === "other") &&
      (!roll || !roll.secret)
    ) {
      dispatch(activeRoomEffectByText(roomId, effectText));
    }

    const displayText = trimText(data.text);
    if (displayText) {
      return addDoc(messagesRef(roomId), {
        ...data,
        text: displayText,
        type,
        channel,
        channelName,
        extend,
        edited: false,
        updatedAt: serverTimestamp(),
        createdAt: serverTimestamp(),
      }).then((res) => {
        sendEvent("addRoomMessage", { value: ~~(Date.now() - startTime) });
        return res;
      });
    }
  };

export const updateRoomMessage =
  (
    messageId: string,
    data: {
      text?: string;
      extend?: {
        roll: {
          secret: boolean;
        };
      };
    },
    edited: boolean = true
  ): DefaultThunk =>
  (_, getState) => {
    const roomId = getState().app.state.roomId;
    if (!roomId) return null;
    return setDoc(
      doc(messagesRef(roomId), messageId),
      {
        ...data,
        edited,
        updatedAt: serverTimestamp(),
      },
      { merge: true }
    );
  };

export const deleteMessage = (roomId: string, messageId: string) => () => {
  return deleteDoc(doc(messagesRef(roomId), messageId));
};

export const deleteAllMessages = (roomId: string) => async (dispatch) => {
  dispatch(
    appStateMutate((state) => {
      state.loading = true;
    })
  );
  try {
    await deleteAllRoomMessages(roomId);
  } catch (_) {
    window.alert(i18next.t("操作が完了できませんでした。"));
  }
  dispatch(
    appStateMutate((state) => {
      state.loading = false;
    })
  );
};

export const loadRoomMessages =
  (): DefaultThunk<Promise<number | null>> =>
  async (dispatch, getState): Promise<number | null> => {
    const state = getState();
    const roomId = state.app.state.roomId;
    const channel = state.app.state.roomChatTab;
    const isLoading = state.app.state.roomChatChannelLoading[channel];
    const isLoaded = state.app.state.roomChatChannelLoaded[channel] ?? false;
    if (!roomId || isLoading || isLoaded || !channel) return null;

    const lastMessage = getFirstRoomMessage(state);
    const lastTime = lastMessage?.createdAt ?? Timestamp.now();

    dispatch(
      appStateMutate((state) => {
        state.roomChatChannelLoading[channel] = true;
      })
    );

    return getDocs(
      query(
        messagesRef(roomId),
        where("channel", "==", channel),
        orderBy("createdAt", "desc"),
        startAfter(lastTime),
        limit(20)
      )
    )
      .then((snapshot) => {
        const addActions = snapshot.docs.map((doc) => {
          return actions.add({
            id: doc.id,
            entity: {
              ...(doc.data() as Message),
              _id: doc.id,
              removed: true,
            },
            reversed: true,
          });
        });

        for (const action of addActions) {
          dispatch(action);
        }

        if (snapshot.size < 20) {
          dispatch(
            appStateMutate((state) => {
              state.roomChatChannelLoaded[channel] = true;
            })
          );
        }

        return snapshot.size;
      })
      .finally(() => {
        dispatch(
          appStateMutate((state) => {
            state.roomChatChannelLoading[channel] = false;
          })
        );
      });
  };

export const DEFAULT_TEXT_COLOR_CODE = "#888888";

export const addDummyMessages = (): DefaultThunk => async (_, getState) => {
  const state = getState();
  const roomId = getAppState(state, "roomId");
  const from = getAppState(state, "uid");

  if (!roomId || !from) {
    return;
  }

  const batchId = shortid();
  const now = Date.now();
  const batch = writeBatch(db);
  for (let i = 0; i < 100; i++) {
    const id = shortid();
    const text = i.toString().repeat(Math.random() * 100 + 1) + ":" + batchId;
    const createdAt = Timestamp.fromMillis(now + i);
    batch.set(doc(messagesRef(roomId), id), {
      to: null,
      toName: "",
      from,
      name: "Dummy User",
      iconUrl: null,
      color: DEFAULT_TEXT_COLOR_CODE,
      imageUrl: null,
      text,
      type: "text",
      channel: "main",
      channelName: "main",
      extend: {},
      edited: false,
      updatedAt: createdAt,
      createdAt,
    });
  }

  batch.commit();
};

type SlashCommandHandler = (
  props: SlashCommandHandlerProps
) => Promise<boolean>;
type SlashCommandHandlerProps = {
  roomId: string;
  characterId: string | null;
  textData: TextData;
  inputText: string;
  startTime: number;
  isPro: boolean;
  isStandalone: boolean;
  dispatch: ThunkDispatch<DefaultRootState, unknown, any>;
  getState: () => DefaultRootState;
};

const getSlashCommandHandler = (
  inputText: string
): SlashCommandHandler | undefined => {
  const [prefix] = inputText.split(/\s/, 2);
  // [a-zA-Z0-9_] とハイフンのみを許可
  const matched = /^\/([\w-]+)$/.exec(prefix);
  if (matched == null) {
    return;
  }

  const commnad = matched[1];
  if (isRepeated(commnad)) {
    return nopHandler;
  }

  const handler = slashCommands[commnad];
  if (!!handler) {
    // handlerが存在する場合はrepeatを記録
    markRepeated(commnad);
  }
  return handler;
};

const WAIT_MS = 250;
const repeated: Record<string, boolean> = {};

const isRepeated = (commandName: string) => {
  return repeated[commandName];
};

const markRepeated = (commandName: string) => {
  repeated[commandName] = true;
  window.setTimeout(() => {
    repeated[commandName] = false;
  }, WAIT_MS);
};

const omikujiPool: string[] = [
  "大吉",
  "吉",
  "中吉",
  "小吉",
  "末吉",
  "凶",
  "大凶",
];

const getRandomInt = (n: number): number => {
  return Math.floor(Math.random() * n);
};

const omikujiHandler: SlashCommandHandler = async ({
  roomId,
  characterId,
  textData,
  dispatch,
  getState,
  startTime,
  isPro,
}) => {
  if (!isPro) {
    dispatch(
      appStateMutate(
        (state) =>
          (state.errorSnackbarMessage = i18next.t(
            "/omikuji コマンドは CCFOLIA PRO のみ利用可能な機能です。"
          ))
      )
    );
    return true;
  }

  const chosenIndex = getRandomInt(omikujiPool.length);

  const message: UpdateMessage = {
    text: i18next.t("今日のあなたの運勢は……？"),
    extend: {
      roll: {
        result: `\n【${omikujiPool[chosenIndex]}】`,
        dices: [],
        secret: false,
        success: false,
        failure: false,
        critical: false,
        fumble: false,
      },
    },
  };

  await sendMessage({
    message,
    type: "text",
    roomId,
    characterId,
    textData,
    getState,
    startTime,
  });

  return true;
};

const playHandler: SlashCommandHandler = async ({
  inputText,
  getState,
  isPro,
  dispatch,
}) => {
  const url = inputText.split(" ")[1];

  if (!url) {
    return false;
  }

  if (!isValidUrl(url) || !ReactPlayer.canPlay(url)) {
    dispatch(
      appStateMutate(
        (state) =>
          (state.errorSnackbarMessage = i18next.t("再生できないURLです。"))
      )
    );
    return true;
  }

  if (!(isPro || YouTubePlayer.canPlay(url))) {
    dispatch(
      appStateMutate(
        (state) =>
          (state.errorSnackbarMessage = i18next.t(
            "/play コマンドでの動画ファイルの再生は CCFOLIA PRO のみ利用可能な機能です。"
          ))
      )
    );
    return true;
  }

  if (!getHasEditableRole(getState())) {
    dispatch(
      appStateMutate(
        (state) =>
          (state.errorSnackbarMessage = i18next.t(
            "/play コマンドを実行できるのはルームマスターのみです。"
          ))
      )
    );
    return true;
  }

  dispatch(updateCurrentRoom({ video: { id: shortid.generate(), url } }));
  return true;
};

const pdfHandler: SlashCommandHandler = async ({
  inputText,
  dispatch,
  isStandalone,
}) => {
  const url = inputText.split(" ")[1];

  if (!url) {
    return false;
  }

  if (isStandalone) {
    dispatch(
      appStateMutate(
        (state) =>
          (state.errorSnackbarMessage = i18next.t(
            "/pdf コマンドは別窓表示では利用できません。"
          ))
      )
    );
    return true;
  }

  if (!isValidUrl(url)) {
    dispatch(
      appStateMutate(
        (state) =>
          (state.errorSnackbarMessage = i18next.t("表示できないURLです。"))
      )
    );
    return true;
  }

  dispatch(
    appStateMutate((state) => {
      const prevOpen = state.openRoomDisplays;
      state.openPDFViewer = true;
      state.openPDFViewerUrl = url;
      if (prevOpen !== "pdf") {
        state.prevOpenRoomDisplay = prevOpen;
      }
      state.openRoomDisplays = "pdf";
    })
  );
  return true;
};

const loadHandler: SlashCommandHandler = async ({
  inputText,
  getState,
  dispatch,
}) => {
  if (!getHasEditableRole(getState())) {
    dispatch(
      appStateMutate(
        (state) =>
          (state.errorSnackbarMessage = i18next.t(
            "/load コマンドを実行できるのはルームマスターのみです。"
          ))
      )
    );
    return true;
  }

  const [line] = inputText.split("\n", 1);
  const savedataName = line.slice(6).trim(); // trim "/save "

  const state = getState();
  const savedataId = getRoomSavedataIdByName(state, savedataName);
  if (savedataId == null) {
    dispatch(
      appStateMutate(
        (state) =>
          (state.errorSnackbarMessage = i18next.t(
            "指定されたセーブデータがありませんでした。\n[セーブデータ名] {{savedataName}}",
            { savedataName }
          ))
      )
    );
    return true;
  }

  dispatch(
    appStateMutate((state) => {
      state.openLoadConfirmId = savedataId;
      state.openLoadConfirm = true;
    })
  );
  return true;
};

const saveHandler: SlashCommandHandler = async ({
  inputText,
  getState,
  dispatch,
}) => {
  if (!getHasEditableRole(getState())) {
    dispatch(
      appStateMutate(
        (state) =>
          (state.errorSnackbarMessage = i18next.t(
            "/save コマンドを実行できるのはルームマスターのみです。"
          ))
      )
    );
    return true;
  }

  const [line] = inputText.split("\n", 1);
  const savedataName = line.slice(6).trim(); // trim "/save "

  const state = getState();
  const savedata = getRoomSavedataByName(state, savedataName);
  if (savedata == null) {
    dispatch(
      appStateMutate(
        (state) =>
          (state.errorSnackbarMessage = i18next.t(
            "指定されたセーブデータがありませんでした。\n[セーブデータ名] {{savedataName}}",
            { savedataName }
          ))
      )
    );
    return true;
  }

  dispatch(
    appStateMutate((state) => {
      state.openSaveConfirm = savedata.order;
      state.saveConformDisabledNameEdit = true;
    })
  );
  return true;
};

const resetHandler: SlashCommandHandler = async ({ getState, dispatch }) => {
  if (!getHasEditableRole(getState())) {
    dispatch(
      appStateMutate(
        (state) =>
          (state.errorSnackbarMessage = i18next.t(
            "/reset コマンドを実行できるのはルームマスターのみです。"
          ))
      )
    );
    return true;
  }

  const state = getState();
  const room = getCurrentRoom(state);
  if (room?.initialSavedata == null) {
    dispatch(
      appStateMutate(
        (state) =>
          (state.errorSnackbarMessage = i18next.t(
            "このルームでは /reset コマンドを実行できません。"
          ))
      )
    );
    return true;
  }

  if (!window.confirm(i18next.t("ルームをルーム作成時の状態に戻しますか？"))) {
    return true;
  }

  dispatch(loadRoomBySnapshotId(room.initialSavedata.snapshotId));
  return true;
};

type SendMessageProps = {
  message: UpdateMessage;
  type: MessageType;
  roomId: string;
  characterId: string | null;
  textData: TextData;
  startTime: number;
  getState: () => DefaultRootState;
};

const sendMessage = ({
  message,
  type,
  roomId,
  characterId,
  textData,
  getState,
  startTime,
}: SendMessageProps): Promise<any> => {
  const state = getState();
  const character = getCharacterById(state, characterId);
  const from = getAppState(state, "uid") || undefined;
  const to = textData.to || getAppState(state, "roomChatTo");
  const toName =
    textData.roomChatToName || getAppState(state, "roomChatToName");
  const name = textData.name || getAppState(state, "roomChatName");
  const iconUrl = textData.iconUrl || null;
  const imageUrl = textData.iconUrl || null;
  const color =
    textData.color || character.color || getAppState(state, "roomChatColor");
  const channel = textData.channel || getAppState(state, "roomChatTab");
  const channelName = textData.channelName || getChannelName(state, channel);

  const data: UpdateMessage = {
    to,
    toName,
    from,
    name,
    iconUrl,
    color,
    imageUrl,
    type,
    channel,
    channelName,
    extend: {},

    ...message,

    edited: false,
    updatedAt: serverTimestamp(),
    createdAt: serverTimestamp(),
  };

  return addDoc(messagesRef(roomId), data).then((res) => {
    sendEvent("addRoomMessage", { value: ~~(Date.now() - startTime) });
    return res;
  });
};

const bgmHandler: SlashCommandHandler = async ({
  inputText,
  dispatch,
  getState,
}) => {
  const [, url, ...args] = inputText.split(/\s+/);

  if (!isValidUrl(url)) {
    dispatch(
      appStateMutate(
        (state) =>
          (state.errorSnackbarMessage = i18next.t(
            "/bgm コマンドに有効でないURLが指定されました。"
          ))
      )
    );
    return true;
  }

  const state = getState();
  const enableGoCdn = state.app.state.config.enabledGoCdnOnAudio;

  if (!(await isPlayableAudioUrl(toCDNUrl(url, { enableGoCdn })))) {
    dispatch(
      appStateMutate(
        (state) =>
          (state.errorSnackbarMessage = i18next.t(
            "/bgm コマンドで指定されたURLが再生できないファイルでした。"
          ))
      )
    );
    return true;
  }

  const options = parseBgmOptions(args);

  if (
    !Number.isFinite(options.volume) ||
    options.volume > 1 ||
    options.volume < 0
  ) {
    dispatch(
      appStateMutate(
        (state) =>
          (state.errorSnackbarMessage = i18next.t(
            "/bgm コマンドで指定するボリュームの値は0以上・1以下としてください。"
          ))
      )
    );
    return true;
  }

  switch (options.slot) {
    case "bgm01":
      dispatch(
        updateCurrentRoomField({
          mediaUrl: url,
          mediaType: "file",
          mediaName: options.name,
          mediaVolume: options.volume,
          mediaRepeat: options.loop,
        })
      );
      break;
    case "bgm02":
      dispatch(
        updateCurrentRoomField({
          soundUrl: url,
          soundName: options.name,
          soundVolume: options.volume,
          soundRepeat: options.loop,
        })
      );
      break;
    default:
      dispatch(
        appStateMutate(
          (state) =>
            (state.errorSnackbarMessage = i18next.t(
              "/bgm コマンドで不明なスロットが指定されました。"
            ))
        )
      );
  }

  return true;
};

const bgmStopHandler: SlashCommandHandler = async ({ dispatch }) => {
  dispatch(
    updateCurrentRoomField({
      mediaName: "",
      mediaUrl: null,
      mediaRepeat: true,
      mediaType: "",
      mediaVolume: 0,
      soundName: "",
      soundUrl: null,
      soundRepeat: true,
      soundVolume: 0,
    })
  );

  return true;
};

type BgmOptions = {
  slot: string;
  name: string;
  volume: number;
  loop: boolean;
};

const parseBgmOptions = (args: string[]): BgmOptions => {
  const options: BgmOptions = {
    slot: "bgm01",
    name: "",
    volume: 1,
    loop: true,
  };

  for (const arg of args) {
    const a = arg.toLowerCase();
    if (a.startsWith("volume:")) {
      options.volume = Number.parseFloat(a.substring("volume:".length));
    } else if (a.startsWith("slot:")) {
      options.slot = a.substring("slot:".length);
    } else if (a.startsWith("name:")) {
      options.name = a.substring("name:".length);
    } else if (a === "loop:true") {
      options.loop = true;
    } else if (a === "loop:false") {
      options.loop = false;
    }
  }

  return options;
};

const passphrase = "d0_n0t_ev1";

type ExecArgs = {
  command: string;
  roomPackageId: string;
};

const isExecArgs = (data: any): data is ExecArgs => {
  return (
    typeof data.command === "string" && typeof data.roomPackageId === "string"
  );
};

const execHandler: SlashCommandHandler = async ({
  inputText,
  dispatch,
  getState,
}) => {
  const base64 = inputText.substring("/exec ".length);
  let args;

  try {
    const decrypted = AES.decrypt(base64, passphrase);
    const json = decrypted.toString(Utf8);
    args = JSON.parse(json);
  } catch {
    dispatch(
      appStateMutate(
        (state) =>
          (state.errorSnackbarMessage =
            i18next.t("コマンドの実行に失敗しました。"))
      )
    );
    return true;
  }

  const state = getState();
  const room = getCurrentRoom(state);
  const roomPackageId =
    room?.parentRoomPackageId || room?.publishedRoomPackageId;

  // ループ防止
  if (
    !isExecArgs(args) ||
    args.roomPackageId !== roomPackageId ||
    args.command.startsWith("/exec ")
  ) {
    dispatch(
      appStateMutate(
        (state) =>
          (state.errorSnackbarMessage =
            i18next.t("コマンドの実行に失敗しました。"))
      )
    );
    return true;
  }

  dispatch(addMessageCurrentRoom(args.command, false));
  return true;
};

const sceneHandler: SlashCommandHandler = async ({
  roomId,
  inputText,
  dispatch,
  getState,
  isPro,
}) => {
  const sceneName = inputText.substring("/scene ".length);

  if (!sceneName) {
    return false;
  }

  if (!getHasEditableRole(getState())) {
    dispatch(
      appStateMutate(
        (state) =>
          (state.errorSnackbarMessage = i18next.t(
            "/scene コマンドを実行できるのはルームマスターのみです。"
          ))
      )
    );
    return true;
  }

  const state = getState();
  const sceneId = getRoomSceneIdByName(state, sceneName);
  if (!sceneId) {
    dispatch(
      appStateMutate(
        (state) =>
          (state.errorSnackbarMessage = i18next.t(
            "指定されたシーンがありませんでした。\n[シーン名] {{sceneName}}",
            { sceneName }
          ))
      )
    );
    return true;
  }

  dispatch(applyScene(roomId, sceneId));
  dispatch(addUndoSceneChange(sceneId));
  return true;
};

const sceneWithoutBgmHandler: SlashCommandHandler = async ({
  roomId,
  inputText,
  dispatch,
  getState,
  isPro,
}) => {
  const sceneName = inputText.substring("/scene-without-bgm ".length);

  if (!sceneName) {
    return false;
  }

  if (!getHasEditableRole(getState())) {
    dispatch(
      appStateMutate(
        (state) =>
          (state.errorSnackbarMessage = i18next.t(
            "/scene コマンドを実行できるのはルームマスターのみです。"
          ))
      )
    );
    return true;
  }

  const state = getState();
  const sceneId = getRoomSceneIdByName(state, sceneName);
  if (!sceneId) {
    dispatch(
      appStateMutate(
        (state) =>
          (state.errorSnackbarMessage = i18next.t(
            "指定されたシーンがありませんでした。\n[シーン名] {{sceneName}}",
            { sceneName }
          ))
      )
    );
    return true;
  }

  dispatch(applyScene(roomId, sceneId, { withoutBgm: true }));
  dispatch(addUndoSceneChange(sceneId, true));
  return true;
};

const varHandler: SlashCommandHandler = async ({
  inputText,
  roomId,
  dispatch,
  getState,
}) => {
  const [, label, value] = inputText.split(" ", 3);
  if (!label) {
    return false;
  }

  if (!getHasEditableRole(getState())) {
    dispatch(
      appStateMutate(
        (state) =>
          (state.errorSnackbarMessage = i18next.t(
            "/var コマンドを実行できるのはルームマスターのみです。"
          ))
      )
    );
    return true;
  }

  const isOverflowLabel = label.length > 16;
  const isOverflowValue = value && value.length > 64;

  if (isOverflowLabel || isOverflowValue) {
    const errorMessage =
      (isOverflowLabel ? i18next.t("ラベルは16文字以下としてください。") : "") +
      (isOverflowValue ? i18next.t("値は64文字以下としてください。") : "");
    dispatch(
      appStateMutate((state) => (state.errorSnackbarMessage = errorMessage))
    );
    return true;
  }

  const state = getState();
  const room = getCurrentRoom(state);
  if (!room) {
    return true;
  }

  const variables = room.variables;
  const index = room.variables.findIndex((v) => v.label === label);
  if (index >= 0) {
    const prevValue = variables[index].value;
    dispatch(
      updateCurrentRoom({
        variables: produce(variables, (draft) => {
          if (value) {
            draft[index].value = value;
          } else {
            draft.splice(index, 1);
          }
        }),
      })
    );

    const systemMessage = value
      ? `/system [ room ] ${label} : ${prevValue} → ${value}`
      : `/system [ room ] ${label} : Deleted`;
    dispatch(
      addMessage(
        roomId,
        null,
        {
          text: systemMessage,
          name: "system",
          channel: "main",
        },
        false
      )
    );
  } else if (variables.length >= 16) {
    dispatch(
      appStateMutate(
        (state) =>
          (state.errorSnackbarMessage = i18next.t(
            "上限に達しているため、新しくルーム変数を登録できませんでした。ルーム変数は最大16個です。"
          ))
      )
    );
    return true;
  } else if (value) {
    dispatch(
      updateCurrentRoom({ variables: [...variables, { label, value }] })
    );
    dispatch(
      addMessage(
        roomId,
        null,
        {
          text: `/system [ room ] ${label} : ${value}`,
          name: "system",
          channel: "main",
        },
        false
      )
    );
  } else {
    dispatch(
      appStateMutate(
        (state) =>
          (state.errorSnackbarMessage = i18next.t(
            "指定されたラベルの変数がありませんでした。\n[ラベル] {{label}}",
            { label }
          ))
      )
    );
  }
  return true;
};

const nopHandler: SlashCommandHandler = async () => {
  return true;
};

const linkHandler: SlashCommandHandler = async ({ inputText, dispatch }) => {
  const url = inputText.split(" ")[1];

  if (!url) {
    return false;
  }

  if (!isValidUrl(url)) {
    dispatch(
      appStateMutate(
        (state) =>
          (state.errorSnackbarMessage =
            i18next.t("開くことができないURLです。"))
      )
    );
    return true;
  }

  const checkUrl = new URL(url);
  if (!ORIGIN_WHITE_LIST.includes(checkUrl.origin)) {
    dispatch(
      appStateMutate(
        (state) =>
          (state.errorSnackbarMessage =
            i18next.t("許可されていないドメインです。"))
      )
    );
    return true;
  }

  if (
    window.confirm(
      i18next.t("下記 URL を開こうとしてます。本当によろしいですか？\n") + url
    )
  ) {
    window.open(url, "_blank");
  }
  return true;
};

const slashCommands: Record<string, SlashCommandHandler> = {
  bgm: bgmHandler,
  "bgm-stop": bgmStopHandler,
  exec: execHandler,
  omikuji: omikujiHandler,
  play: playHandler,
  pdf: pdfHandler,
  scene: sceneHandler,
  "scene-without-bgm": sceneWithoutBgmHandler,
  save: saveHandler,
  load: loadHandler,
  reset: resetHandler,
  var: varHandler,
  link: linkHandler,
};
