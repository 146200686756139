import {
  Box,
  Breadcrumbs,
  CircularProgress,
  Container,
  Grid,
  Link,
  Typography,
} from "@mui/material";
import { memo, useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  Link as RouterLink,
  useHistory,
  useLocation,
  useParams,
} from "react-router-dom";
import ThumbnailViewer from "./ThumbnailViewer";
import RelatedProductCard from "./RelatedProductCard";
import DetailList from "./DetailList";
import DetailListItem from "./DetailListItem";
import Footer from "containers/Footer";
import ProductSummary from "./ProductSummary";
import ShareButtons from "./ShareButtons";
import useSWR, { useSWRConfig } from "swr";
import {
  createFreeProductPurchase,
  createProductCheckoutUrl,
  getGamesPurchasedProductIds,
  getProductById,
  Product as ProductType,
  syncPurchaseState,
} from "api";
import BuyButton from "./BuyButton";
import PurchasedDialog from "./PurchasedDialog";
import { useAppDispatch, useAppSelector } from "stores";
import { getAuthedUid, getUid } from "stores/modules/app.user/selectors";
import LoginDialog from "./LoginDialog";
import { useModal } from "hooks/useModal";
import {
  clearActionAfterLogin,
  getActionAfterLogin,
  setActionBuyProduct,
  setActionGetFreeProduct,
} from "./actionAfterLogin";
import CreateRoomFromProductDialog from "containers/CreateRoomFromProductDialog";
import LoadingDialog from "containers/LoadingDialog/LoadingDialog";
import { useDocumentTitle } from "hooks/useDocumentTitle";
import { useMetaTagDescription } from "hooks/useMetaTagDescription";
import { sendViewProductPageEvent } from "stores/modules/app.state/operations";
import { RefererData, setRefererInternal } from "modules/referer";
import { useReferer } from "hooks/referer";
import Linkify from "linkify-react";
import { IntermediateRepresentation } from "linkifyjs";

const Product = () => {
  const [t] = useTranslation();
  const {
    open: openLoginDialog,
    onOpen: onOpenModal,
    onClose: onCloseModal,
  } = useModal();
  const createRoomModal = useModal();

  const { productId } = useParams<{
    productId: string;
  }>();
  const {
    data: product,
    isLoading,
    error,
  } = useSWR(["getProductById", productId], ([_, gameId]) =>
    getProductById(gameId)
  );

  const { syncingPurchase, openPurchased, onOpenPurchased, onClosePurchased } =
    useSyncPurchase(productId);

  const { isLoadingCheckoutUrl } = useActionAfterLogin(
    productId,
    onOpenPurchased
  );

  const onBeforeSnsLogin = useCallback(() => {
    if (!productId) {
      return;
    }

    if (product?.price === 0) {
      setActionGetFreeProduct(productId);
    } else {
      setActionBuyProduct(productId);
    }
  }, [productId, product?.price]);

  const onClickBreadcrumbsParentProduct = useCallback(() => {
    const parentProductId = product?.parentProduct?.id;
    if (parentProductId) {
      setRefererInternal({
        productId: parentProductId,
        referer: "product-page-breadcrumbs-parent-product",
      });
    }
  }, [product?.parentProduct?.id]);

  // reset scroll to top
  useEffect(() => {
    setTimeout(() => {
      window.scroll({ top: 0, left: 0, behavior: "auto" });
    }, 0);
  }, [productId]);

  useDocumentTitle(product?.name ? `${product.name} | CCFOLIA` : null);
  useMetaTagDescription(product?.description);

  const referer = useReferer(productId);
  useAnalyticsProduct({ productId, referer });

  const hasAvailableExtention = useHasAvailableExtention(
    product?.relatedProducts
  );

  if (isLoading) {
    return (
      <Box display="flex" justifyContent="center" marginTop="64px">
        <CircularProgress />
      </Box>
    );
  }

  if (error || !product) {
    return (
      <Box marginTop="64px">
        <Typography color="textPrimary" variant="body1" textAlign="center">
          {t("お探しの商品は見つかりませんでした。")}
        </Typography>
      </Box>
    );
  }

  return (
    <>
      <Container style={{ marginTop: "40px" }}>
        <Breadcrumbs separator="＞" aria-label="breadcrumb">
          <Link
            underline="hover"
            color="inherit"
            component={RouterLink}
            to="/games"
          >
            {t("ゲームストア")}
          </Link>
          {product.parentProduct && (
            <Link
              underline="hover"
              color="inherit"
              component={RouterLink}
              to={`/games/${product.parentProduct.id}`}
              onClick={onClickBreadcrumbsParentProduct}
            >
              {product.parentProduct.name}
            </Link>
          )}
          <Typography color="inherit">{product.name}</Typography>
        </Breadcrumbs>
        <Typography
          variant="h5"
          component="h1"
          color="textPrimary"
          marginTop="8px"
          marginBottom="24px"
          fontWeight="normal"
        >
          {product.name}
        </Typography>
        <Grid container spacing={4}>
          <Grid item md={8} xs={12}>
            <ThumbnailViewer key={product.id} visuals={product.carouselItems} />
            <Box marginTop="16px" sx={{ display: { md: "none", xs: "block" } }}>
              <ProductSummary
                category={product.category}
                players={product.players}
                playTimes={product.playTimes}
                developer={product.developer}
                kind={product.kind}
                price={product.price}
              />
              <BuyButton
                productId={product.id}
                roomPackageId={product.roomPackageId}
                parentProductId={product.parentProduct?.id}
                hasAvailableExtention={hasAvailableExtention}
                externalUrl={product.externalShopUrl}
                isFree={product.price === 0}
                referer={referer}
                onRequestCreateRoom={createRoomModal.onOpen}
                onRequestLogin={onOpenModal}
                onOpenPurchased={onOpenPurchased}
                notAvailable={product.notAvailable}
              />
              <ShareButtons gameName={product.name} />
            </Box>
            <Typography
              paragraph
              color="textPrimary"
              whiteSpace="pre-wrap"
              marginTop="40px"
              marginBottom="32px"
            >
              <Linkify options={{ render: renderLink }}>
                {product.details}
              </Linkify>
            </Typography>
            <DetailList>
              <DetailListItem title={t("ジャンル")} text={product.category} />
              <DetailListItem title={t("プレイ人数")} text={product.players} />
              <DetailListItem
                title={t("プレイ時間")}
                text={product.playTimes}
              />
              <DetailListItem title={t("内容物")} text={product.contents} />
              <DetailListItem
                title={t("リリース日")}
                text={product.releasedAt}
              />
              <DetailListItem title={t("開発元")} text={product.developer} />
              {product.officialSite && (
                <DetailListItem
                  title={t("公式サイト")}
                  text={product.officialSite.name}
                  href={product.officialSite.url}
                />
              )}
            </DetailList>
            <Typography
              color="textSecondary"
              fontSize="14px"
              marginBottom="8px"
              marginTop="24px"
              paragraph
            >
              {t(
                "※本作品の購入により提供される全てのルームデータに係る著作権その他一切の権利は、ココフォリア株式会社または正当な権利を有する第三者に帰属します。"
              )}
            </Typography>
            <Typography
              color="textSecondary"
              fontSize="14px"
              marginBottom="8px"
              paragraph
            >
              {t(
                "※営利・非営利にかかわらず、ダウンロードデータ、ルームデータまたはそれに接続するURLの再配布、転送、転載、複製、改ざん等を固く禁じます。"
              )}
            </Typography>
            <Typography
              color="textSecondary"
              fontSize="14px"
              marginBottom="8px"
              paragraph
            >
              {t(
                "※そのような行為は法律により罰せられる場合があり、万が一、そのような行為を発見した場合には法的措置をとる場合がございます。"
              )}
            </Typography>
            {product.parentProduct && (
              <>
                <Typography
                  variant="h5"
                  component="h2"
                  color="textPrimary"
                  marginTop="40px"
                  marginBottom="24px"
                  fontWeight="normal"
                >
                  {t("ゲーム本体")}
                </Typography>
                <RelatedProductCard
                  productId={product.parentProduct.id}
                  name={product.parentProduct.name}
                  thumbnailUrl={product.parentProduct.thumbnailUrl}
                  description={product.parentProduct.description}
                  referer="product-page-parent-card"
                />
              </>
            )}
            {product.relatedProducts.length > 0 && (
              <Typography
                variant="h5"
                component="h2"
                color="textPrimary"
                marginTop="40px"
                marginBottom="24px"
                fontSize="20px"
                fontWeight="normal"
              >
                {t("このゲームの追加・拡張用コンテンツ")}
              </Typography>
            )}
            {product.relatedProducts.map((related) => (
              <RelatedProductCard
                productId={related.id}
                name={related.name}
                thumbnailUrl={related.thumbnailUrl}
                description={related.description}
                referer="product-page-extentions-card"
              />
            ))}
            {product.relatedItems.length > 0 && (
              <Typography
                variant="h5"
                component="h2"
                color="textPrimary"
                marginTop="40px"
                marginBottom="24px"
                fontSize="20px"
                fontWeight="normal"
              >
                {t("関連作品")}
              </Typography>
            )}
            {product.relatedItems.map((related) => (
              <RelatedProductCard
                productId={related.id}
                name={related.name}
                thumbnailUrl={related.thumbnailUrl}
                description={related.description}
                referer="product-page-related-items-card"
              />
            ))}
          </Grid>
          <Grid
            item
            md={4}
            xs={12}
            sx={{ display: { xs: "none", md: "initial" } }}
          >
            <ProductSummary
              category={product.category}
              players={product.players}
              playTimes={product.playTimes}
              developer={product.developer}
              kind={product.kind}
              price={product.price}
            />
            <BuyButton
              productId={product.id}
              roomPackageId={product.roomPackageId}
              parentProductId={product.parentProduct?.id}
              externalUrl={product.externalShopUrl}
              hasAvailableExtention={hasAvailableExtention}
              isFree={product.price === 0}
              referer={referer}
              onRequestCreateRoom={createRoomModal.onOpen}
              onRequestLogin={onOpenModal}
              onOpenPurchased={onOpenPurchased}
              notAvailable={product.notAvailable}
            />
            <ShareButtons gameName={product.name} />
          </Grid>
        </Grid>
      </Container>
      <Footer />
      <PurchasedDialog
        open={openPurchased}
        onClose={onClosePurchased}
        onRequestCreateRoom={createRoomModal.onOpen}
        name={product.name}
        thumbnailUrl={product.thumbnailUrl}
        productId={productId}
        roomPackageId={product.roomPackageId || ""}
        parentProductId={product.parentProduct?.id}
        isFree={product.price === 0}
      />
      {(product.parentProduct || hasAvailableExtention) && (
        <CreateRoomFromProductDialog
          open={createRoomModal.open}
          productId={product.parentProduct?.id || product.id}
          defaultChecked={product.id}
          onClose={createRoomModal.onClose}
        />
      )}
      <LoginDialog
        open={openLoginDialog}
        onClose={onCloseModal}
        onBeforeSnsLogin={onBeforeSnsLogin}
      />
      <LoadingDialog
        open={syncingPurchase}
        text={t("購入情報を読み込んでいます")}
      />
      <LoadingDialog
        open={isLoadingCheckoutUrl}
        text={t("決済ページを取得しています")}
      />
    </>
  );
};

const renderLink = ({ attributes, content }: IntermediateRepresentation) => {
  return (
    <Link style={{ wordBreak: "break-all" }} {...attributes}>
      {content}
    </Link>
  );
};

const useSyncPurchase = (productId: string) => {
  const [t] = useTranslation();
  const location = useLocation();
  const history = useHistory();
  const { mutate } = useSWRConfig();
  const [syncingPurchase, setSyncingPurchase] = useState(false);
  const [openPurchased, setOpenPurchased] = useState(false);

  const uid = useAppSelector(getAuthedUid);

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    if (searchParams.get("success_checkout") !== "true" || !uid || !productId) {
      return;
    }

    setSyncingPurchase(true);
    syncPurchaseState(productId)
      .then(({ purchased }) => {
        if (!purchased) {
          throw new Error();
        }

        history.replace(window.location.pathname);
        setSyncingPurchase(false);
        setOpenPurchased(true);
        mutate(["getGamesPurchase", productId, uid], true);
      })
      .catch(() => {
        setSyncingPurchase(false);
        if (
          window.confirm(
            t(
              "購入情報の同期に失敗しました。ページをリロードして再度同期しますか？"
            )
          )
        ) {
          window.location.reload();
        }
      });
  }, [
    location.search,
    history,
    uid,
    productId,
    mutate,
    setSyncingPurchase,
    setOpenPurchased,
    t,
  ]);

  const onOpenPurchased = useCallback(() => {
    setOpenPurchased(true);
  }, [setOpenPurchased]);

  const onClosePurchased = useCallback(() => {
    setOpenPurchased(false);
  }, [setOpenPurchased]);

  return { syncingPurchase, openPurchased, onOpenPurchased, onClosePurchased };
};

const useActionAfterLogin = (
  productId: string,
  onOpenPurchased: () => void
) => {
  const [t] = useTranslation();
  const { mutate } = useSWRConfig();
  const uid = useAppSelector(getAuthedUid);
  const [loading, setLoading] = useState(false);

  const agreedTermsAt = useAppSelector(
    (state) => state.entities.userSetting.agreedTermsAt
  );

  const buyProduct = useCallback(
    (productId: string) => {
      setLoading(true);
      createProductCheckoutUrl(productId)
        .then(({ url }) => {
          window.location.href = url;
        })
        .catch(() => {
          setLoading(false);
          window.alert(t("決済ページの取得に失敗しました"));
        });
    },
    [setLoading, t]
  );

  const getFreeProduct = useCallback(
    (productId: string, uid: string) => {
      setLoading(true);
      createFreeProductPurchase(productId)
        .then(({ purchased }) => {
          setLoading(false);
          if (purchased) {
            mutate(["getGamesPurchase", productId, uid], true);
            onOpenPurchased();
          }
        })
        .catch(() => {
          setLoading(false);
          window.alert(t("処理に失敗しました"));
        });
    },
    [setLoading, onOpenPurchased, mutate, t]
  );

  useEffect(() => {
    if (!uid || !agreedTermsAt) {
      return;
    }

    const action = getActionAfterLogin();
    clearActionAfterLogin();
    if (action?.productId !== productId) {
      return;
    }

    if (action.kind === "buyProduct") {
      buyProduct(productId);
    } else if (action.kind === "getFreeProduct") {
      getFreeProduct(productId, uid);
    }
  }, [uid, productId, agreedTermsAt, buyProduct, getFreeProduct]);

  return { isLoadingCheckoutUrl: loading };
};

type UseAnalyticsProductProps = {
  productId: string;
  referer: RefererData;
};

const useAnalyticsProduct = ({
  productId,
  referer,
}: UseAnalyticsProductProps) => {
  const dispatch = useAppDispatch();

  const uid = useAppSelector(getUid);

  useEffect(() => {
    if (!uid) {
      return;
    }

    dispatch(
      sendViewProductPageEvent({
        productId,
        referer: referer.referer,
        refererKind: referer.refererKind,
      })
    );
  }, [uid, productId, referer.referer, referer.refererKind, dispatch]);
};

const useHasAvailableExtention = (relatedProducts?: ProductType[]) => {
  const uid = useAppSelector(getAuthedUid);
  const { data: purchasedProductIds } = useSWR(
    uid ? ["getGamesPurchasedProductIds", uid] : null,
    getGamesPurchasedProductIds
  );

  const hasAvailableExtention = useMemo(
    () =>
      relatedProducts?.some((relatedProduct) =>
        purchasedProductIds?.includes(relatedProduct.id)
      ) ?? false,
    [relatedProducts, purchasedProductIds]
  );

  return hasAvailableExtention;
};

export default memo(Product);
