import { memo, useCallback, useEffect } from "react";
import { useAppDispatch, useAppSelector } from "stores";
import store from "stores/interfaces";
import {
  AppBar,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  FormControl,
  FormControlLabel,
  FormLabel,
  IconButton,
  List,
  Radio,
  RadioGroup,
  TextField,
  Toolbar,
  Typography,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { useTranslation } from "react-i18next";
import { Controller, useFieldArray, useForm } from "react-hook-form";
import { getAuthedUid } from "stores/modules/app.user/selectors";
import shortid from "shortid";
import { MessageGroup } from "stores/modules/entities.rooms/records";
import { addRoomMessageGroup } from "stores/modules/entities.rooms/operations";
import theme from "theme";
import ChatTabCheckboxMember from "containers/ChatTabCheckboxMember";

type FormData = {
  name: string;
  kind: "public" | "private";
  members: { id: string; checked: boolean }[];
};

const ChatTabFormDialog = () => {
  const [t] = useTranslation();
  const dispatch = useAppDispatch();

  const { register, handleSubmit, control, reset, getValues, watch } =
    useForm<FormData>({
      defaultValues: { name: "", kind: "public", members: [] },
    });

  const { fields } = useFieldArray({ control, name: "members" });

  const open = useAppSelector((state) =>
    store.getAppState(state, "openRoomMessageChannel")
  );
  const hasEditableRole = useAppSelector(store.getHasEditableRole);

  const onClose = useCallback(() => {
    dispatch(
      store.appStateMutate((state) => {
        state.openRoomMessageChannel = false;
      })
    );
    reset();
  }, [dispatch, reset]);

  useEffect(() => {
    if (!hasEditableRole) {
      onClose();
    }
  }, [hasEditableRole, onClose]);

  const onSubmit = useCallback(
    (values: FormData) => {
      if (!values.name) {
        window.alert(t("タブ名を入力してください"));
        return;
      }

      const id = shortid();
      const data: MessageGroup =
        values.kind === "public"
          ? { kind: "public", id, name: values.name }
          : {
              kind: "private",
              id,
              name: values.name,
              uids: values.members
                .filter((member) => member.checked)
                .map((member) => member.id),
            };
      dispatch(addRoomMessageGroup(data));
      onClose();
    },
    [onClose, dispatch, t]
  );

  const memberIds = useAppSelector(store.getRoomMemberIds);
  const authedUid = useAppSelector(getAuthedUid);

  useEffect(() => {
    if (memberIds.length > 0) {
      reset({
        ...getValues(),
        members: memberIds.map((id) => {
          return { id, checked: id === authedUid };
        }),
      });
    }
  }, [memberIds, authedUid, reset, getValues]);

  const visibility = watch("kind");

  return (
    <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
      <AppBar color="default" position="sticky">
        <Toolbar>
          <Typography style={{ flex: 1 }}>{t("チャットタブを作成")}</Typography>
          <IconButton edge="end" onClick={onClose} size="large">
            <CloseIcon />
          </IconButton>
        </Toolbar>
      </AppBar>
      <DialogContent>
        <form onSubmit={handleSubmit(onSubmit)}>
          <TextField
            variant="filled"
            label={t("チャットタブ名")}
            fullWidth
            autoFocus
            required
            {...register("name")}
          />
          <FormControl sx={{ marginTop: "32px" }}>
            <FormLabel id="new-channel-dialog-kind-label" focused={false}>
              {t("チャットタブの種類")}
            </FormLabel>
            <Controller
              name="kind"
              control={control}
              render={({ field: { onChange, value, name } }) => (
                <RadioGroup
                  aria-labelledby="new-channel-dialog-kind-label"
                  defaultValue="public"
                  name={name}
                  onChange={onChange}
                  value={value}
                >
                  <FormControlLabel
                    value="public"
                    control={<Radio />}
                    label={t("全体公開")}
                  />
                  <FormControlLabel
                    value="private"
                    control={<Radio />}
                    label={t("プライベート")}
                  />
                </RadioGroup>
              )}
            />
          </FormControl>
          <Typography
            variant="caption"
            paragraph
            color={theme.palette.text.secondary}
            marginTop="4px"
            marginBottom="16px"
          >
            ※{" "}
            {t(
              "「プライベート」設定は厳密に通信の保護を提供するものではなく、チャットタブの表示状態を変更するものです。"
            )}
            <br />※{" "}
            {t(
              "「プライベート」のメンバーになれるのはログインユーザーのみです。"
            )}
          </Typography>
          <Box mt="24px" display={visibility === "public" ? "none" : undefined}>
            <Typography variant="body1" color="text.secondary" marginBottom="0">
              {t("メンバー")}
            </Typography>
            <List sx={{ paddingTop: "4px" }}>
              {fields.map((field, index) => (
                <ChatTabCheckboxMember
                  key={field.id}
                  memberId={memberIds[index]}
                  field={field}
                  index={index}
                  disabled={visibility === "public"}
                  control={control}
                />
              ))}
            </List>
          </Box>
        </form>
      </DialogContent>
      <DialogActions>
        <Button fullWidth onClick={onClose} color="secondary">
          {t("キャンセル")}
        </Button>
        <Button fullWidth onClick={handleSubmit(onSubmit)} color="primary">
          {t("タブを作成")}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default memo(ChatTabFormDialog);
