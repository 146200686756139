import React, { useState, memo, useEffect, useRef, useCallback } from "react";
import { shallowEqual } from "react-redux";
import { useAppSelector } from "stores";
import store from "stores/interfaces";
import { List, ListItem, ListItemText, DialogContent } from "@mui/material";

import MediumListItem from "../MediumListItem";
import MediumListItemMenu from "../MediumListItemMenu";

import { useTranslation } from "react-i18next";
import {
  SortableContext,
  verticalListSortingStrategy,
} from "@dnd-kit/sortable";
import SortableItem from "containers/SortableItem";
import { useDndContext } from "@dnd-kit/core";
import styled from "styled-components";

type CurrentMedium = {
  mediumId?: string;
  anchorEl?: Element;
};

const MediumList = () => {
  const [t] = useTranslation();
  const [current, setCurrent] = useState<CurrentMedium>({});
  const [uid, mediumIds] = useAppSelector((state) => {
    return [
      store.getAppState(state, "uid"),
      store.getCurrentUserMediumIds(state),
    ] as const;
  }, shallowEqual);
  const { over, active } = useDndContext();
  const [hiddenId, setHiddenId] = useState(active?.id.toString() ?? null);
  const lastOverContainerId = useRef<string | undefined>(
    over?.data.current?.sortable.containerId
  );

  const onCloseMenu = useCallback(() => {
    setCurrent({});
  }, []);

  useEffect(() => {
    if (over?.data.current?.sortable.containerId) {
      lastOverContainerId.current = over.data.current.sortable.containerId;
    }
  }, [over]);

  useEffect(() => {
    if (active) {
      setHiddenId(active.id.toString());
    } else {
      if (lastOverContainerId.current === "bgm-tab") {
        // ちらつき防止のためタブ上でドラッグが終了した場合は表示まで少し間を置く
        setTimeout(() => {
          setHiddenId(null);
        }, 100);
      } else {
        setHiddenId(null);
      }
    }
  }, [active]);

  return (
    <MediumDialogContent>
      <MediumListItemMenu
        key={current.mediumId}
        open={current.mediumId != null}
        uid={uid}
        mediumId={current.mediumId}
        anchorEl={current.anchorEl}
        onClose={onCloseMenu}
      />
      <MediumDialogList component="div">
        {mediumIds.length < 1 ? (
          <ListItem>
            <ListItemText
              primary={t(
                "ドラッグアンドドロップでファイルを追加することができます。"
              )}
              primaryTypographyProps={{ variant: "body2" }}
            />
          </ListItem>
        ) : null}
        <SortableContext
          items={mediumIds}
          strategy={verticalListSortingStrategy}
          id="bgm-list"
        >
          {mediumIds.map((mediumId) => (
            <div
              key={mediumId}
              style={{
                opacity: mediumId === hiddenId ? 0 : 1, // ドラッグ中のアイテムを透明にする
              }}
            >
              <SortableItem id={mediumId}>
                <MediumListItem
                  key={mediumId}
                  mediumId={mediumId}
                  onEdit={setCurrent}
                />
              </SortableItem>
            </div>
          ))}
        </SortableContext>
      </MediumDialogList>
    </MediumDialogContent>
  );
};

const MediumDialogContent = styled(DialogContent)`
  padding: 0;
  flex: 1;
`;

const MediumDialogList = styled(List)`
  overflow: auto;
  padding-bottom: 64px;
`;

export default memo(MediumList);
