import {
  Avatar,
  CircularProgress,
  ListItemAvatar,
  ListItemButton,
  ListItemText,
} from "@mui/material";
import AudiotrackIcon from "@mui/icons-material/Audiotrack";
import DescriptionOutlinedIcon from "@mui/icons-material/DescriptionOutlined";
import FolderZipOutlinedIcon from "@mui/icons-material/FolderZipOutlined";
import PhotoSizeSelectActualOutlinedIcon from "@mui/icons-material/PhotoSizeSelectActualOutlined";
import SaveAltIcon from "@mui/icons-material/SaveAlt";
import { memo, useCallback, useMemo, useRef, useState } from "react";
import { getDownloadAttachmentUrl } from "api";
import { useTranslation } from "react-i18next";
import { format } from "date-fns";

type ListItemAttachmentProps = {
  filename: string;
  size: string;
  mime: string;
  updatedAt: string;
  productId: string;
};

/* eslint-disable jsx-a11y/anchor-has-content, jsx-a11y/anchor-is-valid */
const ListItemAttachment = ({
  filename,
  size,
  mime,
  updatedAt,
  productId,
}: ListItemAttachmentProps) => {
  const [t] = useTranslation();

  const [loading, setLoading] = useState(false);
  const refAnchor = useRef<HTMLAnchorElement>(null);
  const requestDownloadUrl = useCallback(() => {
    setLoading(true);

    getDownloadAttachmentUrl(productId, filename)
      .then(({ url }) => {
        if (refAnchor.current == null) {
          window.alert(t("ダウンロード処理が失敗しました。"));
          return;
        }

        refAnchor.current.href = url;
        refAnchor.current.click();
      })
      .catch(() => {
        window.alert(t("ダウンロードURLの取得に失敗しました。"));
      })
      .finally(() => {
        setLoading(false);
      });
  }, [productId, filename, t]);

  const formatedUpdatedAt = useMemo(
    () => format(new Date(updatedAt), "yyyy/MM/dd"),
    [updatedAt]
  );

  // iOS SafariではPDFがそのまま開いてしまうが、 target="_blank" では動作しなくなる。
  return (
    <>
      <ListItemButton onClick={requestDownloadUrl} disabled={loading}>
        <ListItemAvatar>
          <Avatar>
            <Icon mime={mime} />
          </Avatar>
        </ListItemAvatar>
        <ListItemText
          primary={filename}
          secondary={t("{{size}}　最終更新：{{updatedAt}}", {
            size,
            updatedAt: formatedUpdatedAt,
          })}
          primaryTypographyProps={{ color: "text.primary" }}
        />
        {loading ? (
          <CircularProgress size={24} />
        ) : (
          <SaveAltIcon color="action" />
        )}
      </ListItemButton>
      <a ref={refAnchor} download={filename} style={{ display: "none" }} />
    </>
  );
};
/* eslint-enable */

type IconProps = {
  mime: string;
};

const Icon = ({ mime }: IconProps) => {
  if (mime === "application/zip") {
    return <FolderZipOutlinedIcon />;
  } else if (mime.startsWith("image/")) {
    return <PhotoSizeSelectActualOutlinedIcon />;
  } else if (mime.startsWith("audio/")) {
    return <AudiotrackIcon />;
  }

  return <DescriptionOutlinedIcon />;
};

export default memo(ListItemAttachment);
