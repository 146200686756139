import { useCallback, useState, useRef, useEffect, memo } from "react";
import { useAppDispatch, useAppSelector } from "stores";
import styled from "styled-components";
import store from "stores/interfaces";
import {
  Dialog,
  TextField,
  DialogContent,
  DialogActions,
  Button,
  ButtonBase,
  Slider,
  Typography,
} from "@mui/material";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import toCDNUrl from "modules/toCDNUrl";
import { Effect } from "stores/modules/entities.room.effects";
import { addUndo } from "stores/modules/entities.room.histories/slice";

const style = { background: "rgba(0, 0, 0, 0.2)" };

type EffectDetailProps = {
  roomId: string;
};

const EffectDetail = ({ roomId }: EffectDetailProps) => {
  const dispatch = useAppDispatch();
  const [t] = useTranslation();

  const open = useAppSelector((state) =>
    store.getAppState(state, "openRoomEffect")
  );
  const effectId = useAppSelector((state) =>
    store.getAppState(state, "openRoomEffectId")
  );
  const effect = useAppSelector((state) =>
    store.getRoomEffectById(state, effectId || "")
  );
  const beforeEffect = useRef<Effect | null>(null);

  const { register, handleSubmit, getValues, reset } = useForm({
    defaultValues: {
      name: "",
    },
  });

  const [volume, setVolume] = useState(effect.soundVolume ?? 0.15);

  /* eslint-disable react-hooks/exhaustive-deps */
  useEffect(() => {
    if (open) {
      beforeEffect.current = effect;
    }
  }, [open]);
  /* eslint-enable */

  useEffect(() => {
    setVolume(effect.soundVolume);
  }, [effect.soundVolume]);

  useEffect(() => {
    reset({
      name: effect.name,
    });
  }, [effect.name, reset]);

  const timer = useRef<number | null>(null);
  const onChangeVolume = useCallback(
    (_, value) => {
      setVolume(value);
      if (timer.current) {
        clearTimeout(timer.current);
      }
      timer.current = window.setTimeout(() => {
        if (effectId) {
          dispatch(
            store.updateRoomEffect(roomId, effectId, {
              soundVolume: value,
            })
          );
        }
      }, 300);
    },
    [roomId, effectId, setVolume, dispatch, timer]
  );

  const onSubmit = useCallback(
    (values: FormData) => {
      if (effectId) {
        dispatch(
          store.updateRoomEffect(roomId, effectId, {
            name: values.name,
          })
        );
      }
    },
    [roomId, effectId, dispatch]
  );

  const onClose = useCallback(() => {
    if (effectId) {
      const effectName = getValues();
      dispatch(
        store.updateRoomEffectName(roomId, effectId, {
          name: effectName.name,
        })
      );
      const before = beforeEffect.current;
      const after = { ...effect, name: effectName.name };
      if (before) {
        const isUpdate = Object.keys(after).some(
          (key) => before[key] !== after[key]
        );
        if (isUpdate) {
          dispatch(
            addUndo({
              kind: "update-effect",
              id: effectId,
              before: before,
              after: after,
            })
          );
        }
      }
    }
    dispatch(
      store.appStateMutate((state) => {
        state.openRoomEffect = false;
      })
    );
  }, [roomId, effectId, getValues, dispatch, effect, beforeEffect]);

  const onDelete = useCallback(() => {
    if (effectId && window.confirm(t("本当に削除しますか？"))) {
      dispatch(store.deleteRoomEffect(roomId, effectId));
      dispatch(
        addUndo({
          kind: "update-effect",
          id: effectId,
          before: effect,
          after: null,
        })
      );
      dispatch(
        store.appStateMutate((state) => {
          state.openRoomEffect = false;
        })
      );
    }
  }, [roomId, effectId, dispatch, t, effect]);

  const onOpen = useCallback(() => {
    dispatch(
      store.appStateMutate((state) => {
        state.openRoomMedia = true;
        state.openRoomMediaDir = "effect";
        state.enabledUserMediaLoader = true;
      })
    );
  }, [dispatch]);

  const onSelectImage = useCallback(() => {
    dispatch(
      store.appStateMutate((state) => {
        state.openRoomImageSelect = true;
        state.openRoomImageSelectDir = "effect";
        state.openRoomImageSelectTarget = "effect/set";
      })
    );
  }, [dispatch]);

  const onPlay = useCallback(() => {
    if (effectId) {
      dispatch(
        store.updateRoomEffect(roomId, effectId, {
          playTime: Date.now(),
        })
      );
    }
  }, [roomId, effectId, dispatch]);

  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="xs">
      <DialogContent style={style}>
        <Form onSubmit={handleSubmit(onSubmit)}>
          <TextField
            variant="filled"
            label={t("タイトル")}
            fullWidth
            margin="dense"
            defaultValue={effect.name}
            maxRows={24}
            rows={12}
            {...register("name")}
          />
          <Typography variant="caption" display="block">
            {t(
              "カットインはチャットの末尾に反応して起動します。カットインタイトルを「成功」とした場合、発言の末尾に「成功」という文字が入った時にカットインが起動して、サウンドや画像が再生されます。"
            )}
          </Typography>
        </Form>
        <ImageButton onClick={onSelectImage}>
          {effect.imageUrl ? (
            <img src={toCDNUrl(effect.imageUrl)} />
          ) : (
            <Blank>
              <Typography>{t("NOIMAGE")}</Typography>
            </Blank>
          )}
        </ImageButton>
        <Actions>
          <Button onClick={onOpen} fullWidth>
            {effect.soundName || t("音源を選択")}
          </Button>
          <Volume>
            <Typography variant="caption">{t("Volume")}</Typography>
            <Slider
              value={volume}
              color="primary"
              onChange={onChangeVolume}
              min={0.0}
              max={1}
              step={0.01}
            />
          </Volume>
        </Actions>
      </DialogContent>
      <DialogActions>
        <Button fullWidth onClick={onDelete} color="secondary">
          {t("削除")}
        </Button>
        <Button fullWidth onClick={onPlay} color="primary">
          {t("再生")}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const Form = styled.form`
  display: block;
`;

const ImageButton = styled(ButtonBase)`
  margin: 16px auto;
  width: 50%;
  position: relative;
  display: block;
  background: rgba(0, 0, 0, 0.12);
  border: 2px solid #fff;
  img {
    display: block;
    width: 100%;
    object-fit: contain;
  }
`;

const Blank = styled.span`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 60px;
`;

const Volume = styled.div`
  display: flex;
  align-items: center;
  > * + * {
    margin-left: 16px;
  }
`;

const Actions = styled.div`
  margin-top: 16px;
  margin-bottom: 8px;
`;

type FormData = {
  name: string;
};

export default memo(EffectDetail);
