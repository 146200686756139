import {
  Box,
  Breadcrumbs,
  Button,
  Card,
  CardActionArea,
  CardContent,
  CircularProgress,
  Container,
  Grid,
  Link,
  List,
  Typography,
} from "@mui/material";
import LockIcon from "@mui/icons-material/Lock";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import {
  createRoomFromRoomPackage,
  getGamesPurchasedProductIds,
  getProductById,
} from "api";
import Footer from "containers/Footer";
import { memo, useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import { useAppSelector } from "stores";
import { Link as RouterLink, useParams } from "react-router-dom";
import { getAuthedUid } from "stores/modules/app.user/selectors";
import styled from "styled-components";
import useSWR from "swr";
import theme from "theme";
import { useModal } from "hooks/useModal";
import CreateRoomFromProductDialog from "containers/CreateRoomFromProductDialog";
import { getDocs, query, where } from "firebase/firestore";
import {
  roomsRef,
  saveInitialRoomState,
} from "stores/modules/entities.rooms/operations";
import { Room } from "stores/modules/entities.rooms";
import { setRefererInternal } from "modules/referer";
import ListItemAttachment from "./ListItemAttachment";

const LibraryProduct = () => {
  const [t] = useTranslation();
  const { productId } = useParams<{
    productId: string;
  }>();
  const {
    open: isOpenExtentionSelect,
    onOpen: onOpenExtentionSelect,
    onClose: onCloseExtentionSelect,
  } = useModal();
  const [creating, setCreating] = useState(false);

  const {
    data: product,
    isLoading,
    error,
  } = useSWR(["getProductById", productId], ([_, productId]) =>
    getProductById(productId)
  );

  const uid = useAppSelector(getAuthedUid);
  const {
    data: purchasedProductIds,
    isLoading: isLoadingPurchase,
    error: errorPurchase,
  } = useSWR(
    uid ? ["getGamesPurchasedProductIds", uid] : null,
    getGamesPurchasedProductIds
  );

  const {
    data: existedRoomId,
    isLoading: isLoadingExistedRoomId,
    error: errorExistedRoomId,
  } = useSWR(
    uid ? ["fetchExistedRoomId", uid, productId] : null,
    ([_, uid, productId]) => fetchExistedRoomIdByProductId(uid, productId)
  );

  const onClickCreateRoom = useCallback(async () => {
    if (
      !product?.roomPackageId ||
      !product?.candidateRelatedProducts ||
      !purchasedProductIds
    ) {
      return;
    }

    const isAvailableExtention = product.candidateRelatedProducts.some(
      (relatedProduct) => purchasedProductIds.includes(relatedProduct.id)
    );

    if (isAvailableExtention) {
      onOpenExtentionSelect();
      return;
    }

    setCreating(true);
    try {
      const { roomId } = await createRoomFromRoomPackage(product.roomPackageId);
      await saveInitialRoomState(roomId);

      setCreating(false);
      window.alert(
        t("ルームを作成しました。新しく作成したルームに移動します。")
      );
      window.location.href = `/rooms/${roomId}`;
    } catch {
      setCreating(false);
      window.alert(t("ルームの作成に失敗しました。"));
    }
  }, [
    product?.roomPackageId,
    product?.candidateRelatedProducts,
    purchasedProductIds,
    onOpenExtentionSelect,
    setCreating,
    t,
  ]);

  const onClickThumbnailDetails = useCallback(() => {
    setRefererInternal({
      productId,
      referer: "library-page-thunbnail-details-button",
    });
  }, [productId]);

  if (isLoading || isLoadingPurchase || isLoadingExistedRoomId) {
    return (
      <Box display="flex" justifyContent="center" marginTop="64px">
        <CircularProgress />
      </Box>
    );
  }

  if (
    error ||
    errorPurchase ||
    errorExistedRoomId ||
    !product ||
    product.kind !== "game" ||
    !purchasedProductIds ||
    !purchasedProductIds.includes(productId)
  ) {
    return (
      <Box marginTop="64px">
        <Typography color="textPrimary" variant="body1" textAlign="center">
          {t("情報の取得に失敗しました")}
        </Typography>
      </Box>
    );
  }

  return (
    <>
      <div
        style={{
          position: "relative",
        }}
      >
        <BannerWrap>
          <Banner src={product.thumbnailUrl} />
        </BannerWrap>
        <Breadcrumbs
          separator="＞"
          aria-label="breadcrumb"
          sx={{
            position: "absolute",
            top: "16px",
            left: "16px",
            color: theme.palette.text.primary,
            display: {
              sm: "inherit",
              xs: "none",
            },
          }}
        >
          <Link
            underline="hover"
            color="inherit"
            component={RouterLink}
            to="/library"
          >
            {t("ライブラリ")}
          </Link>
          <Typography color="inherit">{product.name}</Typography>
        </Breadcrumbs>
        <WrapContainer>
          <Container maxWidth="sm">
            <div style={{ position: "relative" }}>
              <WrapProductThumbnail>
                <img width="100%" src={product.thumbnailUrl} />
                <DetailButton
                  color="black"
                  variant="contained"
                  component="a"
                  target="_blank"
                  href={`/games/${productId}`}
                  endIcon={<OpenInNewIcon fontSize="small" />}
                  sx={{ border: "1px solid rgba(255,255,255,0.3)" }}
                  onClick={onClickThumbnailDetails}
                >
                  {t("詳細")}
                </DetailButton>
              </WrapProductThumbnail>
              <WrapCard>
                <Box
                  marginBottom="48px"
                  sx={{
                    width: { sm: "calc(100% - 60px - 60px)", xs: "100%" },
                    marginLeft: { sm: "60px", xs: 0 },
                  }}
                >
                  <Card sx={{ borderRadius: "8px" }}>
                    <CardContent sx={{ padding: "24px" }}>
                      <Typography
                        variant="h2"
                        fontSize="18px"
                        fontWeight="normal"
                        marginBottom="8px"
                      >
                        {product.name}
                      </Typography>
                      <Description
                        title={t("ジャンル")}
                        text={product.category}
                      />
                      <Description
                        title={t("プレイ人数")}
                        text={product.players}
                      />
                      <Description
                        title={t("プレイ時間")}
                        text={product.playTimes}
                      />
                      <Description
                        title={t("開発元")}
                        text={product.developer}
                      />
                      {product.officialSite && (
                        <Description
                          title={t("公式サイト")}
                          text={product.officialSite.name}
                          href={product.officialSite.url}
                        />
                      )}
                      {product.roomPackageId && (
                        <Button
                          variant="contained"
                          color="secondary"
                          size="large"
                          fullWidth
                          disabled={creating}
                          sx={{ marginTop: "24px" }}
                          onClick={onClickCreateRoom}
                        >
                          {creating && (
                            <CircularProgress
                              size={16.5}
                              sx={{ marginRight: "8px" }}
                            />
                          )}
                          {t("新しいルームを作成する")}
                        </Button>
                      )}
                      {existedRoomId && (
                        <Button
                          variant="outlined"
                          size="large"
                          fullWidth
                          component="a"
                          href={`/rooms/${existedRoomId}`}
                          sx={{ marginTop: "16px" }}
                        >
                          {t("前回作成したルームに入室する")}
                        </Button>
                      )}
                    </CardContent>
                  </Card>
                </Box>
              </WrapCard>
            </div>
            {product.attachments.length > 0 && (
              <>
                <Typography
                  variant="h5"
                  color="text.primary"
                  marginBottom="8px"
                  fontWeight="bold"
                >
                  {t("ダウンロード")}
                </Typography>
                <List dense sx={{ marginBottom: "32px" }}>
                  {product.attachments.map((attachment) => (
                    <ListItemAttachment
                      key={attachment.filename}
                      filename={attachment.filename}
                      size={attachment.size}
                      mime={attachment.mime}
                      updatedAt={attachment.updatedAt}
                      productId={productId}
                    />
                  ))}
                </List>
              </>
            )}
            {product.relatedProducts.length > 0 && (
              <>
                <Typography
                  variant="h5"
                  color="text.primary"
                  marginBottom="20px"
                  fontWeight="bold"
                >
                  {t("追加・拡張コンテンツ")}
                </Typography>
                <Grid container spacing={2}>
                  {product.relatedProducts.map((relatedProduct) => (
                    <RelatedProduct
                      key={relatedProduct.id}
                      productId={relatedProduct.id}
                      thumbnailUrl={relatedProduct.thumbnailUrl}
                      shortName={relatedProduct.shortName}
                      purchased={purchasedProductIds.includes(
                        relatedProduct.id
                      )}
                      referer="library-page-related-products-card"
                    />
                  ))}
                </Grid>
              </>
            )}
            {product.relatedItems.length > 0 && (
              <>
                <Typography
                  variant="h5"
                  color="text.primary"
                  marginBottom="20px"
                  fontWeight="bold"
                >
                  {t("関連作品")}
                </Typography>
                <Grid container spacing={2}>
                  {product.relatedItems.map((relatedProduct) => (
                    <RelatedProduct
                      key={relatedProduct.id}
                      productId={relatedProduct.id}
                      thumbnailUrl={relatedProduct.thumbnailUrl}
                      shortName={relatedProduct.name}
                      purchased={true}
                      referer="library-page-related-items-card"
                    />
                  ))}
                </Grid>
              </>
            )}
          </Container>
        </WrapContainer>
      </div>
      <Footer />
      <CreateRoomFromProductDialog
        productId={productId}
        open={isOpenExtentionSelect}
        onClose={onCloseExtentionSelect}
      />
    </>
  );
};

type DescriptionProps = {
  title: string;
  text: string;
  href?: string;
};

const Description = ({ title, text, href }: DescriptionProps) => {
  if (!text) {
    return null;
  }

  return (
    <Box display="flex" flexDirection="row" marginTop="8px">
      <Typography
        color="textPrimary"
        fontSize="14px"
        fontWeight="bold"
        lineHeight="1.4rem"
      >
        {title}：
      </Typography>
      {href ? (
        <Link
          href={href}
          variant="body1"
          target="_blank"
          underline="hover"
          fontSize="16px"
          lineHeight="1.4rem"
          marginLeft="16px"
        >
          {text}
        </Link>
      ) : (
        <Typography
          color="textPrimary"
          fontSize="16px"
          lineHeight="1.4rem"
          marginLeft="16px"
        >
          {text}
        </Typography>
      )}
    </Box>
  );
};

type RelatedProductProps = {
  productId: string;
  shortName: string;
  thumbnailUrl: string;
  purchased: boolean;
  referer:
    | "library-page-related-products-card"
    | "library-page-related-items-card";
};

const RelatedProduct = ({
  productId,
  thumbnailUrl,
  purchased,
  shortName,
  referer,
}: RelatedProductProps) => {
  const onClick = useCallback(() => {
    setRefererInternal({
      productId,
      referer,
    });
  }, [productId, referer]);

  return (
    <Grid item xs={4} sm={3}>
      <Card sx={{ borderRadius: "8px" }}>
        <CardActionArea
          href={`/games/${productId}`}
          target="_blank"
          onClick={onClick}
        >
          <Thumbnail src={thumbnailUrl} />
          {!purchased && (
            <Overlay>
              <LockIcon fontSize="large" />
            </Overlay>
          )}
        </CardActionArea>
      </Card>
      <Typography
        fontSize="12px"
        fontWeight="bold"
        color="textPrimary"
        margin="8px 0"
        whiteSpace="nowrap"
        overflow="hidden"
        textOverflow="ellipsis"
      >
        {shortName}
      </Typography>
    </Grid>
  );
};

const Thumbnail = styled.img`
  width: 100%;
  aspect-ratio: 1 / 1;
  object-fit: cover;
`;

const Overlay = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;

  display: flex;
  justify-content: center;
  align-items: center;

  background: rgba(0, 0, 0, 0.7);
`;

const BannerWrap = styled.div`
  width: 100%;
  height: 240px;
  overflow: hidden;

  ${theme.breakpoints.down("sm")} {
    display: none;
  }
`;

const Banner = styled.img`
  width: 100%;
  height: 240px;
  object-fit: cover;
  filter: blur(4px);
`;

const WrapProductThumbnail = styled.div`
  position: relative;
  width: 100%;

  ${theme.breakpoints.down("sm")} {
    width: 100vw;
    width: calc(100% + 32px);

    margin: 0 -16px;
  }
`;

const WrapContainer = styled.div`
  position: relative;
  margin-top: -168px;
  z-index: 10;

  ${theme.breakpoints.down("sm")} {
    position: static;
    margin-top: 0;
  }
`;

const DetailButton = styled(Button)`
  position: absolute;
  top: 24px;
  right: 24px;

  ${theme.breakpoints.down("sm")} {
    top: unset;
    right: unset;

    bottom: 16px;
    right: 16px;
  }
`;

const WrapCard = styled.div`
  position: relative;
  margin-top: -64px;
  z-index: 10;

  ${theme.breakpoints.down("sm")} {
    margin-top: 16px;
  }
`;

const fetchExistedRoomIdByProductId = async (
  uid: string,
  productId: string
): Promise<string | null> => {
  const snapshot = await getDocs(
    query(roomsRef, where("owner", "==", uid), where("archived", "==", false))
  );

  const rooms = snapshot.docs
    .map((doc) => {
      const room = doc.data() as Room;
      return {
        id: doc.id,
        parentProductId: room.parentProductId,
        createdAt: room.createdAt || 0,
      };
    })
    .sort(compareCreatedAtToDesc);

  const index = rooms.findIndex((room) => room.parentProductId === productId);
  if (index >= 0) {
    return rooms[index].id;
  }
  return null;
};

interface HasCreatedAt {
  createdAt: number;
}

const compareCreatedAtToDesc = (a: HasCreatedAt, b: HasCreatedAt): number => {
  if (a.createdAt > b.createdAt) {
    return -1;
  }
  if (a.createdAt < b.createdAt) {
    return 1;
  }
  return 0;
};

export default memo(LibraryProduct);
