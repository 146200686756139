import { memo, useCallback, useEffect, useRef } from "react";
import styled from "styled-components";
import { Typography } from "@mui/material";
import { useTranslation } from "react-i18next";

import FileListItem, { FileListItemEmpty } from "../FileListItem";
import FileListItemMenu from "../FileListItemMenu";
import { OfficialImageDir } from "api";
import OfficialFileListItem from "containers/FileListItem/OfficialItem";

const range = 100;
const imageInView = (img: HTMLImageElement) => {
  const rect = img.getBoundingClientRect();
  const isInView =
    -range < rect.bottom && rect.top < window.innerHeight + range;
  const src = img.dataset["src"];
  const isLoaded = isInView && !!src;
  if (isLoaded) {
    img.src = src;
  }
  return isLoaded;
};

type FileListProps = {
  userFileIds: string[];
  onSelect: (file: string) => void;
  officialDir?: OfficialImageDir;
};

const FileList = ({ userFileIds, onSelect, officialDir }: FileListProps) => {
  const [t] = useTranslation();
  const containerRef = useRef<HTMLDivElement>(null);
  const imagesRef = useRef<HTMLImageElement[]>([]);
  const timerRef = useRef<number | null>(null);

  useEffect(() => {
    if (containerRef.current) {
      containerRef.current.scrollTop = 0;
    }
  }, [containerRef]);

  const onScroll = useCallback(() => {
    if (timerRef.current) return;
    timerRef.current = window.setTimeout(() => {
      imagesRef.current = imagesRef.current.filter((img) => {
        return !imageInView(img);
      });
      timerRef.current = null;
    }, 200);
  }, [imagesRef]);

  const onMounted = useCallback(
    (img: HTMLImageElement) => {
      if (!imageInView(img)) {
        imagesRef.current.push(img);
      }
    },
    [imagesRef]
  );

  const onUnMounted = useCallback(
    (img: HTMLImageElement) => {
      const i = imagesRef.current.indexOf(img);
      i > -1 && imagesRef.current.splice(i, 1);
    },
    [imagesRef]
  );
  return (
    <Tiles ref={containerRef} onScroll={onScroll}>
      <FileListItemEmpty onSelect={onSelect} />
      {userFileIds.map((assetId) => (
        <FileListItem
          onMounted={onMounted}
          onUnMounted={onUnMounted}
          key={assetId}
          assetId={assetId}
          onSelect={onSelect}
        />
      ))}
      {officialDir &&
        officialDir.images.map((asset) => (
          <OfficialFileListItem
            onMounted={onMounted}
            onUnMounted={onUnMounted}
            key={asset.id}
            asset={asset}
            onSelect={onSelect}
          />
        ))}
      {userFileIds.length < 1 &&
      (!officialDir || officialDir.images.length < 1) ? (
        <EmptyText variant="body2">
          {t("ドラッグアンドドロップでファイルを追加することができます。")}
        </EmptyText>
      ) : null}
      <FileListItemMenu />
    </Tiles>
  );
};

const EmptyText = styled(Typography)`
  &.MuiTypography-root {
    padding: 16px;
  }
`;

const Tiles = styled.div`
  padding: 1%;
  height: 640px;
  display: flex;
  flex-wrap: wrap;
  overflow-y: scroll;
  align-content: flex-start;
  -webkit-overflow-scrolling: touch;
  ::-webkit-scrollbar: {
    display: none;
  }
`;

export default memo(FileList);
