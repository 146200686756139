import { memo, MouseEvent, useCallback, useMemo, useState } from "react";
import { useAppDispatch, useAppSelector } from "stores";
import {
  AppBar,
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogActions,
  Divider,
  Link,
  TextField,
  Tooltip,
  Typography,
  Toolbar,
  IconButton,
} from "@mui/material";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import CloseIcon from "@mui/icons-material/Close";
import HelpIcon from "@mui/icons-material/Help";
import { useTranslation } from "react-i18next";
import { updateRoom } from "stores/modules/entities.rooms/operations";
import {
  appStateMutate,
  loadDiceBotInfo,
} from "stores/modules/app.state/operations";
import { useForm } from "react-hook-form";
import { getRoomById } from "stores/modules/entities.rooms/selectors";
import { getAppState } from "stores/modules/app.state/selectors";
import copy from "copy-to-clipboard";
import theme from "theme";

type RoomSettingsProps = {
  roomId: string;
};

const RoomSettings = ({ roomId }: RoomSettingsProps) => {
  const open = useAppSelector((state) =>
    getAppState(state, "openRoomSettings")
  );
  const roomName = useAppSelector((state) => getRoomById(state, roomId).name);
  const diceBotName = useAppSelector(
    (state) => getRoomById(state, roomId).diceBotName
  );
  const dispatch = useAppDispatch();
  const [t] = useTranslation();
  const { register, handleSubmit } = useForm({
    defaultValues: { name: roomName },
  });

  const onSubmit = useMemo(
    () =>
      handleSubmit(({ name }) => {
        dispatch(
          updateRoom(roomId, {
            name,
          })
        );
      }),
    [dispatch, roomId, handleSubmit]
  );

  const onOpenRoomDiceBotTypeList = useCallback(() => {
    dispatch(
      appStateMutate((state) => {
        state.openRoomDiceBotTypeList = true;
      })
    );
  }, [dispatch]);

  const onClose = useCallback(() => {
    dispatch(
      appStateMutate((state) => {
        state.openRoomSettings = false;
      })
    );
    onSubmit();
  }, [dispatch, onSubmit]);

  const roomUrl = useMemo(() => {
    return window.location.origin + "/rooms/" + roomId;
  }, [roomId]);
  const [openTooltip, setOpenTooltip] = useState(false);
  const onClickCopy = useCallback(() => {
    if (copy(roomUrl)) {
      setOpenTooltip(true);
      setTimeout(() => {
        setOpenTooltip(false);
      }, 1000);
    } else {
      window.alert(t("コピーに失敗しました"));
    }
  }, [roomUrl, setOpenTooltip, t]);

  const onOpenDiceBotHelp = useCallback(
    (event: MouseEvent) => {
      event.preventDefault();
      dispatch(loadDiceBotInfo());
    },
    [dispatch]
  );

  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="xs">
      <AppBar color="default" position="sticky">
        <Toolbar>
          <Typography style={{ flex: 1 }} fontSize="20px" fontWeight="bold">
            {t("新規ルーム作成")}
          </Typography>
          <IconButton edge="end" onClick={onClose} size="large">
            <CloseIcon />
          </IconButton>
        </Toolbar>
      </AppBar>
      <DialogContent>
        <form onSubmit={onSubmit}>
          <Box mb={2}>
            <TextField
              label={t("ルーム名")}
              type="text"
              variant="filled"
              fullWidth
              margin="normal"
              {...register("name")}
            />
            <TextField
              label={t("ダイスボット")}
              variant="outlined"
              fullWidth
              margin="normal"
              value={diceBotName || t("ダイスボットを選択")}
              InputProps={{
                readOnly: true,
                endAdornment: <ArrowDropDownIcon />,
              }}
              inputProps={{
                style: { cursor: "pointer" },
              }}
              onClick={onOpenRoomDiceBotTypeList}
            />
            <Box mt={1} display="flex" alignItems="center" gap="8px">
              <HelpIcon color="primary" />
              <Typography variant="body2" color={theme.palette.text.secondary}>
                {t("ルームで使用するダイスボットを選択できます。")}
                <Link href="#" onClick={onOpenDiceBotHelp} underline="hover">
                  {t("選択中のダイスボットの詳細はこちら。")}
                </Link>
              </Typography>
            </Box>
          </Box>
          <Divider />
          <Box mt={2}>
            <TextField
              value={roomUrl}
              label={t("ルーム招待URL")}
              type="text"
              variant="filled"
              fullWidth
              margin="dense"
              InputProps={{ readOnly: true }}
            />
            <Typography variant="caption" paragraph>
              {t("ルームID")} {roomId}
            </Typography>
            <Tooltip
              open={openTooltip}
              disableFocusListener
              disableHoverListener
              disableTouchListener
              title={t("コピーしました")}
            >
              <Button
                variant="outlined"
                color="white"
                size="large"
                fullWidth
                startIcon={<ContentCopyIcon />}
                onClick={onClickCopy}
              >
                {t("リンクをコピー")}
              </Button>
            </Tooltip>
          </Box>
        </form>
      </DialogContent>
      <DialogActions>
        <Button color="primary" fullWidth onClick={onClose}>
          {t("閉じる")}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default memo(RoomSettings);
