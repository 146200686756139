import {
  CircularProgress,
  Dialog,
  DialogContent,
  DialogContentText,
} from "@mui/material";
import { syncSubscriptionState } from "api";
import { getIdTokenResult } from "firebase/auth";
import { auth } from "initializer";
import { memo, useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useAppDispatch, useAppSelector } from "stores";
import { useLocation } from "react-router-dom";
import store from "stores/interfaces";
import { AppUser } from "stores/modules/app.user";
import { appUserMutate } from "stores/modules/app.user/operations";
import styled from "styled-components";
import "url-search-params-polyfill";

const StripeRedirectObserver = () => {
  const dispatch = useAppDispatch();
  const location = useLocation();
  const [t] = useTranslation();
  const uid = useAppSelector((state) => store.getAppState(state, "uid"));
  const [loading, setLoading] = useState(false);

  const syncUserPlan = useCallback(async () => {
    await syncSubscriptionState({ force: true });
    if (auth.currentUser == null) {
      return;
    }

    // カスタムクレートを更新するために、ID tokenを強制更新する。
    const token = await getIdTokenResult(auth.currentUser, true);
    const plan: AppUser["plan"] =
      token.claims.plan === "ccfolia-pro" ? "ccfolia-pro" : "free";
    dispatch(
      appUserMutate((draft) => {
        draft.plan = plan;
      })
    );

    if (plan === "ccfolia-pro") {
      dispatch(store.appStateMutate((state) => (state.openJoinedPro = true)));
    }
  }, [dispatch]);

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    if (uid != null && searchParams.has("success")) {
      setLoading(true);
      syncUserPlan()
        .catch(() => window.alert(t("プラン情報の同期に失敗しました。")))
        .finally(() => setLoading(false));
    }
  }, [location.search, uid, syncUserPlan, setLoading, t]);

  return (
    <Dialog open={loading} onClose={() => {}} maxWidth="xs">
      <DialogContent>
        <DialogContentText align="center">
          {t("プラン情報を読み込んでいます")}
        </DialogContentText>
        <Centered>
          <CircularProgress />
        </Centered>
      </DialogContent>
    </Dialog>
  );
};

const Centered = styled.div`
  display: flex;
  justify-content: center;
  margin: 16px;
`;

export default memo(StripeRedirectObserver);
