import isValidUrl from "./isValidUrl";
import { Dispatch } from "redux";
import { addMessageCurrentRoom } from "stores/modules/entities.room.messages/operations";
import {
  ClickActionLink,
  ClickActionMessage,
  ClickAction,
} from "./clickAction";
import i18next from "i18next";

// handlers

const handleLinkAction = (clickAction: ClickActionLink) => {
  if (!isValidUrl(clickAction.url)) {
    return;
  }

  if (
    window.confirm(
      i18next.t("下記 URL を開こうとしてます。本当によろしいですか？\n") +
        clickAction.url
    )
  ) {
    window.open(clickAction.url, "_blank");
  }
};

const handleMessageAction = (
  clickAction: ClickActionMessage,
  dispatch: Dispatch<any>
) => {
  if (clickAction.text === "") {
    return;
  }

  dispatch(addMessageCurrentRoom(clickAction.text, false));
};

export const handleClickAction = (
  clickAction: ClickAction,
  dispatch: Dispatch<any>
): void => {
  switch (clickAction.type) {
    case "link":
      return handleLinkAction(clickAction);
    case "message":
      return handleMessageAction(clickAction, dispatch);
  }
};
