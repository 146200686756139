import { memo, ReactNode, useCallback } from "react";
import { useAppSelector } from "stores";
import store from "stores/interfaces";
import {
  closestCenter,
  DndContext,
  DragEndEvent,
  TouchSensor,
  useSensor,
  useSensors,
} from "@dnd-kit/core";
import { restrictToVerticalAxis } from "@dnd-kit/modifiers";
import { MouseSensor } from "modules/draggableSensor";
import {
  DropProps,
  isDropProps,
} from "stores/modules/firestoreModuleUtils/ordaring";
import styled from "styled-components";
import SortableListOverlay from "./SortableListOverlay";
import {
  SortableContext,
  verticalListSortingStrategy,
} from "@dnd-kit/sortable";

type SortableListProps = {
  ids: string[];
  noPadding?: boolean;
  onReorder: (order: DropProps) => void;
  sortableItem: (id: string) => ReactNode;
  overlay: (id: string) => ReactNode;
};

const SortableList = ({
  ids,
  noPadding,
  onReorder,
  sortableItem,
  overlay,
}: SortableListProps) => {
  const roomId = useAppSelector((state) => store.getAppState(state, "roomId"));

  const sensors = useSensors(
    useSensor(MouseSensor, {
      activationConstraint: {
        distance: 2,
      },
    }),
    useSensor(TouchSensor, {
      activationConstraint: {
        delay: 100,
        tolerance: 2,
      },
    })
  );
  const onDragEnd = useCallback(
    async (e: DragEndEvent) => {
      const { active, over } = e;
      const order = {
        destination: over?.data.current?.sortable.index,
        source: active.data.current?.sortable.index,
      };

      if (roomId && isDropProps(order) && order.destination !== order.source) {
        await onReorder(order);
      }
    },
    [roomId, onReorder]
  );
  return (
    <SortableContent noPadding={noPadding}>
      <DndContext
        sensors={sensors}
        collisionDetection={closestCenter}
        modifiers={[restrictToVerticalAxis]}
        onDragEnd={onDragEnd}
        autoScroll={{
          enabled: true,
          canScroll: (el) => el.classList.contains("scrollable-list"),
        }}
      >
        <SortableContext items={ids} strategy={verticalListSortingStrategy}>
          {ids.map((id) => (
            <div key={id}>{sortableItem(id)}</div>
          ))}
        </SortableContext>
        <SortableListOverlay overlay={overlay} />
      </DndContext>
    </SortableContent>
  );
};

const SortableContent = styled.div<{ noPadding?: boolean }>`
  padding-top: ${(props) => (props.noPadding ? "0" : "8px")};
  padding-bottom: ${(props) => (props.noPadding ? "0" : "8px")};
`;

export default memo(SortableList);
