import { memo } from "react";
import { useAppSelector } from "stores";
import store from "stores/interfaces";
import { UserFile } from "stores/modules/entities.user.files";
import FileList from "../FileList";
import { getAppState } from "stores/modules/app.state/selectors";
import { OfficialImageDir } from "api";

type FileListContainerProps = {
  onSelect: (file: string) => void;
  group: "room" | "all";
  dir: UserFile["dir"] | null;
};

const FileListContainer = ({
  onSelect,
  group,
  dir,
}: FileListContainerProps) => {
  const officialDir = useAppSelector((state) => {
    const images = getAppState(state, "officialImages");
    return images.find((library) => library.id === dir);
  });

  if (!dir) {
    return null;
  }

  switch (group) {
    case "room":
      return (
        <FileListRoom onSelect={onSelect} dir={dir} officialDir={officialDir} />
      );
    case "all":
      return (
        <FileListAll onSelect={onSelect} dir={dir} officialDir={officialDir} />
      );
    default:
      return null;
  }
};

type FileListBaseProps = {
  onSelect: (file: string) => void;
  dir: UserFile["dir"];
  officialDir?: OfficialImageDir;
};

const FileListRoom = ({ onSelect, dir, officialDir }: FileListBaseProps) => {
  const userFileIds = useAppSelector((state) =>
    store.getRoomUserFileIds(state, dir)
  );

  return (
    <FileList
      userFileIds={userFileIds}
      onSelect={onSelect}
      officialDir={officialDir}
    />
  );
};

const FileListAll = ({ onSelect, dir, officialDir }: FileListBaseProps) => {
  const userFileIds = useAppSelector((state) =>
    store.getSortedUserFileIdsByDir(state, dir)
  );

  return (
    <FileList
      userFileIds={userFileIds}
      onSelect={onSelect}
      officialDir={officialDir}
    />
  );
};

export default memo(FileListContainer);
