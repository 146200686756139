export type ClickAction = ClickActionLink | ClickActionMessage;

export type ClickActionLink = {
  type: "link";
  url: string;
};

export type ClickActionMessage = {
  type: "message";
  text: string;
};

export const ClickActionRecord = (
  clickAction: Partial<ClickAction>
): ClickAction | null => {
  switch (clickAction.type) {
    case "link":
      return ClickActionLinkRecord(clickAction);
    case "message":
      return ClickActionMessageLink(clickAction);
    default:
      return null;
  }
};

export const ClickActionLinkRecord = (
  clickAction: Partial<ClickActionLink>
): ClickActionLink => {
  return {
    type: "link",
    url: clickAction.url || "",
  };
};

export const ClickActionMessageLink = (
  clickAction: Partial<ClickActionMessage>
): ClickActionMessage => {
  return {
    type: "message",
    text: clickAction.text || "",
  };
};
