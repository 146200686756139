import { Position } from "containers/DraggableItem";
import { useState, useCallback, MutableRefObject, useEffect } from "react";
import { flushSync } from "react-dom";
import { useAppSelector } from "stores";
import { getAppState } from "stores/modules/app.state/selectors";

export const useResizable = (
  initialPosition: Position,
  onUpdatePosition: (position: Position) => void,
  isResizing: MutableRefObject<boolean>
) => {
  const cellSize = useAppSelector((state) =>
    getAppState(state, "roomScreenCellSize")
  );

  const [size, setSize] = useState({
    width: initialPosition.width * cellSize,
    height: initialPosition.height * cellSize,
  });

  const [resizePosition, setResizePosition] = useState<{
    x: number;
    y: number;
  }>({
    x: 0,
    y: 0,
  });

  useEffect(() => {
    setSize({
      width: initialPosition.width * cellSize,
      height: initialPosition.height * cellSize,
    });
  }, [initialPosition.width, initialPosition.height, cellSize]);

  const handleResize = useCallback(
    (_e, d, _ref, delta) => {
      const newResizePosition = {
        x: 0,
        y: 0,
      };

      const upperDirection = d.toUpperCase();

      if (upperDirection.includes("TOP")) {
        newResizePosition.y = Math.round(delta.height / cellSize);
      }

      if (upperDirection.includes("LEFT")) {
        newResizePosition.x = Math.round(delta.width / cellSize);
      }

      if (upperDirection.includes("TOP") || upperDirection.includes("LEFT")) {
        flushSync(() => {
          setResizePosition(newResizePosition);
        });
      }
    },
    [cellSize]
  );

  const handleResizeStop = useCallback(
    (_e, _b, ref, delta) => {
      isResizing.current = true;
      if (delta.width || delta.height) {
        const newSize = {
          width: Math.round(ref.offsetWidth / cellSize) * cellSize,
          height: Math.round(ref.offsetHeight / cellSize) * cellSize,
        };

        const newPosition = {
          x: initialPosition.x - resizePosition.x,
          y: initialPosition.y - resizePosition.y,
          width: Math.round(ref.offsetWidth / cellSize),
          height: Math.round(ref.offsetHeight / cellSize),
        };

        setSize(newSize);
        setResizePosition({ x: 0, y: 0 });
        onUpdatePosition(newPosition);
      }
    },
    [onUpdatePosition, cellSize, initialPosition, resizePosition, isResizing]
  );

  return {
    size,
    resizePosition,
    handleResize,
    handleResizeStop,
  };
};
