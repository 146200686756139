import styled from "styled-components";
import MessageCard from "./MessageCard";
import Slider from "react-slick";
import theme from "theme";
import { useMediaQuery } from "@mui/material";
import { Trans } from "react-i18next";
import { shuffle } from "lodash-es";

const VOICES = shuffle([
  {
    name: "むつー",
    caption: "TRPG配信者",
    text: "オンラインでTRPGを遊ぶ上で、ココフォリアの無い生活はもはや考えられません。\n直感的で簡単・便利でカスタム幅も深く、やりたいことはほぼ全てできます。\n既に使ってて全く不満が無いのに、アップデートごとに毎度更にパワーアップしていくので手がつけられません。\nこれ無料で使えていいのかよ。",
    iconUrl: "/images/icons/mutsu.png",
  },
  {
    name: "まだら牛",
    caption: "TRPGクリエイター、配信者",
    text: "動作が軽快。インターフェイスがオシャレ。セッション準備が圧倒的に楽。 ……他にもココフォリアの良いところはたくさんあるが、 何より素晴らしいのは、ユーザーのニーズを積極的に拾い上げて 改良を続けている開発陣の心意気だ。",
    iconUrl: "/images/icons/madaraushi.png",
  },
  {
    name: "ぱぱびっぷ",
    caption: "TRPGシナリオ・動画制作、公開セッションの企画、配信など",
    text: "ココフォリアは「多才な演出」と「簡単で軽快な動作」が両立していて セッション中のプレイヤーと視聴者の熱気を逃がさず保てる、配信に必須のツールです。 また現代的な設計思想に基づき、使用者が「本当に求めているもの」を考え工夫して実装する姿勢が素晴らしい。 将来に渡って信頼して使い続けられるセッションツールだと考えています。",
    iconUrl: "/images/icons/papavip.png",
  },
  {
    name: "宮古ミーゴ",
    caption: "Vtuber",
    text: "TRPGやアナログゲームを楽しく準備するなら『ココフォリア』へ！初心者でも直感的に使えるサービスで、簡単にセッションを始められます。遊び場が広がる楽しさを体験してみてください。まずは一度使ってみるのがおすすめです！",
    iconUrl: "/images/icons/migo.png",
  },
  {
    name: "ディズム",
    caption: "TRPG配信者、シナリオライター",
    text: "TRPGを遊ぶだけじゃなく、演出することもできるオンラインセッションツール。想像力で補うのがTRPGの良さだけど、視覚や聴覚に働きかけて、直感的なおもしろさを訴えることも大事なので、重宝しています。写真やイラストを出したり、音楽や効果音を流したりすることで、言葉では伝えきれないところまで補ってもらっています。",
    iconUrl: "/images/icons/dizm.png",
  },
  {
    name: "k4sen",
    caption: "CREATOR",
    text: "TRPGの楽しみは本番のプレイだけではありません。ゲームマスターとして本番に向けて、まるで演出家になったような気持ちで、プレイヤーたちの反応を想像しながらココフォリアでセットアップを行っている時間もまた、TRPGの醍醐味です。\nココフォリアは最もコンパクトで、そして最も壮大な舞台です。",
    iconUrl: "/images/icons/k4sen.png",
  },
  {
    name: "しまどりる",
    caption: "イラストレーター/趣味でTRPGシナリオ執筆",
    text: "アプリや説明書が苦手な自分にも使えています。TRPGの準備は楽しい。イラスト、立ち絵BGM…気がつけば、自分だけの部屋ができています。折角ですからココフォリアで、プレイヤーを驚かせる、とっておきの演出を仕込んでみてはいかがでしょう？",
    iconUrl: "/images/icons/simadoriru.png",
  },
  {
    name: "栗山やんみ",
    caption: "マルチクリエイター",
    text: "TRPG、ボドゲ、プレゼン、クイズ、謎解きなどなど!\n本当にいろんな企画でたっくさん利用させていただいています!\n細かなことからBIGなことまで、クリエイター心も満たしてくれる最強ツール★\n操作感も簡単なのでお友だちにも紹介しやすくて好!\nココフォリアだあいすき!",
    iconUrl: "/images/icons/kuriyamayanmi.png",
  },
  {
    name: "河嶋陶一朗",
    caption: "ゲーム作家",
    text: "あるときオンラインでしか、セッションが遊べなくなりました。\nいっぱいやらないといけないテストプレイ。\nオフセメインの自分のピンチを助けてくれたのがココフォリアです。\n以来、いっぱいお世話になってます。\n凝ってもいいし、適当でもいい。\n気分屋な自分にはとても向いているインターフェイスだと思います。",
    iconUrl: "/images/icons/kawashima.png",
  },
  {
    name: "コーサカ",
    caption: "Vtuber",
    text: "TRPGで遊ばせてもらい始めた時、「自分には触れなさそうだ」と思っていたツールがココフォリアです。\n敷居は低いけれど奥は深い。\nそんな印象を受けた通り、デザインに対する理解やプレイヤーに対するもてなし精神が低い自分でも、触っていると時間が溶けます！　いじるの楽しくて！",
    iconUrl: "/images/icons/kosaka.png",
  },
  {
    name: "高生紳士",
    caption: "あたたかくなる所属",
    text: "僕にとってココフォリアは転校生だったんです。\n突如現れて、みんなの中心になって。\n当時の僕は、それが面白くなかったんです。\nでもある日、こうして関わらない自分が恥ずかしくなってきて、勇気を出してココフォリアに声を掛けました。\n結局、友達になりたかったのは僕のほうなんです。\n素直になれなくて言えなかったけど、ここで言わせて下さい。\nいつもありがとう、ココフォリア。",
    iconUrl: "/images/icons/takao.png",
  },
]);

const Message = () => {
  const matches = useMediaQuery(theme.breakpoints.up("md"));

  const sliderResponsiveOption = {
    responsive: [
      {
        breakpoint: theme.breakpoints.values.lg,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          rows: 1,
        },
      },
      {
        breakpoint: theme.breakpoints.values.md,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          rows: 3,
        },
      },
    ],
  };
  return (
    <MessageWrap>
      <MessageContainer>
        <MessageTitle>
          <Trans>
            応援メッセージも
            <br style={{ display: matches ? "none" : "block" }} />
            届いています
          </Trans>
        </MessageTitle>
        <MessageLists>
          <Slider
            speed={640}
            easing="easeInOut"
            dots
            infinite
            arrows={false}
            adaptiveHeight
            slidesToShow={3}
            slidesToScroll={3}
            {...sliderResponsiveOption}
          >
            {VOICES.map((voice) => (
              <div className="message-item" key={voice.name}>
                <MessageCard
                  name={voice.name}
                  caption={voice.caption}
                  text={voice.text}
                  iconUrl={voice.iconUrl}
                />
              </div>
            ))}
          </Slider>
        </MessageLists>
      </MessageContainer>
    </MessageWrap>
  );
};

const MessageWrap = styled.div`
  font-family: "Noto Sans JP", sans-serif;
  -webkit-font-smoothing: antialiased;
`;

const MessageContainer = styled.section``;

const MessageTitle = styled.h2`
  margin: 120px auto 0;
  font-size: 40px;
  text-align: center;
  color: #fff;
  ${theme.breakpoints.down("md")} {
    margin: 80px auto 0;
    font-size: 24px;
  }
`;

const MessageLists = styled.section`
  width: calc(1176 / 1400 * 100%);
  max-width: 1176px;
  margin: 40px auto 0;
  .slick-dots {
    li button:before {
      font-size: 8px;
      color: #606060;
      opacity: 1;
    }
    li.slick-active button:before {
      color: rgba(244, 0, 87, 1);
      opacity: 1;
    }
  }

  ${theme.breakpoints.down("md")} {
    width: calc(342 / 390 * 100%);
    margin: 24px auto 0;
  }

  ${theme.breakpoints.up("md")} {
    .slick-track {
      display: flex;
    }
    .slick-slide {
      height: auto;
      > * {
        height: 100%;
      }
    }
    .message-item {
      display: flex !important;
      height: 100%;
    }
  }
`;

export default Message;
