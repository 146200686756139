import { useState, useRef, useEffect, useCallback } from "react";

export const useVisibleTabs = () => {
  const leftBadgeIds = useRef<Set<string> | null>(null);
  if (leftBadgeIds.current === null) {
    leftBadgeIds.current = new Set();
  }
  const rightBadgeIds = useRef<Set<string> | null>(null);
  if (rightBadgeIds.current === null) {
    rightBadgeIds.current = new Set();
  }
  const [hasLeftBadge, setHasLeftBadge] = useState(false);
  const [hasRightBadge, setHasRightBadge] = useState(false);
  const tabAreaRef = useRef<HTMLDivElement | null>(null);
  const tabBadgeRefs = useRef<Record<string, IntersectionObserver | null>>({});

  const checkIntersection = useCallback(
    (id: string) =>
      ([e]: IntersectionObserverEntry[]) => {
        if (!leftBadgeIds.current || !rightBadgeIds.current) return;
        const selectedRect = tabAreaRef.current
          ?.getElementsByClassName("MuiTabs-scroller")[0]
          ?.getBoundingClientRect();
        const tabRect = e.target.getBoundingClientRect();
        if (!selectedRect) return;
        const tabPosition =
          tabRect.right < selectedRect.left ? "left" : "right";
        const isBadgeInvisibled =
          e.target.classList.contains("MuiBadge-invisible");

        if (!e.isIntersecting && !isBadgeInvisibled) {
          if (tabPosition === "left") {
            leftBadgeIds.current.add(id);
            rightBadgeIds.current.delete(id);
          } else {
            leftBadgeIds.current.delete(id);
            rightBadgeIds.current.add(id);
          }
        } else {
          leftBadgeIds.current.delete(id);
          rightBadgeIds.current.delete(id);
        }

        setHasLeftBadge(leftBadgeIds.current.size > 0);
        setHasRightBadge(rightBadgeIds.current.size > 0);
      },
    []
  );

  const registerIntersection = useCallback(
    (id: string) => {
      return new IntersectionObserver(checkIntersection(id));
    },
    [checkIntersection]
  );

  const disconnectTab = useCallback((id: string) => {
    const beforeBadge = tabBadgeRefs.current[id];
    if (beforeBadge) {
      if (leftBadgeIds.current) {
        leftBadgeIds.current.delete(id);
        setHasLeftBadge(leftBadgeIds.current.size > 0);
      }
      if (rightBadgeIds.current) {
        rightBadgeIds.current.delete(id);
        setHasRightBadge(rightBadgeIds.current.size > 0);
      }
      beforeBadge.disconnect();
      tabBadgeRefs.current[id] = null;
      return;
    }
  }, []);

  const registerTab = useCallback(
    (id: string, ref: HTMLSpanElement | null) => {
      if (!ref) return;
      const content = ref.getElementsByClassName("MuiBadge-badge")[0];
      const isInvisible = content.classList.contains("MuiBadge-invisible");
      const beforeBadge = tabBadgeRefs.current[id];

      // 通知がない場合は、BadgeIdsから削除し監視を解除
      if (isInvisible) {
        disconnectTab(id);
        return;
      }

      // 通知がある場合は表示を監視
      if (!beforeBadge) {
        const observeObject = registerIntersection(id);
        observeObject.observe(content);
        tabBadgeRefs.current[id] = observeObject;
      }
    },
    [registerIntersection, disconnectTab]
  );

  useEffect(() => {
    const tabBadgeRefsCurrent = tabBadgeRefs.current;
    return () => {
      Object.keys(tabBadgeRefsCurrent).forEach((key) =>
        tabBadgeRefsCurrent[key]?.disconnect()
      );
    };
  }, []);

  return {
    hasLeftBadge,
    hasRightBadge,
    registerTab,
    disconnectTab,
    tabAreaRef,
  };
};
