const storageUrl = process.env.REACT_APP_STORAGE_URL;
const cdnUrl = process.env.REACT_APP_CDN_URL;
const goCdnUrl = process.env.REACT_APP_GO_CDN_URL;

type ToCDNUrlOption = {
  enableGoCdn: boolean;
};

const toCDNUrl = (
  baseUrl: string | null,
  options: ToCDNUrlOption = { enableGoCdn: false }
): string => {
  if (!baseUrl || !storageUrl || !cdnUrl || !goCdnUrl) return "";

  if (options.enableGoCdn) {
    return replacePrefix(baseUrl, [storageUrl, cdnUrl], goCdnUrl);
  } else {
    return replacePrefix(baseUrl, [storageUrl], cdnUrl);
  }
};

const replacePrefix = (
  url: string,
  searchPrefixes: string[],
  replaceValue: string
): string => {
  let v = url;
  for (const searchPrefix of searchPrefixes) {
    if (v.startsWith(searchPrefix)) {
      v = v.replace(searchPrefix, replaceValue);
    }
  }
  return v;
};

export const isCDNUrl = (url: string): boolean => {
  if (!storageUrl || !cdnUrl || !goCdnUrl) return false;

  return (
    url.startsWith(storageUrl) ||
    url.startsWith(cdnUrl) ||
    url.startsWith(goCdnUrl)
  );
};

type HostKind = "cloudflare" | "google" | "external" | "empty";

export const kindofHost = (url: string): HostKind => {
  if (!url) {
    return "empty";
  } else if (cdnUrl && url.startsWith(cdnUrl)) {
    return "cloudflare";
  } else if (goCdnUrl && url.startsWith(goCdnUrl)) {
    return "google";
  } else {
    return "external";
  }
};

export default toCDNUrl;
