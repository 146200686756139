import React, { memo, useCallback, useRef } from "react";
import { useAppSelector } from "stores";
import store from "stores/interfaces";
import { Popover } from "@mui/material";
import DiceDetailContent from "./DiceDetailContent";

const DiceDetail = () => {
  const open = useAppSelector((state) =>
    store.getAppState(state, "openRoomDiceDetail")
  );
  const x = useAppSelector((state) => store.getAppState(state, "roomPointerX"));
  const y = useAppSelector((state) => store.getAppState(state, "roomPointerY"));
  const diceId = useAppSelector((state) =>
    store.getAppState(state, "openRoomDiceDetailId")
  );

  const ref = useRef<{ onSubmit: () => void }>(null);

  const onClose = useCallback(() => {
    ref.current?.onSubmit();
  }, [ref]);

  return (
    <Popover
      open={open}
      onClose={onClose}
      anchorReference="anchorPosition"
      anchorPosition={{ top: y, left: x }}
      disableRestoreFocus
    >
      <DiceDetailContent ref={ref} diceId={diceId || ""} />
    </Popover>
  );
};

export default memo(DiceDetail);
