import { useCallback, memo, useEffect } from "react";
import { useAppDispatch, useAppSelector } from "stores";
import styled from "styled-components";
import store from "stores/interfaces";
import {
  Popover,
  Button,
  Paper,
  TextField,
  IconButton,
  Typography,
  PopoverProps,
  Slider,
} from "@mui/material";

import VolumeDownIcon from "@mui/icons-material/VolumeDown";
import RepeatIcon from "@mui/icons-material/Repeat";
import { useTranslation } from "react-i18next";
import { UserMediumRecord } from "stores/modules/entities.user.media";
import { Controller, useForm } from "react-hook-form";

type MediumListItemMenuProps = {
  mediumId: string | undefined;
  uid: string | null;
  anchorEl: PopoverProps["anchorEl"];
  open: boolean;
  onClose: () => void;
};

type FormData = {
  name: string;
  volume: number;
  loop: boolean;
};

const MediumListItemMenu = ({
  mediumId,
  uid,
  anchorEl,
  open,
  onClose,
}: MediumListItemMenuProps) => {
  const dispatch = useAppDispatch();
  const [t] = useTranslation();

  const medium =
    useAppSelector((state) => store.getMediumById(state, mediumId || "")) ||
    blankMedium;
  const isPreview = useAppSelector(
    (state) => state.app.state.previewMediaUrl !== null
  );

  const { register, handleSubmit, reset, watch, getValues, control } =
    useForm<FormData>({
      defaultValues: { name: "", volume: 0.5, loop: true },
    });

  const currentVolume = watch("volume");

  useEffect(() => {
    if (medium) {
      reset({ name: medium.name, volume: medium.volume, loop: medium.loop });
    }
  }, [medium, reset]);

  const handleClose = useCallback(() => {
    dispatch(
      store.appStateMutate((state) => {
        state.previewMediaUrl = null;
      })
    );
    onClose();
  }, [onClose, dispatch]);

  const onSubmit = useCallback(
    (values: FormData) => {
      if (uid && mediumId) {
        dispatch(store.updateUserMedium(uid, mediumId, values));
        handleClose();
      }
    },
    [uid, mediumId, handleClose, dispatch]
  );
  const onDelete = useCallback(() => {
    if (uid && mediumId && window.confirm(t("本当に削除しますか？"))) {
      dispatch(store.deleteUserMedium(uid, mediumId, !!medium?.external));
      handleClose();
    }
  }, [uid, mediumId, medium?.external, handleClose, t, dispatch]);

  const onPreview = useCallback(() => {
    if (medium) {
      const { volume, loop } = getValues();

      dispatch(
        store.appStateMutate((state) => {
          state.previewMediaUrl = isPreview ? null : medium.url;
          state.previewMediaVolume = volume;
          state.previewMediaLoop = loop;
        })
      );
    }
  }, [isPreview, medium, getValues, dispatch]);

  const onChangePreviewVolume = useCallback(
    (value: number) => {
      if (isPreview) {
        dispatch(
          store.appStateMutate((state) => {
            state.previewMediaVolume = value;
          })
        );
      }
    },
    [isPreview, dispatch]
  );

  const onChangePreviewLoop = useCallback(
    (value: boolean) => {
      if (isPreview) {
        dispatch(
          store.appStateMutate((state) => {
            state.previewMediaLoop = value;
          })
        );
      }
    },
    [isPreview, dispatch]
  );

  if (!medium) return null;

  return (
    <FormPopover
      open={open}
      anchorEl={anchorEl || null}
      onClose={handleClose}
      onSubmit={handleSubmit(onSubmit)}
      anchorOrigin={{ vertical: "top", horizontal: "right" }}
    >
      <MediumEditContent>
        <form onSubmit={handleSubmit(onSubmit)}>
          <TextField
            variant="filled"
            label={t("name")}
            margin="dense"
            fullWidth
            inputProps={register("name")}
          />
          <VolumeBox>
            <VolumeDownIcon />
            <Controller
              name="volume"
              control={control}
              render={({ field: { value, onChange, name, ref } }) => (
                <VolumeSlider
                  name={name}
                  defaultValue={value}
                  max={1}
                  min={0.0}
                  step={0.05}
                  onChangeCommitted={(_, volume) => {
                    if (typeof volume === "number") {
                      onChange(volume);
                      onChangePreviewVolume(volume);
                    }
                  }}
                  componentsProps={{ input: { ref } }}
                />
              )}
            />
            <Typography>{currentVolume}</Typography>
            <Controller
              name="loop"
              control={control}
              render={({ field: { onChange, value } }) => (
                <IconButton
                  onClick={() => {
                    onChange(!value);
                    onChangePreviewLoop(!value);
                  }}
                  size="small"
                  color={value ? "primary" : "default"}
                >
                  <RepeatIcon />
                </IconButton>
              )}
            />
          </VolumeBox>
          <Actions>
            <Button
              fullWidth
              color={isPreview ? "secondary" : "primary"}
              onClick={onPreview}
            >
              {isPreview ? t("試聴停止") : t("試聴")}
            </Button>
            <Button fullWidth color="secondary" onClick={onDelete}>
              {t("削除")}
            </Button>
            <Button fullWidth color="primary" type="submit">
              {t("保存")}
            </Button>
          </Actions>
        </form>
      </MediumEditContent>
    </FormPopover>
  );
};

type FormPopoverProps = Omit<PopoverProps, "onClose" | "onSubmit"> & {
  onSubmit: () => void;
  onClose: () => void;
};

const FormPopover = ({ onClose, onSubmit, ...other }: FormPopoverProps) => {
  const onCloseAndSubmit = useCallback(() => {
    onSubmit();
    onClose();
  }, [onSubmit, onClose]);
  return <Popover onClose={onCloseAndSubmit} {...other} />;
};

const MediumEditContent = styled(Paper)`
  width: 240px;
  padding: 8px 16px;
`;

const VolumeBox = styled.div`
  margin-top: 8px;
  display: flex;
  align-items: center;
`;

const VolumeSlider = styled(Slider)`
  margin: 0 8px;
`;

const Actions = styled.div`
  display: flex;
`;

const blankMedium = UserMediumRecord({});

export default memo(MediumListItemMenu);
