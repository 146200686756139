import { useDraggable } from "@dnd-kit/core";
import { useResizable } from "hooks/useResizable";
import { Resizable } from "re-resizable";
import React, {
  ComponentPropsWithRef,
  memo,
  useCallback,
  useEffect,
  useRef,
  useMemo,
  useState,
  SyntheticEvent,
} from "react";
import { useAppSelector } from "stores";
import { getAppState } from "stores/modules/app.state/selectors";
import styled from "styled-components";

export type Position = {
  x: number;
  y: number;
  width: number;
  height: number;
};

type EnableProps = {
  bottom?: boolean;
  bottomLeft?: boolean;
  bottomRight?: boolean;
  left?: boolean;
  right?: boolean;
  top?: boolean;
  topLeft?: boolean;
  topRight?: boolean;
};
export type DraggableData = {
  initialPosition: Position;
  onUpdate: (position: Position) => void;
  alignWithGrid: boolean;
};

type DraggableItemProps = {
  style?: React.CSSProperties;
  initialPosition: Position;
  draggableId: string;
  disableDragging: boolean;
  enableResizing: boolean;
  enableProps?: EnableProps;
  lockAspectRatio?: boolean;
  alignWithGrid: boolean;
  setDragging?: (dragging: boolean) => void;
  onUpdate: (position: Position) => void;
} & ComponentPropsWithRef<"div">;

const DEFAULT_ENABLE_PROPS = {
  bottom: true,
  bottomLeft: true,
  bottomRight: true,
  left: true,
  right: true,
  top: true,
  topLeft: true,
  topRight: true,
};

const DraggableItem = ({
  style,
  initialPosition,
  draggableId,
  disableDragging,
  enableResizing,
  enableProps,
  lockAspectRatio,
  alignWithGrid,
  setDragging,
  onUpdate,
  children,
  ...props
}: DraggableItemProps) => {
  const { onClick } = props;
  const cellSize = useAppSelector((state) =>
    getAppState(state, "roomScreenCellSize")
  );
  const scale = useAppSelector((state) =>
    getAppState(state, "roomScreenScale")
  );

  const [position, setPosition] = useState<{ x: number; y: number }>({
    x: initialPosition.x,
    y: initialPosition.y,
  });

  const handleUpdate = useCallback(
    (updatePosition: Position) => {
      setPosition({
        x: updatePosition.x,
        y: updatePosition.y,
      });
      onUpdate(updatePosition);
    },
    [onUpdate]
  );
  const isResizing = useRef(false);

  const { size, resizePosition, handleResize, handleResizeStop } = useResizable(
    initialPosition,
    handleUpdate,
    isResizing
  );

  const { attributes, listeners, setNodeRef, transform, isDragging } =
    useDraggable({
      id: draggableId,
      data: {
        initialPosition: {
          ...initialPosition,
          ...position,
        },
        onUpdate: handleUpdate,
        alignWithGrid: alignWithGrid,
      },
      disabled: disableDragging,
    });

  useEffect(() => {
    if (!isDragging) {
      setPosition({
        x: initialPosition.x,
        y: initialPosition.y,
      });
    }
  }, [initialPosition.x, initialPosition.y]); // eslint-disable-line react-hooks/exhaustive-deps
  // isDragging を依存に含めるとドラッグ終了時に初期位置が変更されて一瞬元に戻るような挙動になってしまう

  useEffect(() => {
    if (setDragging) {
      setDragging(isDragging);
    }
  }, [setDragging, isDragging]);

  const positionX = useMemo(() => {
    return (
      (position.x - resizePosition.x) * cellSize + (transform?.x || 0) / scale
    );
  }, [position.x, resizePosition.x, cellSize, transform?.x, scale]);
  const positionY = useMemo(() => {
    return (
      (position.y - resizePosition.y) * cellSize + (transform?.y || 0) / scale
    );
  }, [position.y, resizePosition.y, cellSize, transform?.y, scale]);

  const handleMouseUp = useCallback((e) => {
    // onResizeStopの後にonClickが発火しているためこのタイミングでリサイズを終了
    isResizing.current = false;
  }, []);

  const handleOnClick = useCallback(
    (e) => {
      if (onClick && !isResizing.current) {
        onClick(e);
      }
    },
    [onClick]
  );
  return (
    <DraggableContent
      className={isDragging ? "" : "movable"}
      ref={setNodeRef}
      style={{
        ...style,
        cursor: disableDragging ? "auto" : "move",
        transform: `translate(${positionX}px, ${positionY}px)`,
      }}
      {...props}
      onClick={handleOnClick}
      onMouseUp={handleMouseUp}
    >
      <Resizable
        className={"movable"}
        size={size}
        scale={scale}
        minHeight={cellSize}
        minWidth={cellSize}
        enable={
          enableResizing
            ? enableProps
              ? enableProps
              : DEFAULT_ENABLE_PROPS
            : {}
        }
        grid={[cellSize, cellSize]}
        onResizeStart={preventDefault}
        onResize={handleResize}
        onResizeStop={handleResizeStop}
        lockAspectRatio={lockAspectRatio}
      >
        <div
          style={{
            width: "100%",
            height: "100%",
          }}
          {...attributes}
          {...listeners}
        >
          {children}
        </div>
      </Resizable>
    </DraggableContent>
  );
};

const preventDefault = (e: SyntheticEvent) => {
  e.preventDefault();
};

const DraggableContent = styled.div`
  position: absolute;
  user-select: auto;
  touch-action: none;
  display: inline-block;
  top: 0;
  left: 0;
  box-sizing: border-box;
`;

export default memo(DraggableItem);
