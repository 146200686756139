import {
  useRef,
  MouseEvent as ReactMouseEvent,
  TouchEvent as ReactTouchEvent,
  useEffect,
  PropsWithChildren,
  memo,
  useMemo,
} from "react";

type DraggableFieldProps = PropsWithChildren<{
  onStart?: (e: ReactMouseEvent | ReactTouchEvent) => void;
  onDrag?: (
    e: MouseEvent | TouchEvent,
    data: { deltaX: number; deltaY: number }
  ) => void;
  onStop?: (e: MouseEvent | TouchEvent) => void;
}>;

const DraggableField = ({
  onStart,
  onDrag,
  onStop,
  children,
}: DraggableFieldProps) => {
  const dragging = useRef(false);
  const lastX = useRef(0);
  const lastY = useRef(0);

  const { handleStart, removeEventListeners } = useMemo(() => {
    const handleMove = (e: MouseEvent | TouchEvent) => {
      if (!dragging.current) return;

      const { clientX, clientY } = "touches" in e ? e.touches[0] : e;
      const deltaX = clientX - lastX.current;
      const deltaY = clientY - lastY.current;
      lastX.current = clientX;
      lastY.current = clientY;

      if (onDrag) {
        onDrag(e, { deltaX, deltaY });
      }
    };

    const handleLeftClickOnly = (e: MouseEvent | TouchEvent) => {
      if (e instanceof MouseEvent) {
        // 左クリック以外では盤面を移動させないようにする
        if (e.button !== 0) {
          handleStop(e);
        }
      }
    };

    const removeEventListeners = () => {
      document.removeEventListener("mousemove", handleMove);
      document.removeEventListener("touchmove", handleMove);
      document.removeEventListener("mouseup", handleStop);
      document.removeEventListener("mousedown", handleLeftClickOnly);
      document.removeEventListener("touchend", handleStop);
      document.removeEventListener("contextmenu", handleStop);
    };

    const handleStop = (e: MouseEvent | TouchEvent) => {
      dragging.current = false;

      if (onStop) {
        onStop(e);
      }

      removeEventListeners();
    };

    const handleStart = (e: ReactMouseEvent | ReactTouchEvent) => {
      if (e.nativeEvent instanceof MouseEvent) {
        if (e.nativeEvent.button !== 0) {
          return;
        }
      }

      const { clientX, clientY } = "touches" in e ? e.touches[0] : e;
      lastX.current = clientX;
      lastY.current = clientY;
      dragging.current = true;
      if (onStart) {
        onStart(e);
      }
      document.addEventListener("mousemove", handleMove);
      document.addEventListener("touchmove", handleMove);
      document.addEventListener("mouseup", handleStop);
      document.addEventListener("mousedown", handleLeftClickOnly);
      document.addEventListener("touchend", handleStop);
      document.addEventListener("contextmenu", handleStop);
    };

    return { handleStart, removeEventListeners };
  }, [onDrag, onStart, onStop]);

  useEffect(() => {
    return removeEventListeners;
  }, [removeEventListeners]);

  return (
    <div onMouseDown={handleStart} onTouchStart={handleStart}>
      {children}
    </div>
  );
};

export default memo(DraggableField);
