import React, {
  PropsWithChildren,
  SyntheticEvent,
  useCallback,
  useEffect,
  useState,
} from "react";
import styled from "styled-components";
import theme from "theme";
import {
  Typography,
  DialogContent,
  Portal,
  Paper,
  AppBar,
  Toolbar,
  IconButton,
  useMediaQuery,
} from "@mui/material";

import CloseIcon from "@mui/icons-material/Close";
import EditIcon from "@mui/icons-material/Edit";
import AddIcon from "@mui/icons-material/Add";
import { Resizable, ResizeCallback, ResizeStartCallback } from "re-resizable";
import { useDraggable } from "@dnd-kit/core";
import { flushSync } from "react-dom";
import { clamp } from "modules/clamp";
import { Direction } from "re-resizable/lib/resizer";
import { produce } from "immer";

const dialogContentStyle = { padding: 0 };

type Position = {
  x: number;
  y: number;
};

type Transform = {
  x: number;
  y: number;
};

type Size = {
  width: number;
  height: number;
};

type FloatWindowProps = PropsWithChildren<{
  open: boolean;
  title: string;
  onEdit?: () => any;
  onClose: () => any;
  onAdd?: () => any;
}>;

const FloatWindow = ({
  open,
  title,
  onEdit,
  onClose,
  onAdd,
  children,
}: FloatWindowProps) => {
  const matches = useMediaQuery(theme.breakpoints.down("sm"));
  const defaultWidth = 320;
  const defaultHeight = matches ? 400 : 280;

  // Position

  // 移動やリサイズをしていない状態での位置(position)とサイズ(size)
  const [position, setPosition] = useState({
    x: Math.trunc(window.innerWidth / 2 - defaultWidth / 2),
    y: Math.trunc(window.innerHeight / 2 - defaultHeight / 2),
  });
  const [size, setSize] = useState({
    width: defaultWidth,
    height: defaultHeight,
  });

  const onUpdatePosition = useCallback(
    (delta: { x: number; y: number }) => {
      setPosition((position) =>
        restrictToWindow(
          { x: position.x + delta.x, y: position.y + delta.y },
          size
        )
      );
    },
    [size]
  );

  const { attributes, listeners, setNodeRef, transform } = useDraggable({
    id: "float_window_" + title,
    data: {
      onUpdate: onUpdatePosition,
    },
    disabled: matches,
  });

  // Resize

  // リサイズ実行中の仮サイズ（resigingSize）と、上/左方向のリサイズをしている際の表示位置の調整量（resigingTransform）
  const [resizingSize, setResigingSize] = useState<Size | null>(null);
  const [resigingTransform, setResigingTransform] = useState<Transform | null>(
    null
  );

  // 画面外にはみ出ないように、リサイズ開始時点から計算した最大サイズ
  const [maxSize, setMaxSize] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
  });

  const onResizeStart: ResizeStartCallback = useCallback(
    (e, d, ref) => {
      const { hasTop, hasLeft } = parseDirection(d);
      const refRect = ref.getBoundingClientRect();

      const maxWidth = hasLeft
        ? size.width + refRect.left
        : window.innerWidth - refRect.left;
      const maxHeight = hasTop
        ? size.height + refRect.top
        : window.innerHeight - refRect.top;

      setMaxSize({ width: maxWidth, height: maxHeight });

      e.preventDefault();
    },
    [size]
  );

  const onResize: ResizeCallback = useCallback(
    (_e, d, _ref, delta) => {
      flushSync(() => {
        const { hasTop, hasLeft } = parseDirection(d);
        if (hasTop || hasLeft) {
          setResigingTransform({
            x: hasLeft ? delta.width : 0,
            y: hasTop ? delta.height : 0,
          });
        }

        setResigingSize({
          width: size.width + delta.width,
          height: size.height + delta.height,
        });
      });
    },
    [size]
  );

  const onResizeStop: ResizeCallback = useCallback((_e, d, _ref, delta) => {
    setSize((size) => ({
      width: size.width + delta.width,
      height: size.height + delta.height,
    }));

    const { hasTop, hasLeft } = parseDirection(d);
    if (hasTop || hasLeft) {
      setPosition(
        produce((draft) => {
          if (hasTop) {
            draft.y -= delta.height;
          }
          if (hasLeft) {
            draft.x -= delta.width;
          }
        })
      );
    }

    setResigingSize(null);
    setResigingTransform(null);
    setMaxSize({ width: window.innerWidth, height: window.innerHeight });
  }, []);

  // Resize window

  const moveToWithinWindow = useCallback(() => {
    const newSize = produce(size, (draft) => {
      if (window.innerWidth > 320 && draft.width > window.innerWidth) {
        draft.width = window.innerWidth;
      }
      if (window.innerHeight > 280 && draft.height > window.innerHeight) {
        draft.height = window.innerHeight;
      }
    });

    setPosition((position) => restrictToWindow(position, newSize));
    setSize(newSize);
    setMaxSize({ width: window.innerWidth, height: window.innerHeight });
  }, [size]);

  useEffect(() => {
    if (open) {
      moveToWithinWindow();
    }
  }, [open, moveToWithinWindow]);

  useEffect(() => {
    if (!open) return;

    window.addEventListener("resize", moveToWithinWindow);
    return () => {
      window.removeEventListener("resize", moveToWithinWindow);
    };
  }, [open, moveToWithinWindow]);

  // Decide posiiton

  const { x: positionX, y: positionY } = restrictToWindow(
    {
      x: position.x + (transform?.x || 0) - (resigingTransform?.x || 0),
      y: position.y + (transform?.y || 0) - (resigingTransform?.y || 0),
    },
    resizingSize || size
  );

  if (!open) return null;
  return (
    <Portal>
      <div
        ref={setNodeRef}
        style={{
          position: "absolute",
          top: 0,
          left: 0,
          transform: `translate3d(${positionX}px, ${positionY}px, 0)`,
          zIndex: theme.zIndex.drawer + 1,
        }}
      >
        <Resizable
          defaultSize={{
            width: defaultWidth,
            height: defaultHeight,
          }}
          size={size}
          style={{ position: "absolute" }}
          minWidth={320}
          minHeight={280}
          maxWidth={maxSize.width}
          maxHeight={maxSize.height}
          onResizeStart={onResizeStart}
          onResize={onResize}
          onResizeStop={onResizeStop}
          enable={matches ? false : undefined}
        >
          <Container square>
            <div
              {...attributes}
              {...listeners}
              style={{
                cursor: "move",
              }}
              onKeyDown={(e) => {
                e.preventDefault();
              }}
            >
              <AppBar position="static" color="transparent" elevation={0}>
                <Toolbar variant="dense">
                  <Typography variant="subtitle2">{title}</Typography>
                  <DialogHeadActions data-no-dnd="true">
                    {onAdd ? (
                      <IconButton onClick={onAdd} size="small">
                        <AddIcon />
                      </IconButton>
                    ) : null}
                    {onEdit ? (
                      <IconButton onClick={onEdit} size="small">
                        <EditIcon />
                      </IconButton>
                    ) : null}
                    <IconButton onClick={onClose} size="small" edge="end">
                      <CloseIcon />
                    </IconButton>
                  </DialogHeadActions>
                </Toolbar>
              </AppBar>
            </div>
            <DialogContent
              className="scrollable-list"
              style={dialogContentStyle}
              onMouseDown={stopPropagation}
              onTouchStart={stopPropagation}
            >
              <div>{children}</div>
            </DialogContent>
          </Container>
        </Resizable>
      </div>
    </Portal>
  );
};

const parseDirection = (
  direction: Direction
): { hasTop: boolean; hasLeft: boolean } => {
  const uppered = direction.toUpperCase();
  return {
    hasTop: uppered.includes("TOP"),
    hasLeft: uppered.includes("LEFT"),
  };
};

const restrictToWindow = (pos: Position, size: Size): Position => {
  return {
    x: clamp(pos.x, {
      min: 0,
      max: Math.max(window.innerWidth - size.width, 0),
    }),
    y: clamp(pos.y, {
      min: 0,
      max: Math.max(window.innerHeight - size.height, 0),
    }),
  };
};

const stopPropagation = (e: SyntheticEvent) => {
  e.stopPropagation();
};

const Container = styled(Paper)`
  height: 100%;
  display: flex;
  flex-direction: column;
  background: rgba(44, 44, 44, 0.87);
  z-index: ${theme.zIndex.drawer + 1};
`;

const DialogHeadActions = styled.div`
  margin-left: auto;
  > .MuiIconButton-root + .MuiIconButton-root {
    margin-left: 4px;
  }
`;

export default FloatWindow;
