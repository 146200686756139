import { memo, useCallback } from "react";
import { useAppDispatch, useAppSelector } from "stores";
import store from "stores/interfaces";

import NoteListItem from "./NoteListItem";
import { reorderNotes } from "stores/modules/entities.room.notes/operations";
import SortableList from "containers/SortableList";
import SortableItem from "containers/SortableItem";
import { DropProps } from "stores/modules/firestoreModuleUtils/ordaring";
import styled from "styled-components";

type NoteListProps = {
  roomId: string;
};

const NoteListContainer = (props: NoteListProps) => {
  const dispatch = useAppDispatch();
  const noteIds = useAppSelector(store.getRoomOrderdNoteIds);

  const onReorder = useCallback(
    (order: DropProps) => {
      dispatch(reorderNotes(props.roomId, order));
    },
    [props.roomId, dispatch]
  );

  return (
    <SortableList
      ids={noteIds}
      sortableItem={(id) => (
        <SortableItem id={id}>
          <NoteListItem noteId={id} />
        </SortableItem>
      )}
      overlay={(id) => (
        <OverlayNote>
          <NoteListItem noteId={id} />
        </OverlayNote>
      )}
      onReorder={onReorder}
    />
  );
};

const OverlayNote = styled.div`
  color: white;
  background: rgb(0 0 0 / 0.5);
`;

export default memo(NoteListContainer);
