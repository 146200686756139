import { memo, useCallback, useEffect, useMemo, useRef } from "react";
import { useAppDispatch, useAppSelector } from "stores";
import styled from "styled-components";
import store from "stores/interfaces";
import {
  AppBar,
  Toolbar,
  Tabs,
  Tab,
  Badge,
  Box,
  TabScrollButton,
} from "@mui/material";
import LockIcon from "@mui/icons-material/Lock";

import AddIcon from "@mui/icons-material/Add";
import { useTranslation } from "react-i18next";
import { getHasEditableRole } from "stores/modules/entities.room.members/selectors";
import theme from "theme";
import { getJoinedMessageGroups } from "stores/modules/entities.rooms/selectors";
import { CHAT_SYSTEM_TABS } from "stores/modules/app.state/selectors";
import { useVisibleTabs } from "hooks/useVisibleTabs";
import { difference } from "lodash-es";

const ChatBoxHeader = () => {
  const d = useAppDispatch();
  const [t] = useTranslation();
  const tab = useAppSelector((state) =>
    store.getAppState(state, "roomChatTab")
  );
  const newMessageCounts = useAppSelector((state) =>
    store.getAppState(state, "roomChatTabs")
  );
  const messageCounts = useAppSelector((state) =>
    store.getAppState(state, "roomChatMessageCounts")
  );
  const directMessageChannels = useAppSelector(
    store.getRoomMemberDirectMessageTabs
  );
  const groups = useAppSelector(getJoinedMessageGroups);
  const hasEditableRole = useAppSelector(getHasEditableRole);
  const beforeSearchTabs = useRef<string[]>([]);

  const {
    hasLeftBadge,
    hasRightBadge,
    registerTab,
    disconnectTab,
    tabAreaRef,
  } = useVisibleTabs();

  useEffect(() => {
    const groupTabs = groups.map((group) => group.id);
    const dmTabs = directMessageChannels.map((dm) => dm.key);
    const searchTabs = [...CHAT_SYSTEM_TABS, ...groupTabs, ...dmTabs];

    const deleteTabs = difference(beforeSearchTabs.current, searchTabs);
    // 以前のタブの配列にしか存在しないタブを監視対象から削除
    deleteTabs.forEach((deleteTab) => {
      disconnectTab(deleteTab);
    });

    // 新しいタブの配列を設定
    beforeSearchTabs.current = searchTabs;

    if (!searchTabs.includes(tab)) {
      d(
        store.appStateMutate((state) => {
          state.roomChatTab = "main";
          state.roomChatTabs["main"] = 0;
        })
      );
    }
  }, [tab, groups, directMessageChannels, d, disconnectTab]);

  const onChangeTab = useCallback(
    (_, value) => {
      if (value) {
        d(
          store.appStateMutate((state) => {
            state.roomChatTab = value;
            state.roomChatTabs[value] = 0;
          })
        );
      } else {
        d(
          store.appStateMutate((state) => {
            state.openRoomMessageChannel = true;
          })
        );
      }
    },
    [d]
  );
  const isPrivateTab = useMemo(() => {
    const isSystemTab = CHAT_SYSTEM_TABS.includes(tab);
    const isPublicTab = groups.some(
      (group) => group.id === tab && group.kind === "public"
    );
    return !isSystemTab && !isPublicTab;
  }, [tab, groups]);

  return (
    <StyledAppBar position="static" elevation={0}>
      <Toolbar variant="dense" disableGutters>
        <Tabs
          ref={(el) => (tabAreaRef.current = el)}
          variant="scrollable"
          scrollButtons
          value={tab}
          onChange={onChangeTab}
          textColor={isPrivateTab ? "secondary" : "inherit"}
          allowScrollButtonsMobile
          ScrollButtonComponent={(props) => {
            return (
              <div style={{ position: "relative", display: "inline-flex" }}>
                <TabScrollButton {...props} />
                {!props.disabled && (
                  <ScrollBadge
                    direction={props.direction}
                    variant="dot"
                    color="secondary"
                    invisible={
                      props.direction === "left"
                        ? !hasLeftBadge
                        : !hasRightBadge
                    }
                  />
                )}
              </div>
            );
          }}
        >
          <StyledTab
            label={
              <Badge
                // variant="dot"
                ref={(el) => registerTab("main", el)}
                color="secondary"
                badgeContent={newMessageCounts["main"]}
                invisible={!newMessageCounts["main"]}
              >
                {t("メイン")}
              </Badge>
            }
            id="main"
            value="main"
          />
          <StyledTab
            label={
              <Badge
                ref={(el) => registerTab("info", el)}
                variant="dot"
                color="secondary"
                invisible={!newMessageCounts["info"]}
              >
                {t("情報")}
              </Badge>
            }
            value="info"
          />
          <StyledTab
            label={
              <Badge
                ref={(el) => registerTab("other", el)}
                variant="dot"
                color="secondary"
                invisible={!newMessageCounts["other"]}
              >
                {t("雑談")}
              </Badge>
            }
            value="other"
          />
          {directMessageChannels
            .filter((dm) => {
              return !!messageCounts[dm.key] || dm.key === tab;
            })
            .map((dm) => (
              <StyledTab
                key={dm.key}
                label={
                  <Badge
                    ref={(el) => registerTab(dm.key, el)}
                    variant="dot"
                    color="secondary"
                    invisible={!newMessageCounts[dm.key]}
                  >
                    {dm.name || t("匿名さん")}
                  </Badge>
                }
                value={dm.key}
              />
            ))}
          {groups.map((group) => (
            <StyledTab
              key={group.id}
              label={
                <Badge
                  ref={(el) => registerTab(group.id, el)}
                  variant="dot"
                  color="secondary"
                  invisible={!newMessageCounts[group.id]}
                >
                  <Box display="flex" alignItems="center">
                    {group.kind === "private" && (
                      <LockIcon sx={{ fontSize: "14px", marginRight: "4px" }} />
                    )}
                    {group.name}
                  </Box>
                </Badge>
              }
              value={group.id}
            />
          ))}
          <StyledTab
            label={<AddIcon fontSize="small" />}
            value={null}
            disabled={!hasEditableRole}
          />
        </Tabs>
      </Toolbar>
    </StyledAppBar>
  );
};

const StyledAppBar = styled(AppBar)`
  background: #212121;
`;

const StyledTab = styled(Tab)`
  min-width: 48px;

  &.MuiTab-textColorSecondary.Mui-selected {
    color: ${theme.palette.secondary.main};
  }
`;

const ScrollBadge = styled(Badge)<{ direction: string }>`
  position: abusolute;
  top: 12px;
  right: ${({ direction }) => (direction === "right" ? "10px" : "30px")};
  opacity: 0.6;
`;

export default memo(ChatBoxHeader);
