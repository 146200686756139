import React, { useCallback, useEffect, memo } from "react";
import { useAppDispatch, useAppSelector } from "stores";
import store from "stores/interfaces";
import theme from "theme";
import { useDropzone } from "react-dropzone";
import {
  Dialog,
  AppBar,
  Toolbar,
  Typography,
  IconButton,
  Box,
  Tooltip,
  Tabs,
  Tab,
  useMediaQuery,
  Button,
  ButtonGroup,
  Badge,
  Hidden,
  FormControl,
  Select,
  SelectChangeEvent,
  MenuItem,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import ArchiveIcon from "@mui/icons-material/Archive";
import DeleteIcon from "@mui/icons-material/Delete";
import { useTranslation } from "react-i18next";

import { DialogActions } from "@mui/material";
import UnsplashSearchForm from "./UnsplashSearchForm";
import styled from "styled-components";
import UnsplashFileList from "./UnsplashFileList";
import FileListContainer from "containers/FileListContainer";

const FileListDialog = ({
  onSelect,
  onClose,
  open,
}: {
  onSelect: (url: string) => void;
  onClose: () => void;
  open: boolean;
}) => {
  const d = useAppDispatch();
  const [t] = useTranslation();
  const filtered = useAppSelector((state) =>
    store.getAppState(state, "onlyFilteredFiles")
  );
  const group = useAppSelector((state) =>
    store.getAppState(state, "openRoomImageSelectGroup")
  );
  const dir = useAppSelector((state) =>
    store.getAppState(state, "openRoomImageSelectDir")
  );
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const selectedFileIds = useAppSelector((state) =>
    store.getAppState(state, "selectedFileIds")
  );
  const isDisabled = group === "unsplash";

  // useEffect(() => {
  //   if (open) {
  //     d(store.loadOfficialImages());
  //   }
  // }, [open, d]);

  const handleClose = useCallback(() => {
    d(
      store.appStateMutate((state) => {
        state.selectingFiles = false;
        state.selectedFileIds = [];
      })
    );
    onClose();
  }, [d, onClose]);

  const handleSelect = useCallback(
    (file) => {
      onSelect(file);
      handleClose();
    },
    [onSelect, handleClose]
  );
  const onChangeTab = useCallback(
    (_, value) => {
      d(
        store.appStateMutate((state) => (state.openRoomImageSelectDir = value))
      );
    },
    [d]
  );

  const onArchive = useCallback(() => {
    if (
      window.confirm(t("全ての画像が一覧から削除されます。よろしいですか？"))
    ) {
      d(store.archiveCurrentUserFiles());
    }
  }, [d, t]);

  const onClickRoomButton = useCallback(() => {
    d(
      store.appStateMutate((state) => {
        state.onlyFilteredFiles = true;
        state.openRoomImageSelectGroup = "room";
      })
    );
  }, [d]);
  const onClickAllButton = useCallback(() => {
    d(
      store.appStateMutate((state) => {
        state.onlyFilteredFiles = false;
        state.openRoomImageSelectGroup = "all";
      })
    );
  }, [d]);
  const onClickUnsplashButton = useCallback(() => {
    d(
      store.appStateMutate((state) => {
        state.selectingFiles = false;
        state.selectedFileIds = [];
        state.openRoomImageSelectGroup = "unsplash";
      })
    );
  }, [d]);

  const onChangeGroup = useCallback(
    (event: SelectChangeEvent<"room" | "all" | "unsplash">) => {
      switch (event.target.value) {
        case "unsplash":
          return onClickUnsplashButton();
        case "all":
          return onClickAllButton();
        case "room":
        default:
          return onClickRoomButton();
      }
    },
    [onClickRoomButton, onClickAllButton, onClickUnsplashButton]
  );

  const selectingFiles = useAppSelector((state) =>
    store.getAppState(state, "selectingFiles")
  );
  const onClickMulti = useCallback(() => {
    d(
      store.appStateMutate((state) => {
        state.selectingFiles = !state.selectingFiles;
        state.selectedFileIds = [];
      })
    );
  }, [d]);

  const onClickDeleteSelectedFiles = useCallback(() => {
    if (
      selectedFileIds.length === 0 ||
      !window.confirm(
        t(
          "{{length}}個のファイルを完全に削除します。この画像が参照されていた場合、表示できなくなります。",
          { length: selectedFileIds.length }
        )
      )
    ) {
      return;
    }
    d(store.deleteUserFiles(selectedFileIds));
    d(
      store.appStateMutate((state) => {
        state.selectingFiles = false;
        state.selectedFileIds = [];
      })
    );
  }, [d, selectedFileIds, t]);

  const onDrop = useCallback(
    (files: File[]) => {
      if (dir != null) {
        for (const file of files) {
          d(store.addCurrentUserFile(file, dir));
        }
      }
    },
    [dir, d]
  );

  const dropzone = useDropzone({
    onDrop,
    noClick: true,
    disabled: isDisabled,
    accept: ["image/*"],
  });
  useEffect(() => {
    if (filtered) return;
    d(
      store.appStateMutate((state) => {
        state.enabledUserFilesLoader = true;
      })
    );
  }, [filtered, d]);
  return (
    <Dialog
      open={!!open}
      onClose={handleClose}
      fullWidth
      maxWidth="md"
      fullScreen={isMobile}
      PaperProps={dropzone.getRootProps()}
    >
      <AppBar position="sticky" color="default">
        <Toolbar>
          <Hidden smDown>
            <Tooltip title={t("最大 3000 files まで表示されます")}>
              <Typography variant="subtitle1">{t("画像選択")} </Typography>
            </Tooltip>
            <GroupSelect size="small">
              <Button
                color="white"
                variant={group === "room" ? "contained" : undefined}
                onClick={onClickRoomButton}
              >
                ROOM
              </Button>
              <Button
                color="white"
                variant={group === "all" ? "contained" : undefined}
                onClick={onClickAllButton}
              >
                ALL
              </Button>
              <Button
                color="white"
                variant={group === "unsplash" ? "contained" : undefined}
                onClick={onClickUnsplashButton}
                style={{ textTransform: "none" }}
              >
                Unsplash
              </Button>
            </GroupSelect>
          </Hidden>
          <Hidden smUp>
            <FormControl variant="outlined" size="small">
              <Select variant="standard" value={group} onChange={onChangeGroup}>
                <MenuItem value="room">ROOM</MenuItem>
                <MenuItem value="all">ALL</MenuItem>
                <MenuItem value="unsplash">Unsplash</MenuItem>
              </Select>
            </FormControl>
          </Hidden>
          <Box flexGrow={1} />
          {selectingFiles ? (
            <>
              <Button onClick={onClickMulti}>{t("キャンセル")}</Button>
              <Tooltip title={t("選択したファイルを削除")}>
                <IconButton
                  onClick={onClickDeleteSelectedFiles}
                  disabled={selectedFileIds.length === 0}
                  size="large"
                >
                  <Badge
                    badgeContent={selectedFileIds.length}
                    color="secondary"
                  >
                    <DeleteIcon />
                  </Badge>
                </IconButton>
              </Tooltip>
            </>
          ) : (
            <>
              <Tooltip title={t("ファイルを複数選択して削除する")}>
                <Button onClick={onClickMulti} disabled={isDisabled}>
                  {t("選択削除")}
                </Button>
              </Tooltip>
              <Tooltip
                title={t("全てのファイルをアーカイブ（一覧に表示させない）")}
              >
                <IconButton
                  onClick={onArchive}
                  disabled={isDisabled}
                  size="large"
                >
                  <ArchiveIcon />
                </IconButton>
              </Tooltip>
              <Tooltip title={t("追加する")}>
                <IconButton
                  onClick={dropzone.open}
                  disabled={isDisabled}
                  edge="end"
                  size="large"
                >
                  <AddIcon />
                </IconButton>
              </Tooltip>
            </>
          )}
        </Toolbar>
        {group === "unsplash" ? (
          <UnsplashSearchForm />
        ) : (
          <Tabs
            variant="scrollable"
            textColor="primary"
            indicatorColor="secondary"
            scrollButtons
            value={dir}
            onChange={onChangeTab}
            allowScrollButtonsMobile
          >
            <Tab label={t("前景")} value="foreground" />
            <Tab label={t("背景")} value="background" />
            <Tab label={t("キャラクター")} value="characters" />
            <Tab label={t("スクリーン")} value="item" />
            <Tab label={t("スクリーン裏")} value="cover" />
            <Tab label={t("マーカー")} value="marker" />
            <Tab label={t("カットイン")} value="effect" />
          </Tabs>
        )}
      </AppBar>
      {group === "unsplash" ? (
        <UnsplashFileList onSelect={handleSelect} />
      ) : (
        <FileListContainer onSelect={handleSelect} dir={dir} group={group} />
      )}
      <input {...dropzone.getInputProps()} disabled={false} />
      <DialogActions>
        <Button fullWidth onClick={handleClose}>
          {t("閉じる")}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const GroupSelect = styled(ButtonGroup)`
  margin-left: 16px;
`;

export default memo(FileListDialog);
