import { memo, useCallback, useEffect, useState } from "react";
import { shallowEqual } from "react-redux";
import { useAppDispatch, useAppSelector } from "stores";
import styled from "styled-components";
import store from "stores/interfaces";
import {
  Drawer,
  AppBar,
  Toolbar,
  IconButton,
  TextField,
  Paper,
  ButtonGroup,
  Button,
  Slider,
  Typography,
} from "@mui/material";
import StopIcon from "@mui/icons-material/Stop";
import LibraryAddIcon from "@mui/icons-material/LibraryAdd";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import CloseIcon from "@mui/icons-material/Close";
import VolumeDownIcon from "@mui/icons-material/VolumeDown";
import VolumeUpIcon from "@mui/icons-material/VolumeUp";
import { ReactComponent as CcfoliaProIcon } from "./CcfoliaPro.svg";
import MediumList from "../MediumList";
import { useDropzone } from "react-dropzone";
import { useTranslation } from "react-i18next";
import MediumListTabs from "./MediumListTabs";
import MediumListOfficialPlaylist from "containers/MediumListOfficialPlaylist";
import theme from "theme";
import { createHowl } from "modules/sound";
import {
  Collision,
  CollisionDescriptor,
  CollisionDetection,
  DndContext,
  DragEndEvent,
  DragOverlay,
  DragStartEvent,
  DroppableContainer,
  MouseSensor,
  TouchSensor,
  closestCenter,
  pointerWithin,
  useSensor,
  useSensors,
} from "@dnd-kit/core";
import { createPortal } from "react-dom";
import MediumListLibrarySortableTab from "./MediumListLibrarySortableTab";
import MediumListItem from "containers/MediumListItem";
import { restrictToHorizontalAxis } from "@dnd-kit/modifiers";
import { hasSortableData } from "@dnd-kit/sortable";

const MediumListDialog = () => {
  const d = useAppDispatch();
  const [uid, roomId, group, tab, dir, open] = useAppSelector((state) => {
    return [
      store.getAppState(state, "uid"),
      store.getAppState(state, "roomId"),
      store.getAppState(state, "userMediumListGroup"),
      store.getAppState(state, "userMediumListLibraryTab"),
      store.getAppState(state, "openRoomMediaDir"),
      store.getAppState(state, "openRoomMedia"),
    ] as const;
  }, shallowEqual);
  const isPro = useAppSelector((state) => {
    return store.getAppState(state, "plan") === "ccfolia-pro";
  });
  const [t] = useTranslation();
  const sensors = useSensors(
    useSensor(MouseSensor, {
      activationConstraint: {
        distance: 6,
      },
    }),
    useSensor(TouchSensor, {
      activationConstraint: {
        delay: 100,
        tolerance: 2,
      },
    })
  );
  const [activeId, setActiveId] = useState<string | null>(null);
  const [activeCategory, setActiveCategory] = useState<string | null>(null);

  const customCollisionDetection: CollisionDetection = useCallback((args) => {
    if (!args.pointerCoordinates) return [];

    // ポインターが現在位置するコンテナを取得
    const pointerCollisions = pointerWithin(args);
    const collision = pointerCollisions[0];
    const droppableItem = isCollisionDescriptor(collision)
      ? collision.data.droppableContainer
      : null;
    const activeContainer = hasSortableData(args.active)
      ? args.active.data.current.sortable.containerId
      : null;
    const overId = hasSortableData(droppableItem)
      ? droppableItem.data.current.sortable.containerId
      : activeContainer;

    // 対象を現在位置するコンテナのアイテムに制限
    const droppableContainers = args.droppableContainers.filter(
      (dropItem) =>
        hasSortableData(dropItem) &&
        dropItem.data.current.sortable.containerId === overId
    );

    if (overId === "bgm-tab" && activeContainer !== "bgm-tab") {
      // リストのアイテムがタブ上に存在する場合のみpointerWithinを設定
      return pointerWithin({ ...args, droppableContainers });
    }

    return closestCenter({ ...args, droppableContainers });
  }, []);

  useEffect(() => {
    if (!open || group !== "officialPlaylist") {
      d(
        store.appStateMutate((state) => {
          state.previewMediaUrl = null;
        })
      );
    }
  }, [group, open, d]);
  const onClose = useCallback(() => {
    d(
      store.appStateMutate((state) => {
        state.openRoomMedia = false;
      })
    );
  }, [d]);
  const onStop = useCallback(() => {
    if (dir === "bgm") {
      d(
        store.updateCurrentRoomField({
          mediaUrl: null,
          mediaType: "file",
          mediaName: "",
          mediaVolume: 0,
          mediaRepeat: true,
        })
      );
    } else if (dir === "sound") {
      d(
        store.updateCurrentRoomField({
          soundUrl: null,
          soundName: "",
          soundVolume: 0,
          soundRepeat: false,
        })
      );
    } else if (dir === "effect") {
      d(
        store.updateCurrentRoomEffect({
          soundUrl: null,
          soundName: "",
        })
      );
    }
  }, [dir, d]);
  const onDrop = useCallback(
    (files: File[]) => {
      if (uid == null || roomId == null) return;
      for (const file of files) {
        d(store.addUserMedium(uid, roomId, tab, file));
      }
    },
    [uid, roomId, tab, d]
  );
  const onSubmit = useCallback(
    (e) => {
      e.preventDefault();
      if (uid == null || roomId == null) return [];
      const form = e.currentTarget;
      const formdata = new FormData(form);
      const url = formdata.get("url");
      if (url) {
        createHowl({
          src: [String(url)],
          autoplay: false,
          volume: 1,
          format: ["mp4", "ogg", "wav"],
          mute: true,
          html5: true,
          onload: () => {
            d(store.addUserMediumFromUrl(uid, roomId, tab, String(url)));
          },
          onloaderror: (err) => {
            window.alert(t("利用できないURLです。"));
          },
        });
      }
      form.reset();
    },
    [uid, roomId, tab, d, t]
  );
  const dropzone = useDropzone({
    onDrop,
    noClick: true,
    disabled: group !== "library",
    accept: ["audio/*", ".mp3", ".wav"],
  });

  const onChangeGroup = useCallback(
    (group: "library" | "officialPlaylist") => () => {
      d(
        store.appStateMutate((state) => {
          state.userMediumListGroup = group;
        })
      );
    },
    [d]
  );

  useEffect(() => {
    if (open) {
      d(store.loadOfficialPlaylists());
    }
  }, [open, d]);

  const onDragStart = useCallback((e: DragStartEvent) => {
    setActiveCategory(e.active.data.current?.sortable.containerId ?? null);
    setActiveId(e.active.id.toString() ?? null);
  }, []);

  const onReorder = useCallback(
    (e: DragEndEvent) => {
      const activeCurrent = e.active.data.current;
      const overCurrent = e.over?.data.current;

      if (activeCurrent && e.over && overCurrent) {
        const startArea = activeCurrent.sortable.containerId;
        const endArea = overCurrent.sortable.containerId;
        if (startArea === "bgm-list" && endArea === "bgm-list") {
          // リスト内での移動
          if (uid) {
            d(store.reorderMedia(uid, tab, e));
          }
        } else if (startArea === "bgm-list" && endArea === "bgm-tab") {
          // BGMを別タブに移動
          d(store.changeDirMedia(e.active.id.toString(), e.over.id.toString()));
        } else if (startArea === "bgm-tab" && endArea === "bgm-tab") {
          // BGMタブを移動
          const activeIndex = activeCurrent.sortable.index;
          const overIndex = overCurrent.sortable.index;
          if (activeIndex !== overIndex && uid) {
            d(
              store.reorderUserMediumDirectory(uid, {
                destination: overIndex,
                source: activeIndex,
              })
            );
            d(store.focusMediumDirectory(e.active.id.toString()));
          }
        }
      }
      setActiveId(null);
      setActiveCategory(null);
    },
    [uid, tab, d]
  );

  return (
    <div {...dropzone.getRootProps()}>
      <MediumListDrawer open={open} onClose={onClose} anchor="bottom">
        <DndContext
          sensors={sensors}
          onDragStart={onDragStart}
          onDragEnd={onReorder}
          modifiers={
            activeCategory === "bgm-tab" ? [restrictToHorizontalAxis] : []
          }
          collisionDetection={customCollisionDetection}
          autoScroll={{
            threshold: {
              x: 0.2,
              y: 0.3,
            },
            layoutShiftCompensation: false,
          }}
        >
          {createPortal(
            <>
              {activeId && (
                <DragOverlay zIndex={theme.zIndex.modal + 1}>
                  <OverlayItem>
                    {activeCategory === "bgm-list" && (
                      <MediumListItem mediumId={activeId} />
                    )}
                    {activeCategory === "bgm-tab" && (
                      <MediumListLibrarySortableTab id={activeId} />
                    )}
                  </OverlayItem>
                </DragOverlay>
              )}
            </>,
            document.body
          )}
          <AppBar position="static" color="default">
            <Toolbar>
              <ButtonGroup size="small">
                <Button
                  onClick={onChangeGroup("library")}
                  variant={group === "library" ? "contained" : undefined}
                  color="white"
                  style={
                    isPro
                      ? {}
                      : {
                          borderTopRightRadius: "inherit",
                          borderBottomRightRadius: "inherit",
                        }
                  }
                >
                  {t("アップロード")}
                </Button>
                {isPro && (
                  <Button
                    onClick={onChangeGroup("officialPlaylist")}
                    variant={
                      group === "officialPlaylist" ? "contained" : undefined
                    }
                    color="white"
                    disabled={!isPro}
                  >
                    {t("公式ライブラリ")}
                    {isPro ? (
                      <IconPro active={group === "officialPlaylist"} />
                    ) : (
                      <PlanLabel>FREE</PlanLabel>
                    )}
                  </Button>
                )}
              </ButtonGroup>
              <Spacer />
              <IconButton edge="end" onClick={onStop} size="large">
                <StopIcon />
              </IconButton>
              <IconButton edge="end" onClick={onClose} size="large">
                <CloseIcon />
              </IconButton>
            </Toolbar>
            <MediumListTabs />
          </AppBar>
          {group === "library" ? (
            <MediumList />
          ) : (
            <MediumListOfficialPlaylist />
          )}
        </DndContext>
        {group === "library" ? (
          <FloatBar square>
            <Form onSubmit={onSubmit} autoComplete="off" autoCorrect="off">
              <TextField
                variant="standard"
                fullWidth
                label={t("外部ファイルURL")}
                placeholder="https://..."
                helperText={t("指定したURLのファイルに直接リンクします")}
                type="text"
                name="url"
              />
              <IconButton type="submit" size="large">
                <LibraryAddIcon />
              </IconButton>
              <IconButton onClick={dropzone.open} size="large">
                <AttachFileIcon />
              </IconButton>
            </Form>
          </FloatBar>
        ) : (
          <FloatVolumeSlider />
        )}
      </MediumListDrawer>
      <input {...dropzone.getInputProps()} disabled={false} />
    </div>
  );
};

const FloatVolumeSlider = () => {
  const dispatch = useAppDispatch();
  const officialPlaylistVolume = useAppSelector((state) =>
    store.getAppState(state, "officialPlaylistVolume")
  );

  const onChange = useCallback(
    (event: Event, value: number | number[]) => {
      if (typeof value === "number") {
        dispatch(
          store.appStateMutate(
            (state) => (state.officialPlaylistVolume = value)
          )
        );
      }
    },
    [dispatch]
  );

  return (
    <VolumeBar square>
      <Typography variant="caption">Volume Setting</Typography>
      <VolumeBox>
        <VolumeDownIcon />
        <Slider
          value={officialPlaylistVolume}
          onChange={onChange}
          max={1}
          min={0}
          step={0.05}
        />
        <VolumeUpIcon />
      </VolumeBox>
    </VolumeBar>
  );
};

const isCollisionDescriptor = (
  collision: Collision | undefined
): collision is CollisionDescriptor => {
  if (!collision || !collision.data) {
    return false;
  }

  const data = collision.data;

  if (
    data &&
    "value" in data &&
    isDroppableContainer(data.droppableContainer)
  ) {
    return true;
  }

  return false;
};

const isDroppableContainer = (x: unknown): x is DroppableContainer => {
  return (
    x !== null &&
    typeof x === "object" &&
    "id" in x &&
    "key" in x &&
    "data" in x &&
    "disabled" in x &&
    "node" in x &&
    "rect" in x
  );
};

const MediumListDrawer = styled(Drawer)`
  z-index: ${theme.zIndex.modal};

  .MuiDrawer-paper {
    margin: auto;
    max-width: 640px;
    height: 75%;
  }
`;

const Form = styled.form`
  padding: 8px 16px 4px;
  justify-content: flex-start;
  align-items: center;
  display: flex;
`;

const Spacer = styled.div`
  flex: 1;
`;

const PlanLabel = styled.small`
  margin-left: 2px;
  font-size: 10px;
`;

const FloatBar = styled(Paper)`
  padding-bottom: 8px;
  background: #222;
`;

const VolumeBar = styled(Paper)`
  padding: 16px;
  background: #222;
  display: flex;
  align-items: center;
  justify-content: flex-end;
`;

const VolumeBox = styled.div`
  margin-left: 8px;
  display: flex;
  align-items: center;
  gap: 8px;
  width: 200px;
`;

const IconPro = styled(CcfoliaProIcon)<{ active: boolean }>`
  margin-left: 4px;
  fill: ${(props) =>
    props.active ? theme.palette.grey["900"] : theme.palette.grey["100"]};
`;

const OverlayItem = styled.div`
  color: white;
  list-style: none;
  background: rgb(0 0 0 / 0.5);
`;

export default memo(MediumListDialog);
